import { useContext } from "react";

import {
  IsSigninInSetStateType,
  UserContext,
  UserContextType,
  UserSetStateType,
} from "./user.context";

// * Might need to removed user args here
export function useUser(
  user?: UserContextType
): [
  UserContextType,
  UserSetStateType,
  boolean,
  IsSigninInSetStateType,
  string | null
] {
  const userContext = useContext(UserContext);

  if (user) {
    userContext.setUser(user);
  }

  return [
    userContext.user,
    userContext.setUser,
    userContext.isSigningIn,
    userContext.setIsSigningIn,
    userContext.signInError,
  ];
}

export default useUser;
