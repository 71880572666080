import { HelpActionButton, HelpDocMenuWrapper } from "./HelpDocStyled";

interface IHelpDocMenuProps {
  onCancelClicked: () => void;
  onSaveClicked: () => void;
  onPreviewClicked: () => void;
  onDeleteClicked: () => void;
  controlsDisabled?: boolean;
  saveText?: string;
  cancelText?: string;
  previewText?: string;
  deleteText?: string;
}

export type HelpDocMenuProps = IHelpDocMenuProps;

export function HelpDocMenu({
  onSaveClicked,
  onCancelClicked,
  onPreviewClicked,
  onDeleteClicked,
  controlsDisabled,
  saveText,
  cancelText,
  previewText,
  deleteText,
}: HelpDocMenuProps) {
  return (
    <HelpDocMenuWrapper>
      <HelpActionButton
        variant="outline"
        size="small"
        color="secondary"
        onClick={onDeleteClicked}
        disabled={controlsDisabled}
      >
        {deleteText || "Delete"}
      </HelpActionButton>
      <HelpActionButton
        variant="outline"
        size="small"
        color="secondary"
        onClick={onCancelClicked}
        disabled={controlsDisabled}
      >
        {cancelText || "Cancel"}
      </HelpActionButton>
      <HelpActionButton
        variant="outline"
        size="small"
        disabled={controlsDisabled}
        onClick={onPreviewClicked}
      >
        {previewText || "Preview"}
      </HelpActionButton>
      <HelpActionButton
        variant="default"
        size="small"
        color="primary"
        disabled={controlsDisabled}
        onClick={onSaveClicked}
      >
        {saveText || "Save"}
      </HelpActionButton>
    </HelpDocMenuWrapper>
  );
}
