import styled from "styled-components";

export const NavbarToggleWrapper = styled.div`
  display: none;
  position: relative;
  height: 55px;
  width: 100%;
`;

export default NavbarToggleWrapper;
