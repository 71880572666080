import { ENTITY_TYPES } from "@apim/lib-portal-entities";

import { useApiCall } from "../hooks";
import { GenericDTO, Team, HttpMethod } from "../../constants";

interface TeamRecordsDTO {
  apis: TeamAPIDTO[];
  docs: GenericDTO[];
  platforms: GenericDTO[];
}

export interface TeamAPIDTO {
  sk: string;
  pk: string;
  parent: string;
  onClick: () => void;
}

export interface TeamMemberDTO {
  ownership: string;
  ownershipType: ENTITY_TYPES.TEAM;
  pk: string;
  role: string;
  sk: string;
}

// TODO replace `any`
export function useGetTeamRecords(name: string) {
  return useApiCall<TeamRecordsDTO | null | undefined>(
    `/catalog/team/${name}/records`
  );
}

export function useGetTeamInfo(name: string) {
  return useApiCall<any | null | undefined>(`/catalog/team/${name}`);
}

export function useGetTeams() {
  return useApiCall<Team[]>("/catalog/team");
}

export function useCreateTeam() {
  return useApiCall<Team>("/catalog/team", HttpMethod.POST);
}
