import { DefaultTheme } from "styled-components";

import { ASURION_COLORS, COLORS } from "../constants";

export interface ExtendedTheme extends DefaultTheme {
  textOnPrimary: any;
  textOnSecondary: any;
}

export const ASURION_THEME_LIGHT: ExtendedTheme = {
  primary: ASURION_COLORS.primaryBright,
  primaryHover: ASURION_COLORS.primaryBright,
  primaryPressed: ASURION_COLORS.primaryDeeper,
  primaryBackgroundColor: "#f5f5f5",
  primaryBorderColor: ASURION_COLORS.black,
  invertedTextOnPrimary: ASURION_COLORS.neutralDeepest,
  textOnPrimary: ASURION_COLORS.neutralDeepest,

  secondary: ASURION_COLORS.primaryBright,
  secondaryHover: ASURION_COLORS.primaryBright,
  secondaryPressed: ASURION_COLORS.black,
  textOnSecondary: ASURION_COLORS.white,
  secondaryBackgroundColor: ASURION_COLORS.white,
  secondaryBorderColor: ASURION_COLORS.neutralDeep,
  invertedTextOnSecondary: ASURION_COLORS.neutralDeepest, // * inverted text color is for text-colors written on component that has background `secondaryBackgroundColor`

  warn: ASURION_COLORS.errorBright,
  warnHover: ASURION_COLORS.errorBright,
  warnPressed: ASURION_COLORS.errorDeep,
  warnBackgroundColor: ASURION_COLORS.errorBright,
  warnTextColor: ASURION_COLORS.black,
  warnTextInvertColor: ASURION_COLORS.errorDeep,

  // TODO give more meaningful value
  inverted: ASURION_COLORS.warningDeep,
  invertedHover: ASURION_COLORS.white,
  invertedPressed: ASURION_COLORS.white,
  textOnInverted: ASURION_COLORS.white,
};

export const ASURION_THEME_DARK: any = {
  primary: "#7488fa",
  primaryHover: ASURION_COLORS.brandGreenBright,
  primaryPressed: ASURION_COLORS.brandGreenBright,
  primaryBackgroundColor: "#2f3346", // * Stripe
  primaryBorderColor: ASURION_COLORS.white,
  textOnPrimary: "#C1C9D2",
  invertedTextOnPrimary: ASURION_COLORS.white,
  secondary: COLORS.BRAND_BLUE_BRIGHTER,
  secondaryHover: COLORS.BRAND_BLUE_BRIGHTER,
  secondaryPressed: ASURION_COLORS.black,
  secondaryBorderColor: ASURION_COLORS.neutralDeep,
  textOnSecondary: ASURION_COLORS.black,
  invertedTextOnSecondary: "#C1C9D2",
  secondaryBackgroundColor: "#1a1f36",
  warn: ASURION_COLORS.errorBright,
  warnHover: ASURION_COLORS.errorBright,
  warnPressed: ASURION_COLORS.errorDeep,
  warnBackgroundColor: ASURION_COLORS.errorBright,
  warnTextColor: ASURION_COLORS.black,
  warnTextInvertColor: ASURION_COLORS.errorDeep,

  // TODO give more meaningful value
  inverted: ASURION_COLORS.white,
  invertedHover: ASURION_COLORS.white,
  invertedPressed: ASURION_COLORS.white,
  textOnInverted: ASURION_COLORS.white,
};

export const asurionTheme = {
  light: { ...ASURION_THEME_LIGHT },
  dark: { ...ASURION_THEME_DARK },
};

export type ThemeType = { light: ExtendedTheme; dark: ExtendedTheme };
export const DEFAULT_THEME: ThemeType = asurionTheme;
