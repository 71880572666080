import { Button, Text } from "@soluto-private/mx-asurion-ui-react";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { HelpDocMenu } from "./HelpDocMenu";
import { useGetHelpDocCDN, useUpdateHelpDoc } from "../../lib/api";
import {
  RichTextEditor,
  RichTextViewer,
} from "../../lib/components/RichTextEditor";
import { HelpDocContainer, HelpDocWrapper } from "./HelpDocStyled";
import useOnScreen from "../../lib/hooks/onscreen.hook";

interface IHelpDocProps {
  signedUrl: string;
  onChange: (document?: string) => void;
  canModify: boolean;
  helpDocTitle: string;
}

interface IDocumentTitleContainerProps {
  title: string;
  createdAt?: string;
  status?: string;
}

export type HelpDocProps = IHelpDocProps;
export type DocumentTitleContainerProps = IDocumentTitleContainerProps;

const DocumentTitleText = styled(Text)`
  display: block;
`;

const DocumentHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const DocumentTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  & > *:not(:last-child) {
    margin-bottom: 4px;
  }
`;

const HelpActionButton = styled(Button)`
  text-decoration: none;

  & > *:last-child {
    margin-left: 4px;
  }
`;

const HelpActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  @media print {
    display: none;
  }
`;

export function DocumentTitleContainer({
  title,
  createdAt,
  status,
}: DocumentTitleContainerProps) {
  return (
    <DocumentTitleWrapper>
      <DocumentTitleText size={5} weight="heavy">
        {title}
      </DocumentTitleText>
      {createdAt && (
        <Text>
          Created at
          {createdAt}
        </Text>
      )}
      {status && (
        <Text>
          Status
          {status}
        </Text>
      )}
    </DocumentTitleWrapper>
  );
}

export function HelpDoc({
  signedUrl,
  onChange,
  canModify,
  helpDocTitle,
}: HelpDocProps) {
  const [editMode, setEditMode] = useState(false);
  const [previewMode, setPreviewMode] = useState(false);
  const firstRender = useRef(true);
  const {
    response: docUpdateResponse,
    isLoading: isDocUpdating,
    error: docUpdateError,
    invokeApi: updateHelpDoc,
  } = useUpdateHelpDoc(encodeURIComponent(helpDocTitle));
  const { response: productDocResponse, invokeApi: getHelpDoc } =
    useGetHelpDocCDN();
  const ref: any = useRef<HTMLDivElement>();
  const onScreen: boolean = useOnScreen<HTMLDivElement>(ref);
  // const [sectionTitles, setSectionTitles] = useState([] as string[]);
  const [pendingPayload, setPendingPayload] = useState(""); // * Modified document content
  const [editorContent, setEditorContent] = useState(productDocResponse || "");
  const documentTitle: string = decodeURIComponent(helpDocTitle).replace(
    ".txt",
    ""
  );

  useEffect(() => {
    const handler = setTimeout(() => {
      if (onScreen && firstRender.current) {
        const getDoc = async () => {
          await getHelpDoc(null, undefined, null, signedUrl);
        };
        getDoc();
        firstRender.current = false;
      }
    }, 200);

    return () => {
      clearTimeout(handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onScreen]);

  // Subscribe to doc update events
  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      if (docUpdateResponse && !docUpdateError && pendingPayload !== "") {
        setEditMode(false);
        setPendingPayload("");
        onChange();
      } else if (docUpdateError) {
        throw new Error(`${docUpdateError}`);
      }
    }

    return () => {
      unmounted = true;
    };
  }, [docUpdateError, docUpdateResponse, pendingPayload, onChange]);

  // Subscribe to changes on documentation prop, remounting of sections
  useEffect(() => {
    let unmounted = false;
    let sections: string[] = [];

    const sectionTitleCollection = Array.from(
      document.getElementsByClassName("doc_section_title")
    );
    sectionTitleCollection.map((sectionTitleParent: Element) => {
      sections = [...sections, sectionTitleParent.id];

      return true;
    });

    if (!unmounted) {
      // setSectionTitles(sections);
    }

    return () => {
      unmounted = true;
    };
  }, [editorContent]);

  // Subscribe to changes from Help parent component
  useEffect(() => {
    setEditMode(false);
    setEditorContent(productDocResponse);
  }, [productDocResponse]);

  // Document change event
  const handleOnDocumentChange = (payload: string) => {
    setPendingPayload(payload);
  };

  // Document save event
  const handleOnDocumentSave = async () => {
    if (pendingPayload === "") {
      setEditMode(false);
      return;
    }

    const payloadBuffer = new Blob([pendingPayload], {
      type: "text/plain",
    });
    const formData = new FormData();
    formData.append("file", payloadBuffer, decodeURIComponent(helpDocTitle));
    formData.append("parent", "HELPDOC");
    formData.append("resource", "HELPDOC");
    await updateHelpDoc(
      {
        resource: "HELPDOC",
      },
      formData,
      {
        "Content-Type": "multipart/form-data",
      }
    );
    window.location.reload();
  };

  const handleOnDocumentPreview = () => {
    setPreviewMode(true);
    setEditMode(false);
  };

  const handleEditModeSwitch = () => {
    setPreviewMode(false);
    setEditMode(true);
  };

  const handleCancelClick = () => {
    setPendingPayload("");
    setEditMode(false);
  };

  const exportPage = () => {
    window.print();
  };

  return (
    <HelpDocWrapper ref={ref}>
      <HelpDocContainer>
        <DocumentHeaderContainer>
          <DocumentTitleContainer title={documentTitle?.split("/")[3]} />
          {editMode ? (
            <HelpDocMenu
              onSaveClicked={handleOnDocumentSave}
              onPreviewClicked={handleOnDocumentPreview}
              onCancelClicked={handleCancelClick}
              onDeleteClicked={handleCancelClick}
              controlsDisabled={isDocUpdating}
            />
          ) : (
            <HelpActionWrapper>
              {canModify && (
                <HelpActionButton
                  variant="default"
                  iconSide="right"
                  iconSrc="EditPencilChange"
                  color="primary"
                  size="small"
                  onClick={handleEditModeSwitch}
                  disabled={isDocUpdating}
                >
                  {previewMode ? "Continue Editing" : "Edit"}
                </HelpActionButton>
              )}
              <HelpActionButton
                variant="outline"
                size="small"
                iconSide="right"
                iconSrc="DataDownload"
                color="secondary"
                onClick={exportPage}
              >
                Export Document
              </HelpActionButton>
            </HelpActionWrapper>
          )}
        </DocumentHeaderContainer>
        {editMode ? (
          <RichTextEditor
            content={pendingPayload === "" ? editorContent : pendingPayload}
            onChange={handleOnDocumentChange}
            disabled={isDocUpdating}
            isLoading={isDocUpdating}
          />
        ) : (
          <RichTextViewer
            content={
              previewMode && pendingPayload !== ""
                ? pendingPayload
                : editorContent
            }
          />
        )}
      </HelpDocContainer>
    </HelpDocWrapper>
  );
}
