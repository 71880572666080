import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { IMenuButtonItemProps, IMenuLinkItemProps } from "../types";

const MenuItemButtonBase = styled.button<IMenuButtonItemProps>`
  width: 100%;
  padding: 8px 16px;
  background-color: ${(props) => props.theme.primaryBackgroundColor};
  border: none;
  font-family: Apercu;
  cursor: pointer;
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &:hover {
    background-color: ${(props) => props.theme.secondaryBackgroundColor};
  }
`;

const MenuItemLinkBase = styled(Link)`
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  border: none;
  font-family: Apercu;
  cursor: pointer;
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &:hover {
    background-color: ${(props) => props.theme.secondaryBackgroundColor};
  }
  text-decoration: none;
  color: ${(props) => props.theme.textOnPrimary};
  font-size: 14px;
`;

export function MenuItem(props: IMenuLinkItemProps | IMenuButtonItemProps) {
  const { menuType, title, collapseParent } = props;
  const history = useHistory();

  // React on router change to collapse possible parent
  useEffect(() => {
    let isMounted = true;

    history.listen(() => {
      if (isMounted && menuType === "link" && collapseParent) {
        collapseParent();
      }
    });

    return () => {
      isMounted = false;
    };
  }, [history, props, collapseParent, menuType]);

  if (menuType === "button") {
    // React on click action to collapse possible parent
    const handleClick = (
      e: any,
      onClickAction: React.MouseEventHandler<HTMLButtonElement> | undefined
    ) => {
      if (onClickAction) {
        onClickAction(e);
      }

      if (collapseParent) {
        collapseParent();
      }
    };

    // Render Button
    return (
      <MenuItemButtonBase
        onClick={(e) => handleClick(e, (props as IMenuButtonItemProps).onClick)}
      >
        {title}
      </MenuItemButtonBase>
    );
  }
  if (menuType === "link") {
    // Render Link
    return (
      <MenuItemLinkBase to={(props as IMenuLinkItemProps).url}>
        {title}
      </MenuItemLinkBase>
    );
  }

  return null;
}

export default MenuItem;
