import { ENTITY_TYPES } from "@apim/lib-portal-entities";
import { MenuBase, MenuItem, MenuItemContainer } from "./elements";
import { IMenuProps, IMenuItemProps } from "./types";

import { useUser } from "../../hooks";

export function Menu(props: IMenuProps) {
  const { menuItems, triggerEl, collapseParent } = props;
  const [user] = useUser();
  const isAdmin = user?.isAuthorizedByScope(
    `${ENTITY_TYPES.TEAM}#aaim:*:manage`
  );
  let items = menuItems;

  if (!isAdmin) {
    items = menuItems.filter((item) => item.url !== "/admin");
  }

  return (
    <MenuBase triggerEl={triggerEl}>
      {items.map(
        (item: IMenuItemProps, i: number, itemList: IMenuItemProps[]) => (
          <MenuItemContainer
            isLast={itemList.length - 1 === i}
            key={item.title}
          >
            <MenuItem {...item} collapseParent={collapseParent} />
          </MenuItemContainer>
        )
      )}
    </MenuBase>
  );
}

export default Menu;
