import styled from "styled-components";
import { useContext } from "react";
import { Text, getFontSize } from "@soluto-private/mx-asurion-ui-react";
import { useHistory } from "react-router-dom";

import { Scope, UserOwnershipContext } from "../../lib/hooks";
import { ASURION_COLORS } from "../../constants";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 60%;
  flex-grow: 1;
  margin: auto;
  padding: ${getFontSize(3)} 0px;
  @media screen and (max-width: 992px) {
    max-width: 80%;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
`;

const Body = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const EmojiCard = styled.div`
  & {
    background-color: ${(props) => props.theme.secondaryBackgroundColor};
    display: flex;
    padding: 0%;
    border: 1px solid ${ASURION_COLORS.neutralBright};
    width: 100%;
    height: 100%;
    margin-top: ${getFontSize(2)};
    position: relative;
    border-radius: 6px;
    &:hover {
      transform: scale(1.04);
    }
  }

  & .left-side {
    width: 130px;
    height: 120px;
    border-radius: 6px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
  }

  & .right-side {
    width: calc(100% - 130px);
    display: flex;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
    justify-content: space-between;
    white-space: nowrap;
    transition: 0.3s;
    border-radius: 6px;
    &:hover {
      background-color: #f9f7f9;
    }
  }

  & .refer {
    font-size: ${getFontSize(4)};
    font-family: Apercu;
    margin-left: 20px;
  }

  .bigtext {
    padding: 10px;
    font-size: 120px;
  }

  @media only screen and (max-width: 480px) {
    .container {
      transform: scale(0.7);
      &:hover {
        transform: scale(0.74);
      }
    }
    .refer {
      font-size: 18px;
    }
  }
`;

const StyledButton = styled.button`
  padding: 0;
  width: 460px;
  height: 120px;
  position: relative;
  margin: ${getFontSize(2)};
  border: 0px;
`;

export const getTeamScopeList = (scope: Scope) => {
  return scope?.teams?.map((team: string) => {
    const teamScope = scope[team];
    const scopes = Object.keys(teamScope).map((teamScopeKey: string) => {
      const scopeRule = scope[team][teamScopeKey as any] ?? "";
      return `${teamScopeKey}: ${scopeRule}`;
    });
    return { name: team, scopes };
  });
};

function TeamCard() {
  const history = useHistory();
  const userOwnership = useContext(UserOwnershipContext);
  const availableBackgroundColour = [
    "primaryDeeper",
    "secondaryDeeper",
    "neutralDeepest",
    "errorDeep",
    "successDeeper",
  ];

  const teams = userOwnership?.teams ?? [];

  const gotoTeamPage = (team: string) => {
    history.push(`/teams/${team}`);
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {teams &&
        teams.map((team: string) => {
          const asurionColors: any = ASURION_COLORS;
          const randomIndex = Math.floor(
            Math.random() * availableBackgroundColour.length
          );
          const randomColor = availableBackgroundColour[randomIndex] as string;
          const color: string = asurionColors[randomColor as any];
          return (
            <StyledButton
              type="button"
              key={team}
              onClick={() => gotoTeamPage(team)}
            >
              <EmojiCard className="container">
                <div
                  className="left-side"
                  style={{
                    backgroundColor: `${color}`,
                  }}
                >
                  <div className="bigtext">{team.charAt(0)}</div>
                </div>
                <div className="right-side">
                  <div className="refer">{team}</div>
                </div>
              </EmojiCard>
            </StyledButton>
          );
        })}
    </>
  );
}

export function UserTeams() {
  return (
    <Wrapper>
      <Header>
        <Text weight="heavy" size={6}>
          Your Teams:
        </Text>
      </Header>
      <Body>
        <TeamCard />
      </Body>
    </Wrapper>
  );
}
