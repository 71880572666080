import styled from "styled-components";

import { IAvatarMenuBaseProps } from "./types";
import { COLORS } from "../../../constants";

export const AvatarMenuBase = styled.div<IAvatarMenuBaseProps>`
  display: flex;
  align-items: center;
  height: 100%;
  display: ${(props) => (props.isSmall ? "none" : "")};
`;

export const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.primaryBackgroundColor};
  justify-content: center;
  padding: 8px;
  &:hover {
    cursor: pointer;
    color: ${COLORS.NEUTRAL_DEEP};
    background-color: ${(props) => props.theme.primaryBackgroundColor};
    transition: all 0.5s ease;
  }
`;

export const AvatarMenuWrapper = styled.div<any>`
  position: absolute;
  top: 75px;
  background-color: ${(props) => props.theme.primaryBackgroundColor};
  border-bottom: 1px solid ${(props) => props.theme.textOnPrimary};
  border-left: 1px solid ${(props) => props.theme.textOnPrimary};
  border-right: 1px solid ${(props) => props.theme.textOnPrimary};
`;
