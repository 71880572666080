import { colors } from "@soluto-private/mx-asurion-ui-react";
import styled, { css } from "styled-components";
import { CardType } from "../types";

const cardTypeDefault = css`
  background-color: ${colors.white};
  color: ${colors.black};
`;

const cardTypeWarn = css`
  background-color: ${colors.warningBright};
  color: ${colors.black};
`;

const cardTypeError = css`
  background-color: ${colors.errorDeep};
  color: ${colors.white};
`;

const cardTypeSuccess = css`
  background-color: ${colors.successBright};
  color: ${colors.black};
`;

const cardTypeDark = css`
  background-color: ${colors.black};
  color: ${colors.white} !important;
`;

const cardTypeToCss: Record<CardType, any> = {
  default: cardTypeDefault,
  warn: cardTypeWarn,
  error: cardTypeError,
  success: cardTypeSuccess,
  dark: cardTypeDark,
};

export const CardWrapper = styled.div<{
  type: CardType;
}>`
  border-radius: 10px;
  padding: 32px;
  width: auto;
  border: 1px solid ${colors.neutralBrighter};
  ${({ type }) => cardTypeToCss[type]};
`;

export default CardWrapper;
