import {
  AsurionDoodleSpinner,
  Button,
  getFontSize,
  RadioButton,
} from "@soluto-private/mx-asurion-ui-react";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import styled, { useTheme } from "styled-components";
import Modal from "styled-react-modal";
import { UserRole } from "../../../constants";
import { useDeleteUserRole, useGetUsersByProduct } from "../../../lib/api";
import { DeleteForm } from "../../../lib/components/Forms";

interface IUserTab {
  canModify: any;
  product: any;
  userTabRefresh: boolean;
}

const FilterDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SectionTitle = styled.h2`
  margin-right: ${getFontSize(2)};
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 20px;
`;

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

function UserTab({ userTabRefresh, product, canModify }: IUserTab) {
  const [isExternal, setIsExternal] = useState<boolean>();
  const [userData, setUserData] = useState<UserRole[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setselectedUser] = useState("");
  const [selectedRole, setselectedRole] = useState("");
  const [ownership, setOwnership] = useState("");
  const theme = useTheme();

  const {
    response: deleteUserRoleResponse,
    invokeApi: deleteUserRole,
    error: deleteUserRoleError,
    isLoading: deleteUserRoleLoading,
  } = useDeleteUserRole(selectedUser);

  const {
    response: productUsersResponse,
    isLoading: isGetProductUsersLoading,
    invokeApi: getProductUsers,
  } = useGetUsersByProduct(product ? product.pk : null);

  function renderSpecDeleteButton(canModify: boolean, callback: () => void) {
    return (
      canModify && (
        <Button
          onClick={callback}
          ariaLabel="Bin"
          iconSide="right"
          iconSrc="Bin"
          size="small"
          color="secondary"
          variant="text"
        />
      )
    );
  }

  const columns = [
    {
      name: "Email",
      selector: (row: UserRole) => row.pk,
      sortable: true,
    },
    {
      name: "Role",
      selector: (row: UserRole) => row.role,
    },
    {
      name: "",
      button: true,
      cell: (row: any) =>
        renderSpecDeleteButton(canModify, () => {
          setselectedUser(row.pk);
          setselectedRole(row.role);
          setOwnership(row.ownership);
          setIsModalOpen(true);
        }),
    },
  ];
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const handleDelete = async () => {
    await deleteUserRole(null, { ownership, role: selectedRole });
  };

  useEffect(() => {
    if (product) {
      getProductUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, userTabRefresh]);

  useEffect(() => {
    if (deleteUserRoleError) {
      toast.error(`Error occured: ${deleteUserRoleError}`);
    } else if (deleteUserRoleResponse) {
      toast.success("User deleted successfully.");
      toggleModal();
      getProductUsers();
    }
  }, [deleteUserRoleResponse, deleteUserRoleError]);
  useEffect(() => {
    if (productUsersResponse) {
      const filteredData = isExternal
        ? productUsersResponse.filter(
            (user) => !user.pk.includes("asurion.com")
          )
        : productUsersResponse.filter((user) =>
            user.pk.includes("asurion.com")
          );
      setUserData(filteredData);
    }
  }, [productUsersResponse, isExternal]);
  return (
    <>
      <Modal isOpen={isModalOpen} onBackgroundClick={toggleModal}>
        <DeleteForm
          entity="user"
          name={selectedUser}
          message="Are you sure you want to remove this user is irreversible ?"
          isDeleteLoading={deleteUserRoleLoading}
          onDelete={handleDelete}
          onCancel={toggleModal}
        />
      </Modal>

      <FilterDiv>
        <SectionHeader>
          <SectionTitle>Product Users</SectionTitle>
        </SectionHeader>
        <div>
          <RadioButton
            label="External Partners"
            name="userType"
            onChange={() => setIsExternal(true)}
            checked={isExternal}
          />
          <RadioButton
            label="Internal Users"
            name="userType"
            onChange={() => setIsExternal(false)}
            checked={!isExternal}
          />
        </div>
      </FilterDiv>
      <DataTable
        customStyles={{
          rows: {
            style: {
              backgroundColor: theme.secondaryBackgroundColor,
              color: theme.invertedTextOnSecondary,
            },
          },
          table: {
            style: {
              backgroundColor: theme.secondaryBackgroundColor,
              color: theme.invertedTextOnSecondary,
            },
          },
          cells: {
            style: {
              backgroundColor: theme.secondaryBackgroundColor,
              color: theme.invertedTextOnSecondary,
              "&:hover": {
                cursor: "pointer",
                color: theme.invertedTextOnPrimary,
              },
            },
          },
          headCells: {
            style: {
              backgroundColor: theme.secondaryBackgroundColor,
              color: theme.invertedTextOnSecondary,
            },
          },
          tableWrapper: {
            style: {
              backgroundColor: theme.secondaryBackgroundColor,
              color: theme.invertedTextOnSecondary,
            },
          },
          head: {
            style: {
              backgroundColor: theme.secondaryBackgroundColor,
              color: theme.invertedTextOnSecondary,
            },
          },
          headRow: {
            style: {
              backgroundColor: theme.secondaryBackgroundColor,
              color: theme.invertedTextOnSecondary,
            },
          },
          header: {
            style: {
              backgroundColor: theme.secondaryBackgroundColor,
              color: theme.invertedTextOnSecondary,
            },
          },
          noData: {
            style: {
              backgroundColor: theme.secondaryBackgroundColor,
              color: theme.invertedTextOnSecondary,
            },
          },
        }}
        columns={columns}
        data={userData}
        progressPending={isGetProductUsersLoading}
        progressComponent={<AsurionDoodleSpinner width="80px" />}
      />
    </>
  );
}

export default UserTab;
