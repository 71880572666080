import { useState, useEffect } from "react";
import { useTheme } from "styled-components";
// import $ from "jquery";
import {
  StyledDrawer,
  StyledSidebarSearchField,
  StyledLink,
  LinkWrapper,
  StyledMinimizeButton,
} from "./elements";
import { SidebarProps } from "./types";

export function Sidebar(props: SidebarProps) {
  const {
    links,
    productSidebar,
    withSearchField,
    onChange,
    onSubmit,
    open,
    modal,
    children,
    addUserOnclick,
    showAddUser,
    docItems,
    apiItems,
    canModifyProduct,
    addDocumentOnClick,
    addApiOnClick,
    minimized,
    setIsMinimized,
    ...rest
  } = props;
  const [showChildren, setShowChildren] = useState(true);
  const theme = useTheme();

  useEffect(() => {
    if (!minimized) {
      setTimeout(() => {
        setShowChildren(true);
      }, 300);
    } else {
      setShowChildren(false);
    }
  }, [minimized]);

  // const toggleSpecNavBar = () => {
  //   $(".sl-flex.sl-overflow-y-auto.sl-flex-col.sl-sticky").css(
  //     "display",
  //     "block"
  //   );
  // };

  return (
    <StyledDrawer
      open={open}
      dismissible
      {...rest}
      productSidebar={productSidebar}
      color={theme.primary}
      backgroundColor={theme.primaryBackgroundColor}
      borderColor={theme.primaryBorderColor}
      minimized={minimized}
    >
      {withSearchField && (
        <StyledSidebarSearchField
          label="Search"
          onChange={onChange}
          onSubmit={onSubmit}
        />
      )}
      <div>
        <StyledMinimizeButton
          size="small"
          variant="flat"
          iconSrc={minimized ? "CardsNavigationChevronRight" : "CloseX"}
          onClick={() => {
            // toggleSpecNavBar();
            setIsMinimized && setIsMinimized((curreValue) => !curreValue);
          }}
        />
      </div>
      {showChildren && children}
      {!!links &&
        showChildren &&
        links.map((linkItem) => (
          <LinkWrapper key={linkItem.url}>
            <StyledLink
              key={linkItem.url}
              to={{
                pathname: `${linkItem.url}`,
                state: { signedUrl: `${linkItem.signedUrl}` },
              }}
              onClick={linkItem?.onClick}
              target={linkItem?.isTargetBlank ? "_blank" : ""}
            >
              <div title={linkItem.title}>{linkItem.title}</div>
            </StyledLink>
          </LinkWrapper>
        ))}
    </StyledDrawer>
  );
}

export default Sidebar;
