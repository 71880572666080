import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  Text,
  getFontSize,
  Button,
  Icon,
} from "@soluto-private/mx-asurion-ui-react";
import DataTable from "react-data-table-component";
import Modal from "styled-react-modal";
import { useTheme } from "styled-components";

import { useUser } from "../../lib/hooks";
import {
  useGetTeamMembers,
  useGetTeamRecords,
  useGetTeamInfo,
  TeamAPIDTO,
} from "../../lib/api";
import { Sidebar, EditTeamMemberForm, MemberData } from "../../lib/components";
import {
  Wrapper,
  Body,
  StyledButton,
  SidebarContentWrapper,
  SectionWrapper,
  Section,
  MemberSection,
} from "./elements";

export function Team() {
  const { teamName } = useParams<{ teamName: string }>();
  const history = useHistory();
  const [teamAPIs, setTeamAPIs] = useState<TeamAPIDTO[] | undefined>();
  const [teamMemberList, setTeamMemberList] = useState<
    MemberData[] | undefined
  >();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState<MemberData | null>(null);
  const [userPrincipal] = useUser();
  const theme = useTheme();

  const onAddMemberClicked = () => {
    setSelectedMember(null);
    setIsModalOpen(true);
  };

  const onClosedClicked = () => {
    setSelectedMember(null);
    setIsModalOpen(false);
  };

  const apiColumns = [
    {
      name: "API Name",
      selector: (row: TeamAPIDTO) => (row.sk as string).replace("API#", ""), // * `any` is how react-data-table-component developers defined it
      sortable: true,
    },
  ];
  const memberColumns = [
    {
      name: "Email",
      selector: (row: MemberData) => row.pk,
      sortable: true,
    },
    {
      name: "Role/s",
      selector: (row: MemberData) => row.roles.join(", "),
    },
  ];

  const { invokeApi: getTeamRecords, response: teamRecords } =
    useGetTeamRecords(teamName);
  const { invokeApi: getTeamMembers, response: teamMembers } =
    useGetTeamMembers(teamName);
  const { invokeApi: getTeamInfo, response: teamInfo } =
    useGetTeamInfo(teamName);
  const isUserTeamAdmin = userPrincipal?.isAuthorizedByScope(
    `TEAM#${teamName}:team:manage`
  );

  const navigateToSection = (section: string) => {
    document
      .getElementById(section)
      ?.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  useEffect(() => {
    getTeamRecords();
    getTeamMembers();
  }, [getTeamRecords, getTeamMembers]);

  useEffect(() => {
    const memberTableData: any = {};
    teamMembers?.forEach((data) => {
      const existingData = memberTableData[data.pk];
      if (!existingData) {
        memberTableData[data.pk] = { roles: [data.role] };
      } else {
        existingData.roles.push(data.role);
      }
    }, {});
    const teamMemberList: MemberData[] = [];
    Object.keys(memberTableData).forEach((memberDataKey) => {
      teamMemberList.push({
        pk: memberDataKey,
        ...memberTableData[memberDataKey],
      } as MemberData);
    });
    setTeamMemberList(teamMemberList ?? []);
  }, [teamMembers]);

  useEffect(() => {
    getTeamInfo();
  }, [getTeamInfo]);

  useEffect(() => {
    setTeamAPIs(teamRecords?.apis ?? []);
  }, [teamRecords]);

  return (
    <Wrapper>
      <Body>
        <Sidebar open>
          <SidebarContentWrapper>
            <StyledButton
              variant="text"
              iconSrc="TV"
              key="overview-btn-key"
              size="medium"
              onClick={() => navigateToSection("overview")}
            >
              Overview
            </StyledButton>
            <StyledButton
              variant="text"
              iconSrc="User"
              key="user-btn-key"
              size="medium"
              onClick={() => navigateToSection("members")}
            >
              Members
            </StyledButton>
            <StyledButton
              variant="text"
              iconSrc="Folder"
              key="api-btn-key"
              size="medium"
              onClick={() => navigateToSection("api")}
            >
              APIS
            </StyledButton>
          </SidebarContentWrapper>
        </Sidebar>
        <SectionWrapper>
          <Section id="overview">
            <Text size={5}>{teamName}</Text>
            <Text size={1} style={{ marginTop: getFontSize(2) }}>
              <b>Description:</b> {teamInfo?.description ?? "None"}
            </Text>
          </Section>
          <Section id="members">
            <MemberSection>
              <Text size={3} weight="base">
                Members
              </Text>
              {isUserTeamAdmin && (
                <Button
                  size="small"
                  variant="flat"
                  iconSrc="PlusAdd"
                  onClick={onAddMemberClicked}
                />
              )}
            </MemberSection>
            <DataTable
              columns={memberColumns}
              data={teamMemberList ?? []}
              customStyles={{
                rows: {
                  style: {
                    backgroundColor: theme.secondaryBackgroundColor,
                    color: theme.invertedTextOnSecondary,
                  },
                },
                table: {
                  style: {
                    backgroundColor: theme.secondaryBackgroundColor,
                    color: theme.invertedTextOnSecondary,
                  },
                },
                cells: {
                  style: {
                    backgroundColor: theme.secondaryBackgroundColor,
                    color: theme.invertedTextOnSecondary,
                    "&:hover": {
                      cursor: "pointer",
                      color: theme.invertedTextOnPrimary,
                    },
                  },
                },
                headCells: {
                  style: {
                    backgroundColor: theme.secondaryBackgroundColor,
                    color: theme.invertedTextOnSecondary,
                  },
                },
                tableWrapper: {
                  style: {
                    backgroundColor: theme.secondaryBackgroundColor,
                    color: theme.invertedTextOnSecondary,
                  },
                },
                head: {
                  style: {
                    backgroundColor: theme.secondaryBackgroundColor,
                    color: theme.invertedTextOnSecondary,
                  },
                },
                headRow: {
                  style: {
                    backgroundColor: theme.secondaryBackgroundColor,
                    color: theme.invertedTextOnSecondary,
                  },
                },
                header: {
                  style: {
                    backgroundColor: theme.secondaryBackgroundColor,
                    color: theme.invertedTextOnSecondary,
                  },
                },
                noData: {
                  style: {
                    backgroundColor: theme.secondaryBackgroundColor,
                    color: theme.invertedTextOnSecondary,
                  },
                },
              }}
              onRowClicked={(row) => {
                setSelectedMember(row);
                setIsModalOpen(true);
              }}
              sortIcon={<Icon size="small" src="ArrowUp" />}
            />
            <Modal
              isOpen={isModalOpen}
              onBackgroundClick={() => setIsModalOpen(!isModalOpen)}
            >
              <EditTeamMemberForm
                selectedMember={selectedMember}
                onCancel={onClosedClicked}
                teamName={teamName}
              />
            </Modal>
          </Section>
          <Section id="api">
            <Text size={3}>APIs</Text>
            <DataTable
              columns={apiColumns}
              customStyles={{
                rows: {
                  style: {
                    backgroundColor: theme.secondaryBackgroundColor,
                    color: theme.textOnPrimary,
                  },
                },
                table: {
                  style: {
                    backgroundColor: theme.secondaryBackgroundColor,
                    color: theme.textOnPrimary,
                  },
                },
                cells: {
                  style: {
                    backgroundColor: theme.secondaryBackgroundColor,
                    color: theme.textOnPrimary,
                    "&:hover": {
                      cursor: "pointer",
                      color: theme.invertedTextOnPrimary,
                    },
                  },
                },
                headCells: {
                  style: {
                    backgroundColor: theme.secondaryBackgroundColor,
                    color: theme.textOnPrimary,
                  },
                },
                tableWrapper: {
                  style: {
                    backgroundColor: theme.secondaryBackgroundColor,
                    color: theme.textOnPrimary,
                  },
                },
                head: {
                  style: {
                    backgroundColor: theme.secondaryBackgroundColor,
                    color: theme.textOnPrimary,
                  },
                },
                headRow: {
                  style: {
                    backgroundColor: theme.secondaryBackgroundColor,
                    color: theme.textOnPrimary,
                  },
                },
                header: {
                  style: {
                    backgroundColor: theme.secondaryBackgroundColor,
                    color: theme.textOnPrimary,
                  },
                },
                noData: {
                  style: {
                    backgroundColor: theme.secondaryBackgroundColor,
                    color: theme.textOnPrimary,
                  },
                },
              }}
              data={teamAPIs ?? []}
              onRowClicked={(row) =>
                history.push(`/api/${row.sk.replace("API#", "")}`)
              }
              sortIcon={<Icon size="small" src="ArrowUp" />}
            />
          </Section>
        </SectionWrapper>
      </Body>
    </Wrapper>
  );
}

export default Team;
