import { CardBody, CardFooter, CardHeader, CardWrapper } from "./elements";
import { CardProps } from "./types";

export function Card(props: CardProps) {
  const { header, body, footer, type, className } = props;
  return (
    <CardWrapper type={type || "default"} className={className}>
      {header && <CardHeader>{header}</CardHeader>}
      {body && <CardBody>{body}</CardBody>}
      {footer && <CardFooter>{footer}</CardFooter>}
    </CardWrapper>
  );
}

export default Card;
