import styled from "styled-components";

export const NavbarContainer = styled.div`
  margin: auto;
  height: 100%;
  max-width: 97vw;
  padding: 0px 1rem;
  width: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  background: ${(props) => props.theme.primaryBackgroundColor};
  position: relative;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
`;

export default NavbarContainer;
