import styled from "styled-components";
import { colors } from "@soluto-private/mx-asurion-ui-react";
import { INavbarTabProps } from "../types";

export const NavbarLinks = styled.ul<INavbarTabProps>`
  background-color: ${(props) => props.theme.primaryBackgroundColor};
  ${(props) =>
    !props.isSmall &&
    ` 
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 30px;
    justify-content: end;
  `}
  ${(props) =>
    props.isSmall &&
    `
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 400px;
    position: absolute;
    top: 50px;
    left: -100%;
    opacity: 1;
  `}
  
  ${(props) =>
    props.isSmall &&
    props.showMenu &&
    `
    left: 0;
    opacity: 1;
    border-bottom: 1px solid ${colors.neutralBrighter};
  `}
  z-index: 1;
  text-align: center;
  justify-content: end;
  list-style: none;
  padding: 0;
`;

export default NavbarLinks;
