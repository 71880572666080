import styled from "styled-components";
import { IAvatar } from "./types";

const containerStyle = `
border-radius: 50%;
display: inline-block;
margin: 4px;
overflow: hidden;
border-style: solid;
background-clip: border-box;
padding: 0;
cursor: pointer;
&:focus {
  outline: none;
  box-shadow: none;
}
`;

export const XSmallAvatarContainer = styled.button`
  ${containerStyle}
  width: 24px;
  height: 24px;
  border-width: 1px;
  border-color: ${(props: IAvatar) => props?.borderColor || "black"};
`;

export const SmallAvatarContainer = styled.button`
  ${containerStyle}
  width: 32px;
  height: 32px;
  border-width: 1px;
  border-color: ${(props: IAvatar) => props?.borderColor || "black"};
`;

export const MediumAvatarContainer = styled.button`
  ${containerStyle}
  width: 40px;
  height: 40px;
  border-width: 1.5px;
  border-color: ${(props: IAvatar) => props?.borderColor || "black"};
`;
export const LargeAvatarContainer = styled.button`
  ${containerStyle}
  width: 64px;
  height: 64px;
  border-width: 2px;
  border-color: ${(props: IAvatar) => props?.borderColor || "black"};
`;

export const AvatarImage = styled.img`
  height: 100%;
  background-color: white;
  background-clip: border-box;
`;
