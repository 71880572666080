import Skeleton from "react-loading-skeleton";

import {
  ApiDetailContainer,
  ApiDetailWrapper,
  SpecContainer,
} from "./ApiDetailsContainers";
import { useMedia } from "../../../lib/hooks";

export function ApiDetailsSkeleton() {
  const breakPoint = useMedia();
  return (
    <>
      <ApiDetailWrapper>
        <ApiDetailContainer>
          <Skeleton
            duration={1500}
            count={1}
            style={{ height: "2rem", width: "300px" }}
          />
          <br />
          <Skeleton
            duration={1500}
            count={1}
            style={{ height: "1.5rem", width: "200px" }}
          />
          <br />
          <Skeleton
            duration={1500}
            count={1}
            style={{ height: "3rem", width: "80vw" }}
          />
        </ApiDetailContainer>
      </ApiDetailWrapper>
      <SpecContainer>
        {breakPoint.SM ? (
          <div>
            <Skeleton
              duration={1500}
              count={1}
              style={{ height: "25rem", width: "85vw" }}
            />
          </div>
        ) : (
          <>
            <div>
              <Skeleton
                duration={1500}
                count={1}
                style={{ height: "25rem", width: "20vw" }}
              />
            </div>
            <div>
              <Skeleton
                duration={1500}
                count={1}
                style={{ height: "25rem", width: "45vw" }}
              />
            </div>
            <div>
              <Skeleton
                duration={1500}
                count={1}
                style={{ height: "25rem", width: "20vw" }}
              />
            </div>
          </>
        )}
      </SpecContainer>
    </>
  );
}

export default ApiDetailsSkeleton;
