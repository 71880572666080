import styled from "styled-components";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  getFontSize,
  Dropdown,
  Button,
  TextField,
} from "@soluto-private/mx-asurion-ui-react";

import {
  TeamMemberDTO,
  useGetRoles,
  useGrantUserTeamAccess,
  useDeleteUserRole,
} from "../../api";

export interface MemberData extends TeamMemberDTO {
  roles: string[];
}

const StyledForm = styled.form`
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 0;
  margin: 0;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  min-width: 400px;
  width: 40vw;
  height: auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 0;
  height: 80px;
`;

const Body = styled.div`
  display: flex;
  flex-grow: 0;
  height: auto;
  padding: 1em;
`;

const Title = styled.h1`
  font-size: ${getFontSize(3)};
  font-family: Apercu;
`;

const Row = styled.div<{ flexAlign?: "right" | "left" }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${({ flexAlign }) =>
    flexAlign === "right" ? "flex-end" : "flex-start"};
  margin: 20px 0px;
  padding: 0px;
`;

const FieldContainer = styled.div`
  && {
    min-width: 45%;
    width: 100%;
    padding: 0 10px;
  }
`;

const StyledButton = styled(Button)`
  margin: 5px;
`;

interface EditTeamMemberFormProps {
  selectedMember: null | MemberData;
  teamName?: string;
  onCancel?: () => void;
  onSubmit?: any;
}

export function EditTeamMemberForm(props: EditTeamMemberFormProps) {
  const { selectedMember, onCancel, onSubmit, teamName } = props;

  const [userRoles, setUserRoles] = useState<string[]>(); // TODO Add tooltips for roles
  const [roleOptions, setRoleOptions] =
    useState<{ name: string; value: string }[]>();
  const [selectedUser, setSelectedUser] = useState<string>("");

  const { invokeApi: addUserToTeam } = useGrantUserTeamAccess(selectedUser);
  const { invokeApi: deleteUserRole } = useDeleteUserRole(selectedUser);
  const { response: roles, invokeApi: getRoles } = useGetRoles();

  const handleSubmit = async (data: { pk: string; role: string }) => {
    const { pk, role } = data;
    const payload = {
      pk,
      role,
      ownership: teamName,
    };
    try {
      await addUserToTeam(null, payload);
    } catch (e) {
      throw new Error(e as any);
    }
  };

  useEffect(() => {
    getRoles();
  }, [getRoles]);

  useEffect(() => {
    selectedMember && setUserRoles(selectedMember?.roles);
  }, [selectedMember]);

  useEffect(() => {
    selectedMember && setSelectedUser(selectedMember.pk);
  }, [selectedMember]);

  useEffect(() => {
    const formattedRoles = roles
      ?.filter((role: any) => {
        return role.pk === "ApiDeveloper" || role.pk === "TeamOwner";
      })
      .map((role: any) => {
        return {
          name: role.pk,
          value: role.pk,
          disabled: role.pk === selectedMember?.roles,
        };
      });
    setRoleOptions(formattedRoles);
  }, [roles, selectedMember]);

  const formik = useFormik({
    initialValues: {
      pk: selectedMember?.pk,
      role: selectedMember?.roles,
    },
    onSubmit,
  });

  const removeRole = async (role: string) => {
    const roleSet = new Set(userRoles);
    if (roleSet.has(role) && selectedUser.length) {
      try {
        await deleteUserRole(null, { ownership: teamName, role });
        roleSet.delete(role);
        setUserRoles(Array.from(roleSet));
      } catch (e) {
        throw new Error(`Error: ${e}`);
      }
    }
  };

  const handleRoleChange = async (
    value:
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLInputElement>
  ) => {
    // TODO add tool tips for roles
    const roleSet = new Set(userRoles);
    const role = value.target.value;
    if (!roleSet.has(role) && selectedUser.length) {
      try {
        await handleSubmit({ pk: selectedUser, role });
        roleSet.add(role);
        const arrayRoles = Array.from(roleSet);
        setUserRoles(arrayRoles);
      } catch (e) {
        throw new Error(`Error: ${e}`);
      }
    }
  };

  return (
    <StyledContainer>
      <Header>
        <Title>{selectedMember ? "Edit member role" : "Add member"}</Title>
        <Button variant="flat" onClick={onCancel}>
          Cancel
        </Button>
      </Header>
      <Body>
        <StyledForm>
          <Row>
            <FieldContainer>
              <TextField
                label="Email address"
                onChange={(value) => {
                  formik.handleChange(value);
                  setSelectedUser(value.target.value);
                }}
                value={selectedUser}
              />
            </FieldContainer>
          </Row>
          <Row>
            <FieldContainer>
              <Dropdown
                label="Select role here."
                name="role"
                onChange={(value) => {
                  handleRoleChange(value);
                  formik.handleChange(value);
                }}
                options={roleOptions ?? []}
              />
            </FieldContainer>
          </Row>
          <Row>
            {userRoles &&
              userRoles.map((role: string) => (
                <StyledButton
                  iconSrc="CloseX"
                  size="small"
                  type="button"
                  onClick={() => removeRole(role)}
                  key={role}
                >
                  {role}
                </StyledButton>
              ))}
          </Row>
        </StyledForm>
      </Body>
    </StyledContainer>
  );
}
