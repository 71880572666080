import { useCallback } from "react";
import { API, APIDTO, APIDoc } from "@apim/lib-portal-entities";

import { useApiCall } from "../hooks";
import { GenericDTO, HttpMethod } from "../../constants";

interface APIDocDTO extends APIDoc {
  signedUrl: string;
  isPublic: boolean;
  isDefault: boolean;
}
interface IAPIDTO extends APIDTO {
  owners: any[];
  docs: APIDocDTO[];
}

interface IAPIPaginated {
  apis: API[];
  lastKey: any;
}

// * https://stackoverflow.com/questions/1051182/what-is-a-data-transfer-object-dto
export function useGetApis() {
  const { invokeApi, ...rest } = useApiCall<API[]>("/catalog/api");

  const getApis = useCallback(async () => {
    await invokeApi();
  }, [invokeApi]);

  return { ...rest, getApis };
}

export function useGetApi(pk: string) {
  return useApiCall<GenericDTO | null>(`/catalog/api/${pk}`);
}

export function useGetApisPaginated() {
  return useApiCall<IAPIPaginated>("/catalog/api/paginated");
}

export function useGetApiCount() {
  return useApiCall<number>(`/catalog/api/count`);
}

export function useGetApiRecords(ApiName: string) {
  return useApiCall<IAPIDTO>(`/catalog/api/${ApiName}/records`);
}

export function useAddApiInfo(name: string) {
  return useApiCall(`catalog/api/${name}`, HttpMethod.POST);
}

export function useEditApiInfo(name: string) {
  return useApiCall<any>(`catalog/api/${name}`, HttpMethod.PUT);
}

export function useAddApiDoc(ApiName: string) {
  return useApiCall(`/catalog/api/${ApiName}/doc`, HttpMethod.POST);
}

export function useUpsertApiDoc(ApiName: string, envName: string) {
  return useApiCall<any>(
    `/catalog/api/${ApiName}/doc/${envName}`,
    HttpMethod.POST
  );
}

export function useUpdateAPIDocFlag(ApiName: string, docName: string) {
  return useApiCall<any>(
    `/catalog/api/${ApiName}/doc/${docName}/flag`,
    HttpMethod.PUT
  );
}

export function useGetSpecFile(api: string) {
  return useApiCall<any>(`/catalog/api/${api}/get-spec`, HttpMethod.GET);
}

export function useAddApi() {
  const { invokeApi, ...rest } = useApiCall<GenericDTO>(
    "/catalog/api/",
    HttpMethod.POST
  );

  return { ...rest, invokeApi };
}

export function useDeleteApi(ApiName: string) {
  return useApiCall(`/catalog/api/${ApiName}`, HttpMethod.DELETE);
}

export function useDeleteApiDoc(apiName: string, docName: string) {
  return useApiCall<any>(
    `/catalog/api/${apiName}/doc/${docName}`,
    HttpMethod.DELETE
  );
}

export function useGetSpecFileCDN() {
  return useApiCall<any | null>(``, HttpMethod.GET);
}
