import styled from "styled-components";
import {
  getFontSize,
  TextField as AsurionTextField,
  Icon as AsurionIcon,
} from "@soluto-private/mx-asurion-ui-react";

import { COLORS } from "../../../constants";
import { CustomTextFieldProps, TextFieldIcon } from "./types";

export const SearchFieldWrapper = styled.div`
  & {
    color: ${(props) => props.theme.textOnSecondary || COLORS.BLACK};
    display: inline-block;
    padding: 0;
    position: relative;
    align-items: flex-start;
    height: auto;
    flex-grow: 1;
    flex-wrap: nowrap;
    background-color: transparent;
    width: auto;
  }
`;

export const SearcFieldInput = styled(AsurionTextField)<CustomTextFieldProps>`
  & {
    margin: 0;
    max-width: 100%;
    min-width: 100%;
    height: 100%;
    flex-grow: 1;
    background: transparent;
    color: ${(props) => props.iconColor || COLORS.WHITE};
    font-family: Apercu;
    border: none;
    border-bottom: 2px solid;
    font-size: ${getFontSize(2)};
  }

  &:not(:disabled) {
    border-radius: 0px;
    border-color: rgb(165, 170, 175);
    width: 100%;
  }

  &&:not(:disabled):hover {
    border-color: ${(props) => props.theme.textOnPrimary};
    color: ${(props) => props.theme.textOnPrimary};
  }

  & {
    box-sizing: border-box;
    padding-left: ${(props) => (props.iconLeft ? "30px" : "0.75rem")};
    padding-right: ${(props) => (props.iconRight ? "30px" : "0.75rem")};
  }

  label {
    color: transparent;
  }

  &::placeholder {
    color: ${(props) => props.iconColor || COLORS.WHITE};
  }

  &:not(:disabled):focus {
    border-bottom: 2px solid
      ${(props) =>
        props.theme.primaryBackgroundColor || COLORS.BRAND_PURPLE_DEEPER};
    outline: none;
  }
`;

export const StyledIcon = styled(AsurionIcon)<TextFieldIcon>`
  font-size: inherit;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 25px;
  z-index: 2;
  ${(props) => (props.isIconLeft ? "left: 0;" : "right: 0;")};
`;
