import { colors } from "@soluto-private/mx-asurion-ui-react";
import React from "react";
import styled from "styled-components";
import { CardElementContentProps } from "../types";

const CardFooterWrapper = styled.div`
  border-top: 1px solid ${colors.neutralBrighter};
  padding-top: 20px;
  margin-top: 20px;
`;

export function CardFooter({ children }: CardElementContentProps) {
  return <CardFooterWrapper>{children}</CardFooterWrapper>;
}

export default CardFooter;
