import { useCallback, useState } from "react";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import * as qs from "qs";

import { ApiParams, API_URL, HttpMethod } from "../../constants";
/*
  Here are some reference used:
  1. Using keys from an enum, to another type
    - https://stackoverflow.com/questions/44243060/use-enum-as-restricted-key-type-in-typescript
  2. How to use hooks for API data fetching
    - https://www.robinwieruch.de/react-hooks-fetch-data
  3. Some `inspirations` for hooks
    - https://usehooks-typescript.com/react-hook/use-fetch
*/
axios.defaults.baseURL = API_URL;
export function useApiCall<T>(
  url: string,
  method = HttpMethod.GET,
  config?: AxiosRequestConfig
) {
  const [response, setResponse] = useState<T | undefined | null>(undefined);
  const [error, setError] = useState<AxiosError>();
  const [isLoading, setIsLoading] = useState(false);
  const [statusCode, setStatusCode] = useState(0);
  const invokeApi = useCallback(
    async (
      params?: ApiParams | null,
      payload?: ApiParams,
      headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      overrideUrl?: string
      // eslint-disable-next-line consistent-return
    ) => {
      try {
        setIsLoading(true);
        const result = await axios.request({
          headers: {
            ...headers,
          },
          method,
          url,
          baseURL:
            overrideUrl !== undefined ? overrideUrl : axios.defaults.baseURL,
          ...config,
          // eslint-disable-next-line no-unneeded-ternary
          paramsSerializer: () => qs.stringify(params),
          data: payload,
          params,
        });
        setResponse(result.data);
        setStatusCode(result.status);
        return result?.data;
      } catch (e) {
        setError(e as AxiosError);
        setStatusCode(500);
      } finally {
        setIsLoading(false);
      }
    },
    [url, method, config]
  );
  return {
    response,
    error,
    isLoading,
    invokeApi,
    statusCode,
  };
}

export default useApiCall;
