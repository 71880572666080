import { ENTITY_TYPES } from "@apim/lib-portal-entities";
import {
  AsurionDoodleSpinner,
  Button,
  Dropdown,
  getFontSize,
  RadioButton,
  Text,
  TextField,
} from "@soluto-private/mx-asurion-ui-react";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { useAddUserRole, useGetTeams } from "../../api";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  min-width: 350px;
  width: 60vw;
  min-height: 60vh;
  overflow-x: scroll;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 0;
  height: 80px;
`;

const Title = styled.h1`
  font-size: ${getFontSize(3)};
  font-family: Apercu;
`;

const StyledForm = styled.form`
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
`;

const Row = styled.div<{ flexAlign?: "right" | "left" }>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: ${({ flexAlign }) =>
    flexAlign === "right" ? "flex-end" : "flex-start"};
  margin: 20px 0px;
  padding: 0px;
`;

const FieldContainer = styled.div`
  && {
    min-width: 45%;
    width: 100%;
    padding: 0 10px;
  }
`;

const SubmitButton = styled(Button)`
  align-self: flex-end;
`;

const RadioButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${(props) => props.theme.textOnPrimary};
`;

interface AssignUserRoleFormProps {
  setIsModalOpen: (isOpen: boolean) => void;
}

type DropdownOptions = {
  name: string;
  value: string;
};

export default function AssignUserRoleForm({
  setIsModalOpen,
}: AssignUserRoleFormProps) {
  const [teams, setTeams] = useState<DropdownOptions[]>([]);
  const { response: teamListResponse, invokeApi: getTeamList } = useGetTeams();
  const [type, setType] = useState<ENTITY_TYPES>(ENTITY_TYPES.TEAM);
  const [email, setEmail] = useState("");
  const {
    response: addUserRoleResponse,
    isLoading: addRoleIsLoading,
    invokeApi: addUserRole,
  } = useAddUserRole(email);

  useEffect(() => {
    getTeamList();
  }, []);

  useEffect(() => {
    if (addUserRoleResponse) {
      toast.success("Add user role is successful");
    }
  }, [addUserRoleResponse]);

  useEffect(() => {
    if (teamListResponse) {
      const transformedOwnership = teamListResponse.map((item) => ({
        name: item.pk as string,
        value: item.pk as string,
      }));
      setTeams(transformedOwnership);
    }
  }, [teamListResponse]);

  const onSubmitForm = async (formValues: any) => {
    let payload;
    if (type === ENTITY_TYPES.PRODUCT) {
      payload = {
        ownership: "Default",
        role: "ProductOwnerRole",
        ownershipType: ENTITY_TYPES.PRODUCT,
      };
    } else {
      payload = {
        ownership: formValues.team,
        role: formValues.role,
        ownershipType: ENTITY_TYPES.TEAM,
      };
    }
    addUserRole(null, payload);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      team: "",
      role: "",
    },
    onSubmit: onSubmitForm,
    validateOnBlur: true,
  });

  return (
    <StyledContainer>
      <Header>
        <Title>Assign Internal User Role</Title>
        <Button variant="flat" onClick={() => setIsModalOpen(false)}>
          Cancel
        </Button>
      </Header>
      <StyledForm onSubmit={formik.handleSubmit}>
        <Row>
          <FieldContainer>
            <TextField
              label="Email address"
              defaultValue={formik.values.email}
              name="Email address"
              onChange={(value) => {
                formik.handleChange(value);
                setEmail(value.target.value);
              }}
            />
          </FieldContainer>
        </Row>
        <RadioButtonsWrapper>
          <RadioButton
            label="Product Owner"
            name="ownershipType"
            onChange={() => {
              setType(ENTITY_TYPES.PRODUCT);
            }}
            checked={type === ENTITY_TYPES.PRODUCT}
          />
          <RadioButton
            label="Team member"
            color="secondary"
            name="ownershipType"
            onChange={() => {
              setType(ENTITY_TYPES.TEAM);
            }}
            checked={type === ENTITY_TYPES.TEAM}
          />
        </RadioButtonsWrapper>
        {type === ENTITY_TYPES.TEAM && (
          <>
            <Row>
              <FieldContainer>
                <Dropdown
                  label="Team"
                  onChange={(value) => {
                    formik.handleChange(value);
                  }}
                  name="team"
                  options={teams}
                  value={formik.values.team}
                />
              </FieldContainer>
            </Row>
            <Row>
              <FieldContainer>
                <Dropdown
                  label="Role"
                  onChange={(value) => {
                    formik.handleChange(value);
                  }}
                  name="role"
                  options={[
                    {
                      name: "ApiDeveloper",
                      value: "ApiDeveloper",
                    },
                    { name: "TeamOwner", value: "TeamOwner" },
                  ]}
                  value={formik.values.role}
                />
              </FieldContainer>
            </Row>
          </>
        )}
        <Row flexAlign="right">
          <SubmitButton
            color="secondary"
            type="submit"
            disabled={addRoleIsLoading}
          >
            <>
              <Text>Add</Text>
              {addRoleIsLoading && <AsurionDoodleSpinner width="20px" />}
            </>
          </SubmitButton>
        </Row>
      </StyledForm>
    </StyledContainer>
  );
}
