import styled from "styled-components";
import { Button } from "@soluto-private/mx-asurion-ui-react";
import { useState } from "react";

import { useMedia } from "../../hooks";
import { AvatarName } from "../Avatar";
// import { Avatar } from '../index';
import { Menu } from "../Menu/Menu";
import { AvatarMenuBase, AvatarMenuWrapper } from "./elements";
import { IAvatarMenuProps } from "./types";

const StyledButton = styled(Button)`
  color: ${(props) => props.theme.textOnPrimary};
  border-color: ${(props) => props.theme.primaryBorderColor};
  &&&:hover {
    background-color: ${(props) => props.theme.secondaryBackgroundColor};
  }
`;

export function AvatarMenu(props: IAvatarMenuProps) {
  const { user, className, menuItems } = props;
  const [showMenu, setShowMenu] = useState(false);
  const breakpoints = useMedia();

  const onAvatarClick = () => {
    setShowMenu(!showMenu);
  };

  return (
    <AvatarMenuBase isSmall={breakpoints.SM} className={className}>
      <StyledButton
        size="small"
        variant="outline"
        iconSrc="RoundedUser"
        onClick={onAvatarClick}
      >
        <AvatarName>{`${user.userAttributes.given_name} ${user.userAttributes.family_name}`}</AvatarName>
      </StyledButton>
      {showMenu ? (
        <AvatarMenuWrapper>
          <Menu
            menuItems={menuItems}
            collapseParent={() => setShowMenu(false)}
          />
        </AvatarMenuWrapper>
      ) : null}
    </AvatarMenuBase>
  );
}

export default AvatarMenu;
