import styled from "styled-components";
import { Dropdown as SolutoDropdown } from "@soluto-private/mx-asurion-ui-react";

export const Dropdown = styled(SolutoDropdown)`
  background-color: transparent;
  color: ${(props) => props.theme.textOnPrimary};
  &&&:hover,
  &&&:hover:not(:focus):not(:active):not(:disabled) {
    color: ${(props) => props.theme.textOnPrimary};
    border-color: ${(props) => props.theme.textOnPrimary};

    &&& ~ label {
      color: ${(props) => props.theme.textOnPrimary};
    }
  }

  &&&:focus:not(:active):not(:disabled) ~ label {
    color: ${(props) => props.theme.textOnPrimary};
  }

  &&&:not(:focus):not(:active):not(:disabled) ~ label {
    color: "#6e767d";
  }

  &&&:focus:not(:disabled) {
    color: ${(props) => props.theme.textOnPrimary};
    border-color: ${(props) => props.theme.textOnPrimary};
  }

  &&& ~ svg {
    ${(props) => {
      const { theme } = props;
      return `${theme.textOnPrimary}` === "#C1C9D2" ? `filter: invert(1);` : ``;
    }}
  }
`;

export default Dropdown;
