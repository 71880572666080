import { TextField } from "@soluto-private/mx-asurion-ui-react";
import styled from "styled-components";

const SearchField = styled.div`
  width: 30vw;
`;

interface SearchBar {
  onSearchFieldChange?: (e: any) => void;
  searchTerm?: string;
  onSearchSubmit?: () => void;
  onKeyDown?: (event: any) => void;
}

export function SearchBarList({
  onSearchFieldChange,
  searchTerm,
  onSearchSubmit,
  onKeyDown,
}: SearchBar) {
  return (
    <SearchField>
      <TextField
        affixRight="SearchMagnifyGlass"
        affixRightClickable
        onAffixRightClick={onSearchSubmit}
        fieldStatus="default"
        label="Search"
        onChange={onSearchFieldChange}
        onKeyDown={onKeyDown}
        value={searchTerm}
      />
    </SearchField>
  );
}

export default SearchBarList;
