import styled from "styled-components";
import { Button, getFontSize, Text } from "@soluto-private/mx-asurion-ui-react";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.primaryBackgroundColor};
  border-radius: 10px;
  padding: 20px;
  min-width: 400px;
  width: 100%;
  height: 100%;
`;

export const LeftContainer = styled.div`
  display: flex;
  height: 100%;
  flex-wrap: wrap;
  align-items: center;
`;
export const RightContainer = styled.div`
  flex: 0 1 50%;
  min-height: 100%;
  max-width: 50%;
  padding: 0.5em;
`;
export const HalfContainers = styled.div`
  flex: 0 0 100%;
  max-height: 50%;
  min-height: 50%;
  padding: 0.5em;
  overflow: hidden;
`;

export const Select = styled.select`
  width: 100%;
  height: 100%;
  padding: 0.5em;
  font-family: Apercu;
`;

export const Title = styled(Text)`
  font-size: ${getFontSize(3)};
`;

export const SubmitButton = styled(Button)`
  align-self: center;
`;

export const StyledDiv = styled.div`
  width: 40vw;
  display: flex;
  justify-content: center;
  margin-bottom: 4px;
`;
