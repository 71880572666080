import React from "react";
import styled from "styled-components";
import { CardElementContentProps } from "../types";

const CardBodyWrapper = styled.div``;

export function CardBody({ children }: CardElementContentProps) {
  return <CardBodyWrapper>{children}</CardBodyWrapper>;
}

export default CardBody;
