import {
  Checkbox,
  TextField,
  Dropdown,
} from "@soluto-private/mx-asurion-ui-react";
import { useEffect, useState, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";

import { useCreateHelpDoc, useGetGithubRaw } from "../../lib/api";
import {
  RichTextEditor,
  RichTextViewer,
} from "../../lib/components/RichTextEditor";
import { HelpDocMenu } from "./HelpDocMenu";
import {
  HelpDocContainer,
  HelpDocWrapper,
  NewDocHeader,
} from "./HelpDocStyled";

const DEFAULT_EDITOR_HEIGHT = "min-height: 500px !important";
const NewDocEditor = styled(RichTextEditor)`
  ${DEFAULT_EDITOR_HEIGHT}
`;

export function CreateHelpDoc() {
  const roles = [
    {
      name: "ApiDeveloper",
      value: "ApiDeveloper",
    },
    {
      name: "PartnerTemplate",
      value: "PartnerTemplate",
    },
    {
      name: "ProductArchitectRole",
      value: "ProductArchitectRole",
    },
    {
      name: "ProductOwnerRole",
      value: "ProductOwnerRole",
    },
    {
      name: "TeamOwnerRole",
      value: "TeamOwnerRole",
    },
    {
      name: "All",
      value: "All",
    },
  ];
  const [selectedRole, setSelectedRole] = useState("ProductArchitectRole");
  const [pendingPayload, setPendingPayload] = useState(
    "<h3>Start editing here!</h3>"
  );
  const [saveToPending, setSaveToPending] = useState(true);
  const [documentTitle, setDocumentTitle] = useState(
    `New ${"HELPDOC"} Document`
  );
  const [fieldsDisabled, setFieldsDisabled] = useState(false);
  const [helpDocName, setHelpDocName] = useState(`New ${"HELPDOC"} Document`);
  const [previewMode, setPreviewMode] = useState(false);
  const [documentUrl, setDocumentUrl] = useState<string | undefined>();
  const [isDocumentUrlValid, setIsDocumentUrlValid] = useState<boolean>(true);
  const markdownRef = useRef(null);
  const { response: rawContent, invokeApi: getRawContent } =
    useGetGithubRaw(documentUrl);

  const {
    response: docSaveResponse,
    isLoading: isDocumentSending,
    invokeApi: postDocument,
  } = useCreateHelpDoc(selectedRole);
  const history = useHistory();

  // Document change event
  const handleOnDocumentChange = (payload: string) => {
    setPendingPayload(payload);
  };

  const handleDocumentUrlChange = (e: any) => {
    const newValue = e.target.value;
    if (!newValue) {
      setPreviewMode(false);
      setIsDocumentUrlValid(true);
      setDocumentUrl(newValue);
      return;
    }

    try {
      const url = new URL(e.target.value);
      const isValid = !!(
        url &&
        (url.protocol === "http:" || url.protocol === "https:")
      );
      setIsDocumentUrlValid(isValid);
      setPreviewMode(isValid);
    } catch (_) {
      setIsDocumentUrlValid(false);
      setPreviewMode(false);
    }
    setDocumentUrl(newValue);
  };

  const handleDocumentTitleChange = (e: any) => {
    setDocumentTitle(e.target.value);
    setHelpDocName(e.target.value);
  };

  const getPayload = () => {
    if (!documentUrl || !isDocumentUrlValid) {
      return pendingPayload;
    }
    const container = markdownRef.current as any;
    const contents = container?.innerHTML;
    // * Cleanup
    const cleanContent = contents
      .replace('<div class="markdown-body">', "")
      .slice(0, -6);

    return cleanContent;
  };

  const handleDocumentSave = async () => {
    const payloadBuffer = new Blob([getPayload()], {
      type: "text/plain",
    });
    const formData = new FormData();
    formData.append("file", payloadBuffer, `${helpDocName}.txt`);
    formData.append("parent", "HELPDOC");
    formData.append("resource", "HELPDOC");
    await postDocument(null, formData, {
      "Content-Type": "multipart/form-data",
    });
    window.location.reload();
  };

  const handleCancellation = () => {
    history.push(`/help`);
  };

  useEffect(() => {
    setFieldsDisabled(isDocumentSending);

    return () => {};
  }, [setFieldsDisabled, isDocumentSending, pendingPayload]);

  const handlePreviewClick = () => {
    setPreviewMode(!previewMode);
  };

  useEffect(() => {
    if (docSaveResponse) {
      history.push(
        `/help/docs/${encodeURIComponent(
          `HELPDOC/DOC/${selectedRole}/${documentTitle}.txt`
        )}`
      );
    }
    // eslint-disable-next-line
  }, [docSaveResponse, documentTitle, history]);

  useEffect(() => {
    if (documentUrl) {
      getRawContent();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [documentUrl]);

  const Viewer = useCallback(() => {
    return documentUrl && rawContent ? (
      <div ref={markdownRef}>
        <ReactMarkdown className="markdown-body">{`${rawContent}`}</ReactMarkdown>
      </div>
    ) : (
      <RichTextViewer content={pendingPayload} />
    );
  }, [rawContent, documentUrl, pendingPayload]);

  return (
    <HelpDocWrapper>
      <HelpDocContainer>
        <HelpDocMenu
          onCancelClicked={handleCancellation}
          onSaveClicked={handleDocumentSave}
          onPreviewClicked={handlePreviewClick}
          controlsDisabled={fieldsDisabled}
          onDeleteClicked={handleCancellation}
          previewText={previewMode ? "Exit Preview Mode" : "Preview"}
        />
        <NewDocHeader>
          <Checkbox
            label="Save Document as Draft"
            checked={saveToPending}
            onChange={() => setSaveToPending(!saveToPending)}
            disabled={fieldsDisabled}
          />
          <TextField
            value={documentTitle}
            onChange={handleDocumentTitleChange}
            disabled={fieldsDisabled}
            label=""
          />
          <TextField
            value={documentUrl}
            disabled={fieldsDisabled}
            label="Raw file URL (e.g https://raw.githubusercontent.com/asurion/your-repo/master/README.md)"
            defaultValue={documentUrl}
            name="Document URL"
            fieldStatus={isDocumentUrlValid ? "default" : "error"}
            onChange={(value) => {
              handleDocumentUrlChange(value);
            }}
          />
          <Dropdown
            label="Role"
            onChange={(e) => {
              setSelectedRole(e.target.value);
            }}
            name="role"
            options={roles}
            value={selectedRole}
          />
        </NewDocHeader>
        {previewMode ? (
          Viewer()
        ) : (
          <NewDocEditor
            content={pendingPayload}
            onChange={handleOnDocumentChange}
            editorStyle={DEFAULT_EDITOR_HEIGHT}
            disabled={fieldsDisabled}
          />
        )}
      </HelpDocContainer>
    </HelpDocWrapper>
  );
}

export default CreateHelpDoc;
