import { useContext, useEffect, useState } from "react";
import { ENTITY_STATI } from "@apim/lib-portal-entities";
import styled from "styled-components";

import $ from "jquery";
import {
  Tag,
  AsurionDoodleSpinner,
  ErrorPage,
  Text,
  Breadcrumb,
  TagProps,
} from "@soluto-private/mx-asurion-ui-react";
import { useHistory, useParams } from "react-router-dom";
import { load } from "js-yaml";
import { useGetApiRecords, useGetSpecFileCDN } from "../../lib/api";
import { useMedia, useUser, useQuery } from "../../lib/hooks";
import {
  UndecoratedLink,
  SpecViewer as APIelements,
} from "../../lib/components";

import {
  ActionsContainer,
  ApiActionButton,
  ApiContainer,
  ApiDetailContainer,
  ApiDetailsSkeleton,
  ApiDetailWrapper,
  StyledDropdown,
  Wrapper,
  LoaderWrapper,
  TagsWrapper,
  BreadcrumbLink,
} from "./elements";
import { UserOwnershipContext } from "../../lib/hooks/user/user.context";
import { BreadCrumbType } from "../../constants";

function convertYmltoJSON(spectext: string): any {
  const out = load(spectext);
  return out;
}
interface APIProps {
  APIName?: string;
}

interface ITagProps extends TagProps {
  isColored: boolean;
}

const TeamMetaLink = styled(UndecoratedLink)`
  color: ${(props) => props.theme.textOnPrimary};
  text-decoration: underline;
  &:hover {
    color: ${(props) => props.theme.secondaryHover};
  }
`;

const StyledTag = styled(Tag)<ITagProps>`
  ${(props) => props.isColored && `background-color: ${props.theme.secondary};`}
  ${(props) =>
    props.isColored && `color: ${props.theme.secondaryBackgroundColor};`}
`;

export function Api({ APIName }: APIProps) {
  const query: any = useQuery();
  const userTeam = useContext(UserOwnershipContext);
  const [selectedSpec, setSelectedSpec] = useState("");
  const { idParams } = useParams() as any;
  const id = APIName !== undefined ? APIName : idParams;
  const [fromProductPage, setFromProductPage] = useState<BreadCrumbType>();
  const [signedUrl, setSignedUrl] = useState<string>();

  const {
    response: api,
    isLoading,
    error,
    invokeApi: getApiRecords,
  } = useGetApiRecords(id);

  const {
    response: specPayload,
    invokeApi: getSpecPayload,
    isLoading: isSpecLoading,
  } = useGetSpecFileCDN();

  const screenSize = useMedia();
  const [canModify, setCanModify] = useState(false);
  const history = useHistory();
  const [user] = useUser();

  const [spec, setSpec] = useState({});
  const parseSpec = (spec: string) => {
    return typeof spec === "object" ? spec : convertYmltoJSON(spec);
  };

  const REDOC_SMALL_SCREEN_SIZE = 785;

  useEffect(() => {
    getApiRecords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [APIName]);
  useEffect(() => {
    if (history.location.state) {
      const fromPage = history.location.state as BreadCrumbType;
      setFromProductPage(fromPage);
    }
  }, [history]);

  useEffect(() => {
    if (api) {
      if (api.docs.length) {
        setSelectedSpec(api.docs[0].payload as string);
      }
    }
  }, [api]);

  useEffect(() => {
    const env = query.get("spec-env");
    if (api && user) {
      if (api?.docs.length) {
        // * IF `env` is present, set that as default.
        // * IF `env` is not present, display `default` spec
        // * Else display the first spec
        const defaultSpec = env
          ? api.docs.find(
              (doc) => `${doc.sk.split("#")[2]}`.toLowerCase() === env
            )
          : api.docs.find((doc) => doc.isDefault);
        defaultSpec
          ? setSelectedSpec(`${defaultSpec?.payload}`)
          : setSelectedSpec(api.docs[0].payload as string);
      }
      const ownerTeams = api?.owners.map((rel) => rel.pk);

      if (userTeam?.teams?.length) {
        userTeam.teams.forEach((team) => {
          if (ownerTeams.includes(team)) {
            setCanModify(true);
          }
        });
      }
    }
  }, [api, user, userTeam.teams, query]);

  // * Sets the query param to match the selected spec
  useEffect(() => {
    const specEnv = query.get("spec-env") ?? "";
    const isNonProd = selectedSpec.toLowerCase().includes("nonprod");
    const isQueryNonprod = specEnv?.toLowerCase().includes("nonprod");
    const env = !isNonProd ? "prod" : "nonprod";
    if (isNonProd !== isQueryNonprod && APIName === undefined) {
      history.push({
        pathname: `/api/${id}`,
        search: `?spec-env=${env}`,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSpec, query]);

  useEffect(() => {
    if (signedUrl && signedUrl !== "") {
      getSpecPayload(null, undefined, null, signedUrl);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [signedUrl]);

  // * Once and only once we load the `default` spec
  useEffect(() => {
    if (selectedSpec && api) {
      const filteredAPIDoc = api.docs.filter(
        (record) => record.payload === selectedSpec
      );
      setSignedUrl(filteredAPIDoc[0].signedUrl as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSpec]);
  // Collapsing all expanded parameters using jquery
  useEffect(() => {
    $(".sl-flex.sl-items-center.sl-max-w-full.sl-cursor-pointer").trigger(
      "click"
    );
  });

  useEffect(() => {
    const specParse = async () => {
      if (specPayload) {
        const parsedPayload = await parseSpec(specPayload);
        setSpec(parsedPayload);
      }
    };
    specParse();
  }, [specPayload]);

  const onSpecChange = (e: any) => {
    const docname = e.target.value;
    setSelectedSpec(docname);
  };

  const formatDocArray = () => {
    const compare = (doc1: any, doc2: any) => {
      if (doc1.isDefault < doc2.isDefault) return 1;
      if (doc1.isDefault > doc2.isDefault) return -1;
      return 0;
    };
    api?.docs?.sort(compare);
    return api?.docs?.map((doc: any) => {
      return {
        name: `${doc.sk.split("#")[2]}`,
        value: doc.payload,
      };
    });
  };

  const onEditClick = () => {
    history.push(`/api/${id}/settings`);
  };

  const exportPage = () => {
    window.print();
  };
  // * https://github.com/Redocly/redoc/issues/560
  return (
    <Wrapper isMobile={screenSize.width <= REDOC_SMALL_SCREEN_SIZE}>
      {error && error.response && error.response.status === 403 && (
        <ErrorPage
          subtitle="Please contact an Asurion representative if you need access for this API."
          title="Sorry, you don't have permission to view this API"
          actionLabel="Go back to previous page"
          button={{
            color: "secondary",
            onClick: () => history.goBack(),
          }}
        />
      )}
      {isLoading && <ApiDetailsSkeleton />}
      {!isLoading && api && (
        <ApiDetailWrapper>
          <ApiDetailContainer>
            {fromProductPage && (
              <Breadcrumb>
                <BreadcrumbLink to={fromProductPage.link}>
                  {fromProductPage.name}
                </BreadcrumbLink>
                <Text style={{ marginTop: 0 }} as="h1" weight="heavy" size={3}>
                  {api.pk}
                </Text>
              </Breadcrumb>
            )}

            <Text style={{ marginTop: 0 }} as="h1" weight="heavy" size={4}>
              {api.pk}
            </Text>
            {user?.isInternal() && (
              <TagsWrapper>
                <StyledTag
                  text={api.isPublic ? "Public" : "Private"}
                  type={api.isPublic ? "attention" : "gray"}
                  size="regular"
                  isColored={api.isPublic}
                />
                <StyledTag
                  text={
                    api.status === ENTITY_STATI.COMPLETE
                      ? "Completed"
                      : "In progress"
                  }
                  type={
                    api.status === ENTITY_STATI.COMPLETE ? "attention" : "gray"
                  }
                  size="regular"
                  isColored={api.status === ENTITY_STATI.COMPLETE}
                />
              </TagsWrapper>
            )}

            <p>
              <span className="paragraph-title">URI: </span>
              {api.URI}
            </p>
            <p>
              <span className="paragraph-title">Description: </span>
              {api.description}
            </p>
            {user?.isInternal() && (
              <p style={{ marginBottom: "10px" }}>
                <span className="paragraph-title">Team: </span>
                <TeamMetaLink to={`/teams/${api.team}`}>
                  {api.team}
                </TeamMetaLink>
              </p>
            )}
            {canModify && (
              <ActionsContainer>
                <ApiActionButton
                  size="small"
                  variant="outline"
                  color="primary"
                  iconSrc="DataDownload"
                  iconSide="right"
                  onClick={exportPage}
                >
                  Export page
                </ApiActionButton>
                {canModify && (
                  <ApiActionButton
                    size="small"
                    variant="outline"
                    color="secondary"
                    iconSrc="EditPencilChange"
                    iconSide="right"
                    onClick={onEditClick}
                  >
                    Edit
                  </ApiActionButton>
                )}
              </ActionsContainer>
            )}
            <ApiContainer>
              <StyledDropdown
                label="Documents"
                options={formatDocArray() || []}
                onChange={onSpecChange}
                value={selectedSpec}
              />
            </ApiContainer>
          </ApiDetailContainer>
        </ApiDetailWrapper>
      )}
      {!isLoading && api && api.docs.length <= 0 && user && user.isInternal() && (
        <ErrorPage
          title="No API specification found."
          onClick={() => onEditClick()}
          actionLabel="Upload API specification"
          button={{
            color: "secondary",
          }}
        />
      )}
      {!isLoading && api && api.docs.length > 0 && (
        <>
          {isSpecLoading && (
            <LoaderWrapper>
              <AsurionDoodleSpinner />
            </LoaderWrapper>
          )}
          {!isSpecLoading && (
            // @ts-ignore
            <APIelements oasFile={spec} />
          )}
        </>
      )}
    </Wrapper>
  );
}

export default Api;
