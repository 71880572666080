import { Tabs } from "@soluto-private/mx-asurion-ui-react";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import { COLORS } from "../../constants";
import { TextField } from "../../lib/components";
import { Teams, Users } from "./elements";

const AdminBackground = styled.div`
  background-color: ${COLORS.NEUTRAL_BRIGHTER};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 30px;
  flex-wrap: wrap;
  row-gap: 20px;
`;

const AdminMenu = styled.div`
  min-height: 4vw;
  min-width: 90vw;
  background-color: ${COLORS.WHITE};
  border-radius: 5px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AdminDashboard = styled.div`
  min-height: 60vh;
  min-width: 90vw;
  max-width: 90vw;
  background-color: ${COLORS.WHITE};
  border-radius: 5px;
  padding: 20px;
`;

const SearchContainer = styled.div`
  width: 350px;
`;

export function Admin() {
  const location = useLocation();
  const { pathname } = location;
  const [searchTerm, setSearchTerm] = useState("");

  const history = useHistory();

  const onProfileViewClick = (pk: string) => {
    history.push(`/account/${pk}`);
  };

  return (
    <AdminBackground>
      <AdminMenu>
        <Tabs
          tabs={[
            // Hiding it for MVP
            // {
            //   color: "#000000",
            //   iconLeftSrc: "IconPlaceholder",
            //   onClick: () => history.push("/admin"),
            //   title: "Overview",
            // },
            {
              color: "#000000",
              iconLeftSrc: "User",
              onClick: () => history.push("/admin/users"),
              title: "Users",
            },
            {
              color: "#000000",
              iconLeftSrc: "User",
              onClick: () => history.push("/admin/teams"),
              title: "Teams",
            },
            // {
            //   color: "#000000",
            //   iconLeftSrc: "IconPlaceholder",
            //   onClick: () => history.push("/admin/roles"),
            //   title: "Roles",
            // },
          ]}
          variant="regular"
        />
        <SearchContainer>
          <TextField
            affixRight="SearchMagnifyGlass"
            fieldStatus="default"
            label={
              pathname.includes("teams")
                ? "Search by team name or owner name"
                : "Search"
            }
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </SearchContainer>
      </AdminMenu>
      <AdminDashboard>
        {!pathname.includes("users") &&
          !pathname.includes("roles") &&
          !pathname.includes("teams") && <p>Overview</p>}
        {pathname.includes("users") && (
          <Users
            onProfileViewClick={onProfileViewClick}
            searchTerm={searchTerm}
          />
        )}
        {pathname.includes("teams") && <Teams searchTerm={searchTerm} />}
        {pathname.includes("roles") && <p>Roles</p>}
      </AdminDashboard>
    </AdminBackground>
  );
}

export default Admin;
