import styled from "styled-components";
import { IMenuPropsBase } from "../types";

export const MenuBase = styled.div<IMenuPropsBase>`
  background-color: ${(props) => props.theme.primaryBackgroundColor};
  text-align: center;
  width: ${(props) => props.menuWidth || 190}px;
`;

export default MenuBase;
