import { useState, useMemo } from "react";

import { useLocalStorage } from "./localStorage.hook";
import { THEME_MODE_KEY } from "../../constants";
import { DEFAULT_THEME, ExtendedTheme } from "../../themes";

export type ThemeModeType = "dark" | "light";

export function useTheme() {
  const [themeMode, setThemeMode] = useLocalStorage<ThemeModeType>(
    THEME_MODE_KEY,
    "dark"
  );
  const [localTheme, setLocalTheme] = useLocalStorage<ExtendedTheme>(
    themeMode,
    DEFAULT_THEME.dark
  );

  const [themeModeState, setThemeModeState] =
    useState<ThemeModeType>(themeMode);
  const [themeState, setThemeState] = useState<ExtendedTheme>(localTheme);

  useMemo(() => {
    const selectedTheme: ExtendedTheme =
      themeModeState === "dark" ? DEFAULT_THEME.dark : DEFAULT_THEME.light;
    setThemeState(selectedTheme);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [themeModeState]);

  const toggleTheme = () => {
    const modeVariant = themeModeState === "light" ? "dark" : "light";
    setThemeModeState(modeVariant);
    setThemeMode(modeVariant);
    setLocalTheme(DEFAULT_THEME[modeVariant]);
    setThemeState(DEFAULT_THEME[modeVariant]);
  };

  return {
    theme: themeState,
    themeMode: themeModeState,
    saveThemeLocally: setLocalTheme,
    toggleTheme,
    setThemeMode,
  };
}

export default useTheme;
