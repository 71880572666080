import { useApiCall } from "../hooks";
import { HttpMethod, RoleType, UserRole } from "../../constants";
import { TeamMemberDTO } from "./team.api";

export function useGetRoles() {
  return useApiCall<RoleType[]>("/access/role");
}

export function useAddUserRole(userId: string) {
  return useApiCall<UserRole>(`/access/user/${userId}/role`, HttpMethod.POST);
}

export function useAddProductArchitect(userId: string, productName: string) {
  // TODO how to throw error if productName is empty
  return useApiCall<UserRole>(
    `/access/user/${userId}/architect/${productName}`,
    HttpMethod.PUT
  );
}

export function useGetTeamMembers(name: string) {
  return useApiCall<TeamMemberDTO[] | null | undefined>(
    `/access/user/team/${name}`
  );
}
