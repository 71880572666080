import {
  AsurionDoodleSpinner,
  Button,
  Dropdown,
  getFontSize,
  PredictiveTextField,
  Text,
  TextField,
} from "@soluto-private/mx-asurion-ui-react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  PORTAL_INTERNAL_COMPANY,
  TEAM_NAME_VALIDATION,
} from "../../../constants";
import { useGetUserByCompany } from "../../api";

const StyledForm = styled.form`
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 0;
  margin: 0;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  min-width: 400px;
  width: 40vw;
  height: auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 0;
  height: 80px;
`;

const Body = styled.div`
  display: flex;
  flex-grow: 0;
  height: auto;
  padding: 1em;
`;

const Title = styled.h1`
  font-size: ${getFontSize(3)};
  font-family: Apercu;
`;

const Row = styled.div<{ flexAlign?: "right" | "left" }>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: ${({ flexAlign }) =>
    flexAlign === "right" ? "flex-end" : "flex-start"};
  margin: 20px 0px;
  padding: 0px;
`;

const FieldContainer = styled.div`
  && {
    min-width: 45%;
    width: 100%;
    padding: 0 10px;
  }
`;

const SubmitButton = styled(Button)`
  align-self: flex-end;
`;

const STATUS_OPTIONS = [
  { name: "Draft", value: "DRAFT" },
  { name: "Completed", value: "COMPLETE" },
];

interface UserOptionDTO {
  header: string;
  id: string;
  subHeader: string;
  value: string;
}

interface ITeamFormProps {
  onCancel?: any;
  onSubmit?: any;
  isLoading?: any;
}

export function TeamForm({ onCancel, onSubmit, isLoading }: ITeamFormProps) {
  const { response: internalUsers, invokeApi: getInternalUsers } =
    useGetUserByCompany(PORTAL_INTERNAL_COMPANY);
  const [userOptions, setUserOptions] = useState<UserOptionDTO[]>();
  const [selectedUser, setSelectedUser] = useState<string>();

  const TeamFormSchema = Yup.object().shape({
    teamName: Yup.string()
      .required("Team name required.")
      .matches(
        TEAM_NAME_VALIDATION,
        "Only alphanumeric characters and -,_ are allowed for the team name."
      ),
    description: Yup.string().required("Description required."),
  });
  useEffect(() => {
    getInternalUsers();
  }, [getInternalUsers]);

  useEffect(() => {
    const mappedData: UserOptionDTO[] | undefined = internalUsers?.map(
      (user) => {
        return {
          header: user.pk,
          id: user.pk,
          subHeader: `${user.firstname}, ${user.lastname}`,
          value: user.pk,
        };
      }
    );
    setUserOptions(mappedData ?? []);
  }, [internalUsers]);

  const formik = useFormik({
    initialValues: {
      teamName: "",
      description: "",
      owner: "",
      status: "",
    },
    onSubmit,
    validationSchema: TeamFormSchema,
    validateOnBlur: true,
  });

  return (
    <StyledContainer>
      <Header>
        <Title>Create Team</Title>
        <Button variant="flat" onClick={onCancel}>
          Cancel
        </Button>
      </Header>
      <Body>
        <StyledForm onSubmit={formik.handleSubmit}>
          <Row>
            <FieldContainer>
              <TextField
                label="Team Name"
                defaultValue={formik.values.teamName}
                name="teamName"
                fieldStatus={formik.errors.teamName ? "error" : "default"}
                helperText={
                  formik.errors.teamName ? formik.errors.teamName : ""
                }
                onChange={(value) => {
                  formik.handleChange(value);
                }}
              />
            </FieldContainer>
          </Row>
          <Row>
            <FieldContainer>
              <TextField
                label="Description"
                defaultValue={formik.values.description}
                name="description"
                fieldStatus={formik.errors.description ? "error" : "default"}
                helperText={
                  formik.errors.description ? formik.errors.description : ""
                }
                onChange={formik.handleChange}
              />
            </FieldContainer>
          </Row>
          <Row>
            <FieldContainer>
              <PredictiveTextField
                label="Owner"
                suggestions={userOptions}
                handleChange={(value) => {
                  formik.values.owner = value;
                  setSelectedUser(value);
                }}
                onSelection={(value) => {
                  formik.values.owner = value.id;
                  setSelectedUser(value.header);
                }}
                value={selectedUser}
              />
            </FieldContainer>
          </Row>
          <Row>
            <FieldContainer>
              <Dropdown
                options={STATUS_OPTIONS}
                label="Status"
                name="status"
                value={formik.values.status}
                onChange={formik.handleChange}
              />
            </FieldContainer>
          </Row>
          <Row flexAlign="right">
            <SubmitButton type="submit" disabled={isLoading}>
              <>
                <Text>Save</Text>
                {isLoading && <AsurionDoodleSpinner width="20px" />}
              </>
            </SubmitButton>
          </Row>
        </StyledForm>
      </Body>
    </StyledContainer>
  );
}

export default TeamForm;
