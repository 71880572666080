import { useRef } from "react";
import { AsurionDoodleSpinner } from "@soluto-private/mx-asurion-ui-react";
import styled from "styled-components";
import SunEditor from "suneditor-react"; // Import Sun Editor's CSS File
import SunEditorCore from "suneditor/src/lib/core";

import { docSectionTitle } from "./customPlugins";
import { RichTextEditorWrapper } from "./elements";
import { RichTextEditorProps } from "./types";

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export function RichTextEditor({
  content,
  onChange,
  disabled = false,
  className,
  editorStyle,
  isLoading = false,
}: RichTextEditorProps) {
  const editorRef = useRef<SunEditorCore>();
  const getSunEditorInstance = (sunEditor: SunEditorCore) => {
    editorRef.current = sunEditor;
  };

  return (
    <RichTextEditorWrapper className={className}>
      {isLoading ? (
        <LoadingContainer>
          <AsurionDoodleSpinner />
        </LoadingContainer>
      ) : (
        <SunEditor
          getSunEditorInstance={getSunEditorInstance}
          onChange={onChange}
          defaultValue={content}
          disable={disabled}
          setOptions={{
            buttonList: [
              [
                "undo",
                "redo",
                "fontSize",
                "formatBlock",
                "blockquote",
                "bold",
                "underline",
                "italic",
                "strike",
                "subscript",
                "superscript",
                "fontColor",
                "hiliteColor",
                "removeFormat",
                "outdent",
                "indent",
                "align",
                "horizontalRule",
                "list",
                "table",
                "link",
                "image",
                "video",
                "fullScreen",
              ],
            ],
            defaultStyle: `font-family: Apercu; font-size: 1rem; height: auto; ${editorStyle}; `,
            customPlugins: [docSectionTitle],
          }}
        />
      )}
    </RichTextEditorWrapper>
  );
}

export default RichTextEditor;
