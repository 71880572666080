import { Plugin } from "suneditor/src/plugins/Plugin";

// const docSectionTitle: Plugin & any = null;

export const docSectionTitle: Plugin & any = {
  // @Required @Unique
  // plugin name
  name: "sectionTitle",

  // @Required
  // data display
  display: "submenu",

  // @Options
  title: "Add section title",

  buttonClass: "",

  innerHTML:
    '<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M17 2H7C5.897 2 5 2.898 5 4V21C5 21.369 5.203 21.708 5.528 21.882C5.852 22.056 6.247 22.037 6.555 21.832L12 18.203L17.445 21.832C17.613 21.944 17.806 22 18 22C18.162 22 18.324 21.961 18.472 21.882C18.797 21.708 19 21.369 19 21V4C19 2.898 18.103 2 17 2ZM17 19.132L12.555 16.168C12.219 15.944 11.781 15.944 11.445 16.168L7 19.132V4H17V19.132Z"></path></svg>',

  // @Required
  // add function - It is called only once when the plugin is first run.
  // This function generates HTML to append and register the event.
  // arguments - (core : core object, targetElement : clicked button element)
  add(core: any, targetElement: any) {
    // @Required
    // Registering a namespace for caching as a plugin name in the context object
    const { context } = core;
    context.customSubmenu = {
      targetButton: targetElement,
      textElement: null,
      currentSpan: null,
    };

    // Generate submenu HTML
    // Always bind "core" when calling a plugin function
    const listDiv = this.setSubmenu(core);

    // Input tag caching
    context.customSubmenu.textElement = listDiv.querySelector("input");

    // You must bind "core" object when registering an event.
    /** add event listeners */
    listDiv
      .querySelector(".se-btn-primary")
      .addEventListener("click", this.onClick.bind(core));
    // @Required
    // You must add the "submenu" element using the "core.initMenuTarget" method.
    /** append target button menu */
    core.initMenuTarget(this.name, targetElement, listDiv);
  },

  setSubmenu(core: any) {
    const listDiv = core.util.createElement("DIV");
    // @Required
    // A "se-submenu" class is required for the top level element.
    listDiv.className = "se-menu-container se-submenu se-list-layer";
    listDiv.innerHTML =
      "" +
      '<div class="se-list-inner">' +
      '<ul class="se-list-basic" style="width: 230px;">' +
      "<li>" +
      '<div class="se-form-group">' +
      '<input class="se-input-form" type="text" placeholder="Section title" style="border: 1px solid #CCC; padding: 0 8px" />' +
      '<button type="button" class="se-btn-primary se-tooltip">' +
      "<strong>OK</strong>" +
      "</button>" +
      "</div>" +
      "</li>" +
      "</ul>" +
      "</div>";

    return listDiv;
  },

  // @Override core
  // Plugins with active methods load immediately when the editor loads.
  // Called each time the selection is moved.
  active(element: any) {
    // If no tag matches, the "element" argument is called with a null value.
    if (!element) {
      this.util.removeClass(this.context.customSubmenu.targetButton, "active");
      this.context.customSubmenu.textElement.value = "";
      this.context.customSubmenu.currentSpan = null;
    } else if (this.util.hasClass(element, "se-custom-tag")) {
      this.util.addClass(this.context.customSubmenu.targetButton, "active");
      this.context.customSubmenu.textElement.value = element.textContent;
      this.context.customSubmenu.currentSpan = element;
      return true;
    }

    return false;
  },

  // @Override submenu
  // Called after the submenu has been rendered
  on() {
    this.context.customSubmenu.textElement.focus();
  },

  onClick() {
    const value = this.context.customSubmenu.textElement.value.trim();
    if (!value) return;

    if (this.getContents() !== "<p><br></p>") {
      this.functions.appendContents("");
      this.functions.appendContents(
        `<span class="se-custom-tag doc_section_title" id="${value}">${value}</span>`
      );
    } else {
      this.functions.setContents(
        `<span class="se-custom-tag doc_section_title" id="${value}">${value}</span>`
      );
    }
    this.functions.appendContents("");
    this.context.customSubmenu.textElement.value = "";

    this.submenuOff();
  },
};

export default docSectionTitle;
