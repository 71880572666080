import { ENTITY_STATI } from "@apim/lib-portal-entities";
import {
  AsurionDoodleSpinner,
  Button,
} from "@soluto-private/mx-asurion-ui-react";
import $ from "jquery";

import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { API as APIelements } from "@stoplight/elements";
import { load } from "js-yaml";
import styled from "styled-components";
// import { useStoplightLayout } from "src/lib/hooks";
import { Dropdown } from "src/lib/components/Dropdown";
import { useGetSpecFileCDN } from "../../../lib/api";
import { useUser } from "../../../lib/hooks";
import {
  ActionsWrapper,
  MetaRow,
  MetaRowDropdown,
  MetaWrapper,
  MetaWrapperDropdown,
  SectionContentDivider,
  SectionHeader,
  SectionTitle,
  StyledTag,
} from "./elements";
import { BreadCrumbType } from "../../../constants";

interface IAPI {
  api: any;
  productName: string;
  onNavClick: () => void;
}

const StyledDropdown = styled(Dropdown)`
  width: 170px;
  background-color: ${(props) => props.theme.primaryBackgroundColor};
  color: ${(props) => props.theme.textOnPrimary};
`;

export const StyledMinimizeButton = styled(Button)`
  color: ${(props) => props.theme.invertedTextOnPrimary};
  float: left;
  z-index: 1;
  position: absolute;
  display: inline-block;
  &&:hover {
    cursor: pointer;
    border: none;
    color: ${(props) => props.theme.textOnSecondary};
    background: ${(props) => props.theme.secondary};
  }
`;

export const LoaderWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const buttonWrapper = styled.div`
  justify-content: center;
  align-items: center;
  height: 100%;
`;

function convertYmltoJSON(spectext: string): any {
  const out = load(spectext);
  return out;
}

const parseSpec = (spec: string) => {
  return typeof spec === "object" ? spec : convertYmltoJSON(spec);
};

function ProductAPI({ api, productName, onNavClick }: IAPI) {
  const [selectedSpec, setSelectedSpec] = useState("");
  const [spec, setSpec] = useState({});
  const history = useHistory();
  const [user] = useUser();
  // const { layout } = useStoplightLayout();
  const ref: any = useRef<HTMLDivElement>();
  const firstRender = useRef(true);
  const [isMinimizedNavAPI, setIsMinimizedNavAPI] = useState(false);
  const [signedUrl, setSignedUrl] = useState<string>();
  const {
    response: specPayload,
    invokeApi: getSpecPayload,
    isLoading: isSpecLoading,
  } = useGetSpecFileCDN();

  useEffect(() => {
    const specParse = async () => {
      if (specPayload) {
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        const parsedPayload = await parseSpec(specPayload);
        console.log(parsedPayload);
        setSpec(parsedPayload);
      }
    };
    specParse();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [specPayload]);

  // useEffect(() => {
  //   const elem: any = document.querySelectorAll(".sl-my-3");
  //   if (elem) {
  //     for (let i = 0; i < elem.length; i += 1) {
  //       for (let j = 0; j < elem[i].children.length; j += 1) {
  //         elem[i].children[j].click();
  //       }
  //     }
  //   }
  // });

  useEffect(() => {
    if (!firstRender.current) {
      getSpecPayload(null, undefined, null, selectedSpec);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedSpec]);

  useEffect(() => {
    if (signedUrl && signedUrl !== "") {
      getSpecPayload(null, undefined, null, signedUrl);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [signedUrl]);

  useEffect(() => {
    if (api.docs[0]) {
      setSignedUrl(api.docs[0].signedUrl as string);
      setSelectedSpec(api.docs[0].signedUrl as string);
    }
    if (false) {
      onNavClick();
    }
  }, []);

  const formatDocArray = () => {
    const compare = (doc1: any, doc2: any) => {
      if (doc1.isDefault < doc2.isDefault) return 1;
      if (doc1.isDefault > doc2.isDefault) return -1;
      return 0;
    };
    api?.docs?.sort(compare);
    return api?.docs?.map((doc: any) => {
      return {
        name: `${doc.sk.split("#")[2]}`,
        value: doc.signedUrl,
      };
    });
  };

  const onSpecChange = (e: any) => {
    const docUrl = e.target.value;
    setSelectedSpec(docUrl);
  };

  const toggleSpecNavBar = () => {
    const divId = `#${api.title.replace(
      / /g,
      "-"
    )} > div > div > div > div > .sl-flex.sl-overflow-y-auto.sl-flex-col.sl-sticky`;
    console.log(divId);
    if ($(divId).css("display") === "block") {
      $(divId).css("display", "none");
    } else {
      $(divId).css("display", "block");
    }
  };

  return (
    <div ref={ref}>
      <MetaWrapper>
        <MetaRow>
          {user && user.isInternal() && (
            <>
              <StyledTag
                text={api?.isPublic ? "Public" : "Private"}
                type={api?.isPublic ? "attention" : "gray"}
                isColored={api?.isPublic}
              />
              <StyledTag
                text={
                  api?.status === ENTITY_STATI.COMPLETE
                    ? "Completed"
                    : "In progress"
                }
                type={
                  api?.status === ENTITY_STATI.COMPLETE ? "attention" : "gray"
                }
                isColored={api?.status === ENTITY_STATI.COMPLETE}
              />
            </>
          )}
        </MetaRow>
        <MetaRow>
          <SectionHeader>
            <div>
              <SectionTitle>{api.title}</SectionTitle>
              {user && user.isInternal() && (
                <ActionsWrapper>
                  <Button
                    size="small"
                    variant="text"
                    onClick={() => {
                      history.push(`/api/${api.title}`, {
                        link: `/products/${productName}`,
                        name: productName,
                      } as BreadCrumbType);
                    }}
                    style={{ display: "inline" }}
                  >
                    View api details
                  </Button>
                </ActionsWrapper>
              )}
            </div>
            <MetaWrapperDropdown>
              <MetaRowDropdown>
                <StyledDropdown
                  label="Documents"
                  options={formatDocArray() || []}
                  onChange={onSpecChange}
                  value={selectedSpec}
                />
              </MetaRowDropdown>
            </MetaWrapperDropdown>
          </SectionHeader>
        </MetaRow>
        <MetaRow>
          <p>{api?.description}</p>
        </MetaRow>
      </MetaWrapper>
      <SectionContentDivider />
      {isSpecLoading ? (
        <LoaderWrapper>
          <AsurionDoodleSpinner />
        </LoaderWrapper>
      ) : (
        // @ts-ignore
        <>
          <StyledMinimizeButton
            size="small"
            variant="flat"
            style={{
              marginLeft: isMinimizedNavAPI ? 0 : "18%",
            }}
            iconSrc={
              isMinimizedNavAPI ? "CardsNavigationChevronRight" : "CloseX"
            }
            onClick={() => {
              toggleSpecNavBar();
              setIsMinimizedNavAPI &&
                setIsMinimizedNavAPI((curreValue) => !curreValue);
            }}
          />
          <div id={api.title.replace(/ /g, "-")}>
            <APIelements apiDescriptionDocument={spec} router="memory" />
          </div>
        </>
      )}
    </div>
  );
}

export default ProductAPI;
