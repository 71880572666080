import { API as APIelements } from "@stoplight/elements";

import { useStoplightLayout } from "src/lib/hooks";

export function SpecViewer(props: any) {
  const { oasFile } = props;
  const { layout } = useStoplightLayout();
  return (
    <APIelements
      layout={layout}
      apiDescriptionDocument={oasFile}
      router="hash"
    />
  );
}

export default SpecViewer;
