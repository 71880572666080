import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import { Tab, TabGroup } from "../../lib/components/TabGroup";
import { ITabData } from "../../lib/components/TabGroup/types";

const TabGroupWrapper = styled.div`
  padding: 24px 48px;
`;

const Tabs = [
  {
    tabLabel: "Tab 1",
    content: <div>Hi</div>,
  },
  {
    tabLabel: "Tab 2",
    content: <div>Hello</div>,
  },
  {
    tabLabel: "Tab 3",
    content: <div>What&#39;s up</div>,
  },
];

export function TabGroupPage() {
  const [, setActiveTab] = useState(1);
  const { search } = useLocation();
  const history = useHistory();

  const onTabSelected = (tabData: ITabData) => {
    setActiveTab(tabData.tabIndex);
    history.push(`/tabgroup?tab=${tabData.tabLabel}`);
  };

  useEffect(() => {
    let unmounted = false;
    const urlParams = new URLSearchParams(search);
    const selectedTab = urlParams.get("tab");

    if (!unmounted) {
      const selectedTabIndex = Tabs.findIndex(
        (tab) => tab.tabLabel === selectedTab
      );
      setActiveTab(!selectedTabIndex ? 0 : selectedTabIndex);
    }

    return () => {
      unmounted = true;
    };
  }, [search]);

  return (
    <TabGroupWrapper>
      <TabGroup onActivate={onTabSelected}>
        {Tabs.map((item) => (
          <Tab tabLabel={item.tabLabel} key={item.tabLabel}>
            {item.content}
          </Tab>
        ))}
      </TabGroup>
    </TabGroupWrapper>
  );
}

export default TabGroupPage;
