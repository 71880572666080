import styled from "styled-components";
import { getFontSize } from "@soluto-private/mx-asurion-ui-react";

const Wrapper = styled.p`
  display: flex;
  font-size: ${getFontSize(1)};
  display: inline-block;
  font-size: 0.875rem;
  color: inherit;
  margin: 0;
  vertical-align: middle;
  width: 100%;
`;

export function Copyright() {
  const currentYear = new Date().getFullYear();

  return (
    <Wrapper>
      ©Asurion 1992-
      {currentYear}. All Rights Reserved.
    </Wrapper>
  );
}

export default Copyright;
