import { useContext } from "react";
import { Guard, PORTAL_INTERNAL_COMPANY } from "../../../constants";
import { useUser } from "./useUser.hook";
import { UserOwnershipContext } from "./user.context";
import { useLocalStorage } from "../localStorage.hook";

/*

  Currently we are using the Principal Token from local storage for checking the permission.
  We however cannot check for the user's role which is why some guards are commented out for now.

*/

export function UserGuard(): Guard {
  const [principal] = useLocalStorage("principal");
  return {
    canActivate: () => !!principal,
  };
}

export function UnAuthenticatedGuard(): Guard {
  const [principal] = useLocalStorage("principal");
  return {
    canActivate: () => !principal,
  };
}

export function InternalUserGuard(): Guard {
  // we cannot cast this to a Principal Object so we cannot use the methods from Principal
  // and if we use the userContext refreshing would cause it to go back to home page
  // as a workaround, we are comparing the literal values from the local storage to our constant value for internal users
  const [principal] = useLocalStorage("principal");
  return {
    canActivate: () => {
      if (principal) {
        return (
          principal.userAttributes.company.toLowerCase() ===
          PORTAL_INTERNAL_COMPANY
        );
      }
      return false;
    },
  };
}

export function ExternalUserGuard(): Guard {
  // we cannot cast this to a Principal Object so we cannot use the methods from Principal
  // and if we use the userContext refreshing would cause it to go back to home page
  // as a workaround, we are comparing the literal values from the local storage to our constant value for internal users
  const [principal] = useLocalStorage("principal");
  return {
    canActivate: () => {
      if (principal) {
        return (
          principal.userAttributes.company.toLowerCase() !==
          PORTAL_INTERNAL_COMPANY
        );
      }
      return false;
    },
  };
}

export function AdminGuard(): Guard {
  const [user] = useUser();
  const userOwnership = useContext(UserOwnershipContext);
  const userTeams = userOwnership?.teams?.map((team) => team.toLowerCase());
  return {
    canActivate: () => !!user && !!userTeams?.includes("aaim"),
  };
}

// export function DeveloperGuard(): Guard {
//   let [user] = useLocalStorage("principal");
//   user = JSON.parse(user);
//   const apiWriteTeams = user?.getUserOwnershipsWithMinRights(["*:api:write"]);
//   return {
//     canActivate: () => !!user && !!apiWriteTeams?.teams?.length,
//   };
// }

// export function ProductOwnerGuard(): Guard {
//   let [user] = useLocalStorage("principal");
//   user = JSON.parse(user);
//   const userManagerTeams = user?.getUserOwnershipsWithMinRights([
//     "*:product:manage",
//     "*:user:manage",
//   ]);
//   return {
//     canActivate: () => !!user && !!userManagerTeams?.teams?.length,
//   };
// }

// export function ArchitectGuard(): Guard {
//   let [user] = useLocalStorage("principal");
//   user = JSON.parse(user);
//   const productWriteTeams = user?.getUserOwnershipsWithMinRights([
//     "*:product:write",
//   ]);
//   return {
//     canActivate: () => !!user && !!productWriteTeams?.teams.length,
//   };
// }

// export function PartnerGuard(): Guard {
//   let [user] = useLocalStorage("principal");
//   user = JSON.parse(user);
//   return {
//     canActivate: () => {
//       if (user) {
//         return user && !isInternalUser(user);
//       }
//       return false;
//     },
//   };
// }

// * Based from this logic/snippet
// public getUserRole() {
//   if (this.isInternal()) {
//      const userTeams = this.getAllUserOwnership().teams;
//      if (userTeams?.includes('AAIM')) {
//        return "Admin";
//      } else {
//        const apiWriteTeams = this.getUserOwnershipsWithMinRights(['*:api:write']);
//        if (apiWriteTeams.teams?.length > 0) {
//          return "Api Developer"
//        } else {
//         const userManagerTeams = this.getUserOwnershipsWithMinRights(['*:product:manage','*:user:manage']);
//         if (userManagerTeams.teams?.length > 0) {
//           return "Product Owner";
//         } else {
//           const productWriteTeams = this.getUserOwnershipsWithMinRights(['*:product:write']);
//           if (productWriteTeams.teams?.length > 0)
//           return "Product Architect"
//           else
//           return ""
//         }
//        }
//      }
//   } else {
//     return "Partner";
//   }
// }
