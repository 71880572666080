import styled from "styled-components";

import { NavigationTabs } from "../../NavigationTabs";
import { TabButton } from "../../TabButton";
import { ITabGroupNavigationProps, ITabProps } from "../types";
import { generateTabItemKey } from "../utils";

const NavigationWrapper = styled(NavigationTabs)<{
  navPosition: "right" | "left" | "center";
}>`
  justify-content: ${(props) =>
    props.navPosition === "left" ? "flex-start" : "flex-end"};
  background-color: ${({ theme }) => theme.primaryBackgroundColor};
  padding-bottom: 15px;
`;

export function TabGroupNavigation(props: ITabGroupNavigationProps) {
  const { navPosition, selectedTab, disabled, tabContents, setSelectedTab } =
    props;
  return (
    <NavigationWrapper navPosition={navPosition}>
      {tabContents.map((tab: ITabProps, i: number) => {
        const tabKey = generateTabItemKey(tab.tabLabel, i);
        const isActive = tabKey === selectedTab;

        return (
          <TabButton
            key={tabKey}
            prependIcon={tab.prependIcon}
            appendIcon={tab.appendIcon}
            id={tabKey}
            onClick={() => setSelectedTab(tabKey, tab.tabLabel)}
            active={isActive}
            className={isActive ? "active-tab-nav" : ""}
            disabled={disabled}
          >
            {tab.tabLabel}
          </TabButton>
        );
      })}
    </NavigationWrapper>
  );
}

export default TabGroupNavigation;
