import {
  StyledContainer,
  HalfContainers,
  LeftContainer,
  RightContainer,
} from "./elements";
// import { PersonalDetails } from "./PersonalDetails";
// import { ActivityDetails } from "./ActivityDetails";
import { UserTeams } from "../Team";
import { useUser } from "../../lib/hooks";

export function UserDetails() {
  const [user] = useUser();
  return (
    <StyledContainer>
      <LeftContainer>
        <HalfContainers>{/* <PersonalDetails /> */}</HalfContainers>
        <HalfContainers>
          {user && user.isInternal() && <UserTeams />}
        </HalfContainers>
      </LeftContainer>
      <RightContainer>{/* <ActivityDetails /> */}</RightContainer>
    </StyledContainer>
  );
}

export default UserDetails;
