import { TextField as AsurionTextField } from "@soluto-private/mx-asurion-ui-react";
import styled from "styled-components";
import { SearchFieldWrapper, StyledIcon, SearcFieldInput } from "./elements";
import { CustomTextFieldProps } from "./types";

const StyledTextField = styled(AsurionTextField)`
  && {
    #asurion-ui-text-field-l013cayc0-field-container
      > div:not(.is-disabled):hover:not(.has-focus) {
      border-color: red;
    }
  }

  && {
    /* .has-hover {
      border-color: ${(props) => props.theme.textOnPrimary};
      color: ${(props) => props.theme.textOnPrimary};
    } */
  }
`;

// ! https://github.com/styled-components/styled-components/issues/540
export function TextField(props: CustomTextFieldProps) {
  const { type, iconLeft, iconRight, onIconClick, value, iconColor, ...rest } =
    props;

  if (!type || type === "default") {
    return <StyledTextField {...rest} />;
  }

  return (
    <SearchFieldWrapper>
      {iconLeft && (
        <StyledIcon
          src={iconLeft}
          fill={iconColor || "white"}
          onClick={onIconClick}
          isIconLeft={!!iconLeft}
        />
      )}
      <SearcFieldInput
        iconLeft={iconLeft}
        iconColor={iconColor}
        iconRight={iconRight}
        {...rest}
      />
      {iconRight && (
        <StyledIcon
          src={iconRight}
          fill={iconColor || "white"}
          onClick={onIconClick}
          isIconLeft={!iconRight}
        />
      )}
    </SearchFieldWrapper>
  );
}

export default TextField;
