import { SITE_META_URLS } from "../../../constants";
import { Copyright } from "../Copyright";
import { useMedia } from "../../hooks";
import {
  StyledMetaAnchorLink,
  FooterWrapper,
  ContentWrapper,
  MetadataWrapper,
  CopyrightWrapper,
  BottomRowBottomContent,
  BottomRow,
} from "./elements";

const RenderMetadata = () => {
  const links = SITE_META_URLS.map((siteMetadata) => (
    <StyledMetaAnchorLink href={siteMetadata.url} key={siteMetadata.title}>
      {siteMetadata.title}
    </StyledMetaAnchorLink>
  ));
  return links;
};

export function Footer() {
  const breakpoints = useMedia();

  return (
    <FooterWrapper>
      <ContentWrapper>
        <BottomRow>
          <BottomRowBottomContent
            isLarge={breakpoints.LG}
            isMobile={breakpoints.XS}
            isSmall={breakpoints.SM}
          >
            <CopyrightWrapper>
              <Copyright />
            </CopyrightWrapper>
            <MetadataWrapper>{RenderMetadata()}</MetadataWrapper>
          </BottomRowBottomContent>
        </BottomRow>
      </ContentWrapper>
    </FooterWrapper>
  );
}

export default Footer;
