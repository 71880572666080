import styled from "styled-components";
import { useParams } from "react-router-dom";

import { Sidebar } from "../lib/components";

const SampleGreeting = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 80%;
  align-items: center;
  margin: auto;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
`;

interface DummyType {
  id?: string;
}

export function Account() {
  const { id } = useParams<DummyType>();
  return (
    <Wrapper>
      <Sidebar
        links={[
          { title: "blank target", isTargetBlank: true, url: "/" },
          { title: "not blank target", isTargetBlank: false, url: "/" },
        ]}
        open
      />
      <SampleGreeting>
        <h1>
          User id
          {id}
        </h1>
        <h1>This is the User Account page</h1>
      </SampleGreeting>
    </Wrapper>
  );
}

export default Account;
