import styled from "styled-components";
import { INavbarMenuIconProps } from "../types";

export const NavbarMenuIcon = styled.div<INavbarMenuIconProps>`
  display: ${(props) => (props.isSmall ? "" : "none")};
  &:hover {
    cursor: pointer;
  }
`;

export default NavbarMenuIcon;
