import { Button, Icon, Switch } from "@soluto-private/mx-asurion-ui-react";
import { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Auth } from "aws-amplify";
import { BarLoader } from "react-spinners";

import {
  NavbarLinks,
  NavbarBrand,
  NavbarLink,
  NavbarWrapper,
  NavbarUser,
  NavbarMenuIcon,
  NavbarContainer,
} from "./elements";
import { INavbarLinkProps } from "./types";
import { IMenuItemProps } from "../Menu/types";
import { AvatarMenu } from "../AvatarMenu";
import {
  useMedia,
  useQuery,
  UserContext,
  UserOwnershipContext,
  useUser,
  // useStoplightLayout,
} from "../../hooks";
import { AvatarWrapper } from "../AvatarMenu/elements";
import { Avatar, AvatarName } from "../Avatar";
import { useTheme } from "../../hooks/theme.hook";

const logout = async () => {
  try {
    // await api.post('/api/auth/logout/')
    if (await Auth.currentSession()) {
      await Auth.signOut();
      window.location.reload();
    }
  } catch (e) {
    throw new Error(`${e}`);
  }
};

// TODO: Dynamically load menu
const userMenuItems: IMenuItemProps[] = [
  {
    title: "My Profile",
    url: "/user/profile",
    menuType: "link",
  },
  {
    title: "Admin Dashboard",
    url: "/admin/users",
    menuType: "link",
  },
  {
    title: "Change Password",
    url: "/change-password",
    menuType: "link",
  },
  {
    title: "Sign Out",
    menuType: "button",
    onClick: logout,
    url: "/",
  },
];

const navBarLinkItems: INavbarLinkProps[] = [
  {
    url: "/home",
    title: "Home",
  },
  {
    url: "/products",
    title: "Products",
  },
  {
    url: "/apis",
    title: "APIs",
  },

  {
    url: "/help",
    title: "Help",
  },
  // {
  //   url: "/about-us",
  //   title: "About Us",
  // },
];

// TODO use this when we add back the theming

interface ListItemProps extends React.HTMLProps<HTMLLIElement> {
  isSmall?: boolean;
}

const NavbarListItem = styled.li<ListItemProps>`
  ${(props) =>
    props.isSmall &&
    `
    padding: 20px;
  `}
`;

const NavigationActionWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
`;

const NavbarUserControls = styled.li<ListItemProps>`
  ${(props) =>
    !props.isSmall &&
    `
    display: none;
  `}
  ${(props) =>
    props.isSmall &&
    `
    padding: 10px;
    display: block;
  `}
`;

const BarLoaderWrapper = styled.div`
  position: fixed;
  top: 75px;
  z-index: 99999;
`;

const StyledSwitch = styled(Switch)`
  [type="checkbox"]:checked + span::before {
    background: ${({ theme }) => theme.primary};
  }
  span::before {
    background: ${({ theme }) => theme.secondary};
  }

  margin-right: 1rem;
`;

export function Navbar({ toggleTheme }: { toggleTheme?: () => void }) {
  const [showMenu, setShowMenu] = useState(false);
  const [navbarItems, setNavbarItems] = useState(navBarLinkItems);
  const breakpoints = useMedia();
  const { user } = useContext(UserContext);
  const history = useHistory();
  // const { layout, setLayout } = useStoplightLayout();
  const [menu, setMenu] = useState<IMenuItemProps[]>([]);
  const userOwnership = useContext(UserOwnershipContext);
  const { themeMode, theme } = useTheme();
  const [themeLabel, setThemeLabel] = useState(
    themeMode === "light" ? "Light" : "Dark"
  );

  const query = useQuery();
  const [, , isSigningIn, setIsSigningIn] = useUser();

  useEffect(() => {
    if (query) {
      if (query.get("code") !== "" && !!query.get("code")) {
        setIsSigningIn(true);
      }
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [query]);

  useEffect(() => {
    if (user) {
      let filteredMenu =
        user && user.getCompany() === "asurion"
          ? userMenuItems.filter((obj) => {
              return obj.title !== "Change Password";
            })
          : userMenuItems;
      filteredMenu =
        user &&
        !user
          ?.getUserTeams()
          ?.map((team) => team.toLowerCase())
          .includes("aaim")
          ? filteredMenu.filter((obj) => {
              return obj.title !== "Admin Dashboard";
            })
          : filteredMenu;

      setMenu(filteredMenu);
    }
  }, [user, userOwnership]);

  const login = () => {
    history.push("/login");
  };

  useEffect(() => {
    if (user) {
      if (!user.isInternal()) {
        const items = navBarLinkItems.filter((item) => {
          return item.title !== "APIs";
        });
        setNavbarItems(items);
      } else setNavbarItems(navBarLinkItems);
    } else {
      const items = navBarLinkItems.filter((item) => {
        return item.title !== "APIs" && item.title !== "Products";
      });
      setNavbarItems(items);
    }
  }, [user]);

  return (
    <>
      <NavbarWrapper isSmall={breakpoints.SM}>
        <NavbarContainer>
          <NavbarBrand />
          <NavbarMenuIcon
            isSmall={breakpoints.SM}
            onClick={() => setShowMenu(!showMenu)}
          >
            <Icon
              aria-hidden="true"
              fill="#333F48"
              size="large"
              src="HamburgerMenu"
              title=""
            />
          </NavbarMenuIcon>
          <NavbarLinks isSmall={breakpoints.SM} showMenu={showMenu}>
            {navbarItems.map((item) => (
              <NavbarListItem key={item.title} isSmall={breakpoints.SM}>
                <NavbarLink
                  to={item.url}
                  activeStyle={{
                    fontWeight: "bold",
                  }}
                >
                  {item.title}
                </NavbarLink>
              </NavbarListItem>
            ))}
            {user ? (
              <NavbarUserControls isSmall={breakpoints.SM}>
                <AvatarWrapper>
                  <AvatarName>{`${user.userAttributes.given_name} ${user.userAttributes.family_name}`}</AvatarName>
                  <Avatar imageUrl="/img/profile.png" avatarSize="xs" />
                </AvatarWrapper>
              </NavbarUserControls>
            ) : (
              <NavbarUserControls isSmall={breakpoints.SM}>
                <Button
                  color="secondary"
                  iconSide="left"
                  iconSrc=""
                  onClick={() => {
                    setShowMenu(false);
                    login();
                  }}
                  size="small"
                  variant="default"
                  style={{ width: "75%", margin: "10px auto" }}
                >
                  Log in
                </Button>
              </NavbarUserControls>
            )}
            {user && (
              <NavbarUserControls isSmall={breakpoints.SM}>
                <Button
                  color="secondary"
                  iconSide="left"
                  iconSrc="SystemAlertAlert"
                  onClick={() => {
                    setShowMenu(false);
                    logout();
                  }}
                  variant="default"
                  style={{ width: "75%", margin: "0 auto" }}
                >
                  Sign out
                </Button>
              </NavbarUserControls>
            )}
          </NavbarLinks>
          <NavigationActionWrapper>
            <StyledSwitch
              label={themeLabel}
              color="secondary"
              labelPosition="right"
              checked={themeMode === "light"}
              onChange={() => {
                if (toggleTheme) {
                  toggleTheme();
                }
                setThemeLabel((prevState) => {
                  return prevState === "Light" ? "Dark" : "Light";
                });
              }}
            />
            {/* <StyledSwitch
              label={layout.toLocaleUpperCase()}
              color="secondary"
              labelPosition="right"
              checked={layout === "sidebar"}
              onChange={() => {
                setLayout((prevState) => {
                  return prevState === "sidebar" ? "stacked" : "sidebar";
                });
              }}
            /> */}
            <NavbarUser isSmall={breakpoints.SM}>
              {user ? (
                <AvatarMenu
                  menuItems={menu}
                  imageUrl="/img/profile.png"
                  user={user}
                />
              ) : (
                <Button
                  color="secondary"
                  iconSide="left"
                  onClick={login}
                  variant="default"
                  size="small"
                  disabled={isSigningIn}
                >
                  Log in
                </Button>
              )}
            </NavbarUser>
          </NavigationActionWrapper>
        </NavbarContainer>
      </NavbarWrapper>
      <BarLoaderWrapper>
        {/* eslint-disable-next-line  react/jsx-boolean-value */}
        <BarLoader
          height={4}
          width="100vw"
          loading={isSigningIn}
          color={theme.secondary}
        />
      </BarLoaderWrapper>
    </>
  );
}

export default Navbar;
