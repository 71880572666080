import { colors, Text } from "@soluto-private/mx-asurion-ui-react";
import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { IGettingStartedProps, IStarterLink } from "../types";

const LinksContainer = styled.div`
  background-color: ${({ theme }) => theme.primaryBackgroundColor};
  position: absolute;
  top: 56px;
  padding: 24px;
  z-index: 7;
  display: flex;
  width: 100%;
  justify-content: center;
`;

const StarterLinkWrapper = styled(Link)`
  text-decoration: none;
  padding: 24px;
  border-right: 1px solid ${colors.neutralDeep};
  :last-child {
    border-width: 0;
  }
  max-width: 182px;
`;

const StarterLinkTitle = styled(Text)`
  display: block;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.textOnPrimary};
`;

const StarterLinkText = styled(Text)`
  color: ${({ theme }) => theme.textOnPrimary};
`;

function StarterLink(props: IStarterLink) {
  const { url, description, title } = props;
  return (
    <StarterLinkWrapper to={url}>
      <StarterLinkTitle weight="heavy">{title}</StarterLinkTitle>
      <StarterLinkText>{description}</StarterLinkText>
    </StarterLinkWrapper>
  );
}

export function GettingStartedLinks(props: IGettingStartedProps) {
  const history = useHistory();
  const { collapseEvent, links } = props;

  // Subscribe to route changes to collapse
  useEffect(() => {
    let isMounted = true;

    history.listen(() => {
      if (isMounted && collapseEvent) {
        collapseEvent();
      }
    });

    return () => {
      isMounted = false;
    };
  }, [history, props, collapseEvent]);

  return (
    <LinksContainer onMouseLeave={collapseEvent}>
      {links.map((link: IStarterLink) => (
        <StarterLink {...link} key={link.title} />
      ))}
    </LinksContainer>
  );
}

export default GettingStartedLinks;
