import styled from "styled-components";
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import {
  getFontSize,
  TextField,
  Button,
  Checkbox,
  AsurionDoodleSpinner,
  Text,
  Textarea,
} from "@soluto-private/mx-asurion-ui-react";
import * as Yup from "yup";

import { GenericDTO, API_NAME_VALIDATION } from "../../../constants";
import { useUser } from "../../hooks";
import { useGetTeams } from "../../api";
import { Dropdown } from "../Dropdown";

const STATUS_OPTIONS = [
  { name: "Draft", value: "DRAFT" },
  { name: "Completed", value: "COMPLETE" },
];

const StyledForm = styled.form`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.secondaryBackgroundColor};
  padding: 0;
  margin: 0;
`;

const StyledCheckbox = styled(Checkbox)`
  &&& ~ span.ui-checkbox__desc {
    color: ${(props) => props.theme.textOnPrimary} !important;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.secondaryBackgroundColor};
  border-radius: 10px;
  padding: 20px;
  min-width: 400px;
  width: 40vw;
  height: auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 0;
  height: 80px;
`;

const Body = styled.div`
  display: flex;
  flex-grow: 0;
  height: auto;
  padding: 1em;
`;

const Title = styled.h1`
  font-size: ${getFontSize(3)};
  font-family: Apercu;
  color: ${(props) => props.theme.invertedTextOnSecondary};
`;

const Row = styled.div<{ flexAlign?: "right" | "left" }>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: ${({ flexAlign }) =>
    flexAlign === "right" ? "flex-end" : "flex-start"};
  margin: 20px 0px;
  padding: 0px;
`;

const FieldContainer = styled.div`
  && {
    min-width: 45%;
    width: 100%;
    padding: 0 10px;
  }
`;

const SubmitButton = styled(Button)`
  align-self: flex-end;
`;

interface ApiFormProps {
  api?: GenericDTO;
  onCancel?: any;
  onSubmit?: any;
  isLoading?: any;
}

interface API {
  name: string;
  description: string;
  status: string;
  isPublic: boolean;
  URI: string;
}

const ApiFormSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "API name required to be at least 3 characters long")
    .required("API name required.")
    .matches(
      API_NAME_VALIDATION,
      "Only alphanumeric characters, spaces, and '/' are allowed in the API name."
    ),
  description: Yup.string().required("Description required."),
  URI: Yup.string().required("URI required."),
  team: Yup.string().required("Team is required."),
});

export function ApiForm(props: ApiFormProps) {
  const [apiName, setApiName] = useState("");
  const [user] = useUser();
  const [userTeams, setUserTeams] = useState<
    Array<{ name: string; value: string }>
  >([]);
  const { response: teamListResponse, invokeApi: getTeamList } = useGetTeams();

  const { api, onCancel, onSubmit, isLoading } = props;

  const formik = useFormik({
    initialValues: {
      name: api?.pk || apiName,
      description: api?.description || "",
      status: api?.status || "DRAFT",
      isPublic: !!api?.isPublic,
      URI: api?.URI || "",
      team: api?.team || "",
    } as API & { team: string },
    onSubmit,
    validationSchema: ApiFormSchema,
    validateOnBlur: true,
  });

  useEffect(() => {
    if (user) {
      const getInfo = async () => {
        const teamSet = new Set();
        await user?.scopeStore
          ?.filter((scope) => scope.includes("TEAM#"))
          .forEach((teamScope: string) => {
            const teamPrefix = teamScope.split(":")[0]; // * TEAM#name
            const name = teamPrefix.split("#")[1]; // * name
            teamSet.add(name);
          });
        const teams: { name: string; value: string }[] = [];
        if (teamSet.has("aaim")) {
          await getTeamList();
          teamListResponse?.forEach((item) =>
            teams.push({
              name: item.pk as string,
              value: item.pk as string,
            })
          );
        } else {
          teamSet.forEach((team: any) =>
            teams.push({ name: team, value: team })
          );
        }
        await setUserTeams(teams);
      };
      getInfo();
    }
  }, [user, teamListResponse, getTeamList]);

  return (
    <StyledContainer>
      <Header>
        <Title>{api ? "Edit API" : "Add API"}</Title>
        <Button variant="flat" onClick={onCancel}>
          Cancel
        </Button>
      </Header>
      <Body>
        <StyledForm onSubmit={formik.handleSubmit}>
          <Row>
            <FieldContainer>
              <TextField
                label="Name"
                defaultValue={formik.values.name}
                name="name"
                onChange={(value) => {
                  formik.handleChange(value);
                  setApiName(value.target.value);
                }}
                fieldStatus={formik.errors.name ? "error" : "default"}
                helperText={
                  formik.errors.name ? (formik.errors.name as string) : ""
                }
              />
            </FieldContainer>
          </Row>
          <Row>
            <FieldContainer>
              <TextField
                label="URI Path"
                defaultValue={formik.values.URI}
                name="URI"
                onChange={formik.handleChange}
                fieldStatus={formik.errors.URI ? "error" : "default"}
                helperText={
                  formik.errors.URI ? (formik.errors.URI as string) : ""
                }
              />
            </FieldContainer>
            <FieldContainer>
              <Dropdown
                options={STATUS_OPTIONS}
                label="Status"
                name="status"
                value={formik.values.status}
                onChange={formik.handleChange}
              />
            </FieldContainer>
          </Row>
          <Row>
            <FieldContainer>
              <Dropdown
                options={userTeams ?? []}
                label="Team"
                name="team"
                value={formik.values.team}
                onChange={formik.handleChange}
                fieldStatus={formik.errors.team ? "error" : "default"}
                helperText={
                  formik.errors.team ? (formik.errors.team as string) : ""
                }
              />
            </FieldContainer>
          </Row>
          <Row>
            <FieldContainer>
              <Textarea
                label="Description"
                name="description"
                defaultValue={formik.values.description}
                resize={false}
                value={formik.values.description}
                onChange={formik.handleChange}
                fieldStatus={formik.errors.description ? "error" : "default"}
                helperText={
                  formik.errors.description
                    ? (formik.errors.description as string)
                    : ""
                }
              />
            </FieldContainer>
          </Row>
          <Row>
            <StyledCheckbox
              checked={formik.values.isPublic}
              label="Public"
              onChange={formik.handleChange}
              name="isPublic"
            />
          </Row>
          <Row flexAlign="right">
            <SubmitButton color="secondary" type="submit" disabled={isLoading}>
              <>
                <Text>Save</Text>
                {isLoading && <AsurionDoodleSpinner width="20px" />}
              </>
            </SubmitButton>
          </Row>
        </StyledForm>
      </Body>
    </StyledContainer>
  );
}

export default ApiForm;
