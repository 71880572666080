import {
  createContext,
  Dispatch,
  SetStateAction,
  useMemo,
  useState,
  useContext,
} from "react";

export type StoplightLayoutContextType = "stacked" | "sidebar";
export type StoplightLayoutSetStateType = Dispatch<
  SetStateAction<StoplightLayoutContextType>
>;

interface ContextState {
  layout: StoplightLayoutContextType;
  setLayout: StoplightLayoutSetStateType;
}

export const StoplightLayoutContext = createContext<ContextState>({
  layout: "sidebar",
  setLayout: () => {},
});

type StoplightLayoutProps = {
  children: JSX.Element;
};

export function StoplightLayoutProvider(props: StoplightLayoutProps) {
  const { children } = props;
  const [layout, setLayout] = useState<StoplightLayoutContextType>("sidebar");
  const memoizedUserData = useMemo(
    () => ({
      layout,
      setLayout,
    }),
    [layout, setLayout]
  );

  return (
    <StoplightLayoutContext.Provider value={memoizedUserData}>
      {children}
    </StoplightLayoutContext.Provider>
  );
}

export function useStoplightLayout() {
  const stoplightContext = useContext<ContextState>(StoplightLayoutContext);
  return {
    layout: stoplightContext.layout,
    setLayout: stoplightContext.setLayout,
  };
}
