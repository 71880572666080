import React from "react";
import styled from "styled-components";
import { CardElementContentProps } from "../types";

const CardHeaderWrapper = styled.div`
  padding-bottom: 12px;
  margin-bottom: 12px;
`;

export function CardHeader({ children }: CardElementContentProps) {
  return <CardHeaderWrapper>{children}</CardHeaderWrapper>;
}

export default CardHeader;
