import {
  AsurionDoodleSpinner,
  Button,
  Dropdown,
  getFontSize,
  Text,
  TextField,
} from "@soluto-private/mx-asurion-ui-react";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { useCreateAPIKey, useGetTeams } from "../../api";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  min-width: 350px;
  width: 60vw;
  min-height: 60vh;
  overflow-x: scroll;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 0;
  height: 80px;
`;

const Title = styled.h1`
  font-size: ${getFontSize(3)};
  font-family: Apercu;
`;

const StyledForm = styled.form`
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
`;

const Row = styled.div<{ flexAlign?: "right" | "left" }>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: ${({ flexAlign }) =>
    flexAlign === "right" ? "flex-end" : "flex-start"};
  margin: 20px 0px;
  padding: 0px;
`;

const FieldContainer = styled.div`
  && {
    min-width: 45%;
    width: 100%;
    padding: 0 10px;
  }
`;

const SubmitButton = styled(Button)`
  align-self: flex-end;
`;

interface CreateAPIKeyFormProps {
  setIsModalOpen: (isOpen: boolean) => void;
}

type DropdownOptions = {
  name: string;
  value: string;
};

export default function CreateAPIKeyForm({
  setIsModalOpen,
}: CreateAPIKeyFormProps) {
  const [teams, setTeams] = useState<DropdownOptions[]>([]);
  const { response: teamListResponse, invokeApi: getTeamList } = useGetTeams();
  const {
    response: createAPIKeyResponse,
    isLoading: createAPIKeyIsLoading,
    invokeApi: createApiKey,
  } = useCreateAPIKey();

  useEffect(() => {
    getTeamList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (teamListResponse) {
      const transformedOwnership = teamListResponse.map((item) => ({
        name: item.pk as string,
        value: item.pk as string,
      }));
      setTeams(transformedOwnership);
    }
  }, [teamListResponse]);

  const onSubmitForm = async (formValues: any) => {
    const payload = {
      name: formValues.name,
      description: formValues.description,
      team: formValues.team,
    };
    await createApiKey(null, payload);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      team: "",
    },
    onSubmit: onSubmitForm,
    validateOnBlur: true,
  });

  useEffect(() => {
    if (createAPIKeyResponse) {
      if (createAPIKeyResponse.key) {
        toast.success(
          `API Key named ${formik.values.name} created for ${createAPIKeyResponse.team}`,
          {
            autoClose: false,
          }
        );
      } else {
        toast.error(createAPIKeyResponse);
      }
      setIsModalOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createAPIKeyResponse]);

  return (
    <StyledContainer>
      <Header>
        <Title>Create API Key</Title>
        <Button variant="flat" onClick={() => setIsModalOpen(false)}>
          Cancel
        </Button>
      </Header>
      <StyledForm onSubmit={formik.handleSubmit}>
        <Row>
          <FieldContainer>
            <TextField
              label="Name"
              defaultValue={formik.values.name}
              name="name"
              onChange={(value) => {
                formik.handleChange(value);
              }}
            />
          </FieldContainer>
        </Row>
        <Row>
          <FieldContainer>
            <TextField
              label="Description"
              name="description"
              defaultValue={formik.values.description}
              onChange={formik.handleChange}
            />
          </FieldContainer>
        </Row>
        <Row>
          <FieldContainer>
            <Dropdown
              label="Team"
              onChange={(value) => {
                formik.handleChange(value);
              }}
              name="team"
              options={teams}
              value={formik.values.team}
            />
          </FieldContainer>
        </Row>
        <Row flexAlign="right">
          <SubmitButton
            color="secondary"
            type="submit"
            disabled={createAPIKeyIsLoading}
          >
            <>
              <Text>Create</Text>
              {createAPIKeyIsLoading && <AsurionDoodleSpinner width="20px" />}
            </>
          </SubmitButton>
        </Row>
      </StyledForm>
    </StyledContainer>
  );
}
