import { RouteProps, Route, Redirect } from "react-router-dom";
import { GuardFunction } from "../../constants";

type ProtectedRouteProps = RouteProps & {
  guards?: Array<GuardFunction>;
  redirectTo?: string; // If authorization fails, will redirect here.
};

export function ProtectedRoute(props: ProtectedRouteProps) {
  const { guards = [], redirectTo = "/" } = props;

  const isAllowed = guards.some((guard) => guard().canActivate());
  return !isAllowed ? <Redirect to={redirectTo} /> : <Route {...props} />;
}

export default ProtectedRoute;
