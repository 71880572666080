import { useCallback } from "react";

import { useApiCall } from "../hooks";
import { Product } from "../../models";
import { GenericDTO, HttpMethod } from "../../constants";

interface IProductsPaginated {
  products: (Product & GenericDTO)[];
  lastKey: any;
}

// * https://stackoverflow.com/questions/1051182/what-is-a-data-transfer-object-dto
export function useGetProducts() {
  const { invokeApi, ...rest } =
    useApiCall<(GenericDTO & Product)[]>("/catalog/product");

  const getProducts = useCallback(
    async (params: { status?: string }) => {
      const defaultParams = {
        status: params.status,
      };
      await invokeApi({ ...defaultParams, ...params });
    },
    [invokeApi]
  );

  return { ...rest, getProducts };
}

export function useGetProductsPaginated() {
  return useApiCall<IProductsPaginated>("/catalog/product/paginated");
}

export function useAddProduct(name: string) {
  return useApiCall<any>(`/catalog/product/${name}`, HttpMethod.POST);
}

export function useUpdateProduct(name: string) {
  return useApiCall<any>(`/catalog/product/${name}/info`, HttpMethod.PUT);
}

export function useGetProduct(pk: string) {
  return useApiCall<GenericDTO | null>(`/catalog/product/${pk}`);
}

export function useGetProductDoc(productName: string) {
  return useApiCall<any | null>(
    `/catalog/product/${productName}/doc`,
    HttpMethod.GET
  );
}

export function useGetProductRecords(productName: string) {
  return useApiCall<any>(
    `/catalog/product/${productName}/records`,
    HttpMethod.GET
  );
}

export function useUpdateProductDoc(productName: string, productDoc: string) {
  return useApiCall<any>(
    `/catalog/product/${productName}/doc/${productDoc}`,
    HttpMethod.PUT,
    {
      headers: {
        "Content-Type": "text/plain",
      },
    }
  );
}

export function useDeleteProduct(productName: string) {
  const { invokeApi, ...rest } = useApiCall<any>(
    `/catalog/product/${encodeURIComponent(productName)}`,
    HttpMethod.DELETE
  );

  return { ...rest, invokeApi };
}

export function useCreateProductDoc(prodName: string) {
  const { invokeApi, ...rest } = useApiCall<GenericDTO>(
    `/catalog/product/${prodName}/doc`,
    HttpMethod.POST,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return { ...rest, invokeApi };
}

export function useGetGithubRaw(url?: string) {
  const { invokeApi, ...rest } = useApiCall<GenericDTO>("", HttpMethod.GET, {
    headers: {
      "Content-Type": null,
    },
    baseURL: url,
  });

  return { ...rest, invokeApi };
}

export function useProductLinkApi(productName: string, apiName: string) {
  const { invokeApi, ...rest } = useApiCall<GenericDTO>(
    `/catalog/product/${productName}/api/${apiName}`,
    HttpMethod.POST
  );

  return { ...rest, invokeApi };
}

export function useProductUnLinkApi(productName: string, apiName: string) {
  const { invokeApi, ...rest } = useApiCall<GenericDTO>(
    `/catalog/product/${productName}/api/${apiName}`,
    HttpMethod.DELETE
  );

  return { ...rest, invokeApi };
}

export function useProductLinkMultipleApi(productName: string) {
  const { invokeApi, ...rest } = useApiCall<GenericDTO>(
    `/catalog/product/${productName}/api`,
    HttpMethod.POST
  );
  return { ...rest, invokeApi };
}

export function useInviteProductUser(productName: string) {
  const { invokeApi, ...rest } = useApiCall<GenericDTO>(
    `/catalog/product/${productName}/invite-user`,
    HttpMethod.POST
  );

  return { ...rest, invokeApi };
}

export function useDeleteProductDocument(productName: string, docName: string) {
  const { invokeApi, ...rest } = useApiCall<GenericDTO>(
    `/catalog/product/${productName}/doc/${docName}`,
    HttpMethod.DELETE
  );

  return { ...rest, invokeApi };
}

export function useGetProductDocCDN() {
  return useApiCall<any | null>(``, HttpMethod.GET);
}
