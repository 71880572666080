import styled from "styled-components";
import { useFormik, FormikValues } from "formik";
import {
  TextField,
  Button,
  getFontSize,
} from "@soluto-private/mx-asurion-ui-react";
import * as Yup from "yup";
import { Auth } from "aws-amplify";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useContext } from "react";
import { UserContext } from "../../lib/hooks";

const SetupSchema = Yup.object().shape({
  userName: Yup.string().required("Required"),
  verificationCode: Yup.string().required("Required"),
  newPassword: Yup.string()
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    )
    .required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .required("Required"),
});

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const FormContainer = styled.div`
  width: 500px;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  margin: ${getFontSize(3)};
`;

const Row = styled.div`
  margin: 10px 5px;
`;

const StyledForm = styled.form`
  margin: 0;
  display: flex;
  flex-direction: column;
  padding: 0;
`;

// * `useQuery` would still be our go-to query-param hook,
// * I just created this so I can unescape `+` symbol
const getQueryStringParams = (query: string) =>
  query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split("&")
        .reduce((params: any, param: string) => {
          const [key, value] = param.split("=");
          // eslint-disable-next-line no-param-reassign
          params[key] = value ?? null;
          return params;
        }, {})
    : {};

export function VerificationCodeEntry() {
  const { search } = useLocation();
  const query = getQueryStringParams(search);
  const { isSigningIn, setIsSigningIn } = useContext(UserContext);
  const userName = query?.username ?? "";
  const history = useHistory();

  const handleSubmit = async (values: FormikValues) => {
    setIsSigningIn(true);
    const { userName, verificationCode, newPassword } = values;
    Auth.forgotPasswordSubmit(userName, verificationCode, newPassword)
      .then(() => {
        toast.success("Successfully updated your password.");
        history.push("/login");
        setIsSigningIn(false);
      })
      .catch((err) => {
        toast.error(err.message);
        setIsSigningIn(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      userName,
      verificationCode: "",
      newPassword: "",
      confirmPassword: "",
    },
    onSubmit: handleSubmit,
    validationSchema: SetupSchema,
    validateOnBlur: true,
  });

  return (
    <Wrapper>
      <FormContainer>
        <StyledForm onSubmit={formik.handleSubmit as any}>
          <Row>
            Please check your registerd email and enter the verification code
          </Row>
          <Row>
            <TextField
              label="User Name"
              defaultValue={formik.values.userName}
              name="userName"
              type="text"
              fieldStatus={formik.errors.userName ? "error" : "default"}
              helperText={
                formik.errors.userName ? (formik.errors.userName as string) : ""
              }
              onChange={(value) => {
                formik.handleChange(value);
              }}
            />
          </Row>
          <Row>
            <TextField
              label="Verification Code"
              defaultValue={formik.values.verificationCode}
              name="verificationCode"
              type="text"
              fieldStatus={formik.errors.verificationCode ? "error" : "default"}
              helperText={
                formik.errors.userName
                  ? (formik.errors.verificationCode as string)
                  : ""
              }
              onChange={(value) => {
                formik.handleChange(value);
              }}
            />
          </Row>
          <Row>
            <TextField
              label="New Password"
              defaultValue={formik.values.newPassword}
              type="password"
              name="newPassword"
              fieldStatus={formik.errors.newPassword ? "error" : "default"}
              helperText={
                formik.errors.newPassword
                  ? (formik.errors.newPassword as string)
                  : ""
              }
              onChange={(value) => {
                formik.handleChange(value);
              }}
            />
          </Row>
          <Row>
            <TextField
              label="Confirm Password"
              defaultValue={formik.values.confirmPassword}
              type="password"
              name="confirmPassword"
              fieldStatus={formik.errors.confirmPassword ? "error" : "default"}
              helperText={
                formik.errors.confirmPassword
                  ? (formik.errors.confirmPassword as string)
                  : ""
              }
              onChange={(value) => {
                formik.handleChange(value);
              }}
            />
          </Row>
          <Button type="submit" disabled={isSigningIn}>
            Submit
          </Button>
        </StyledForm>
      </FormContainer>
    </Wrapper>
  );
}

export default VerificationCodeEntry;
