import {
  colors,
  Text,
  Tag,
  AsurionDoodleSpinner,
  DynamicGrid,
  Switch,
  ErrorPage,
  Card,
  getFontSize,
} from "@soluto-private/mx-asurion-ui-react";
import { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import Modal from "styled-react-modal";
import { API, ENTITY_STATI } from "@apim/lib-portal-entities";
import InfiniteScroll from "react-infinite-scroller";
import { useUser, UserOwnershipContext } from "../../lib/hooks";
import {
  ApiForm,
  ProductListSkeleton,
  TextField,
  UndecoratedLink,
  Button,
  Dropdown,
} from "../../lib/components";
import { useAddApi, useGetApisPaginated } from "../../lib/api";

// TODO: Fetch APIs / Product list from service

const ApisWrapper = styled.div`
  width: 90vw;
  padding: 32px 15px 0 15px;
  margin: 0 auto;
`;

const StyledTag = styled(Tag)`
  background-color: ${({ theme }) => theme.secondary};
  color: ${({ theme }) => theme.secondaryBackgroundColor};
`;
const ApiMetaContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 12px;
`;
const StyledSwitch = styled(Switch)`
  [type="checkbox"]:checked + span::before {
    background: ${({ theme }) => theme.primary};
  }
`;
const ApiMetaLink = styled(UndecoratedLink)`
  color: ${colors.black};
`;

const ApiFooter = styled.div`
  border-top: 1px solid ${colors.neutralBright};
  text-align: left;
`;

const ApiSearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  height: 10vh;
`;

const ApiSetContainer = styled.div`
  margin: 12px auto;
  overflow: auto;
  width: 90vw;
`;

const StyledButton = styled(Button)`
  &&& svg {
    filter: ${({ theme }) => {
      const { textOnPrimary } = theme;
      return (textOnPrimary as string) === "#C1C9D2"
        ? `invert(91%) sepia(21%) saturate(129%) hue-rotate(178deg) brightness(86%) contrast(89%);`
        : `invert(21%) sepia(22%) saturate(480%) hue-rotate(163deg) brightness(95%) contrast(90%);`;
    }};
  }

  &&&:hover {
    background-color: ${({ theme }) => theme.secondaryBackgroundColor};
    border-color: ${({ theme }) => theme.secondaryBackgroundColor};
  }
`;

const ApiDescription = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 20px;
  color: ${(props) => props.theme.invertedTextOnSecondary};
  margin-top: 5px;
  text-align: left;
  padding-bottom: 40px;
`;

const ApiTagsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 5px;
`;

const ApiActionContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: transparent;
  height: 10vh;
  column-gap: 10px;
`;

const StyledSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const ApiTitleText = styled(Text)`
  display: block;
  margin-bottom: 5px;
  margin-top: 5px;
  color: ${(props) => props.theme.invertedTextOnSecondary};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 20vw;
  text-align: left;
`;

const ApiSubtitleText = styled(Text)`
  display: block;
  margin-top: 5px;
  color: ${(props) => props.theme.invertedTextOnSecondary};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 20vw;
  text-align: left;
  font-size: ${getFontSize(1)};
  padding-bottom: 12px;
`;

const StyledCard = styled(Card)`
  padding: 40px;
  background-color: ${(props) => props.theme.secondaryBackgroundColor};
`;

const StyledDropdown = styled.div`
  width: 15vw;
  background-color: ${(props) => props.theme.primaryBackgroundColor};
  color: ${(props) => props.theme.textOnPrimary};
`;

const SearchField = styled.div`
  width: 30vw;
`;

export function Apis() {
  const [searchTerm, setSearchTerm] = useState(undefined);
  const [searchInput, setSearchInput] = useState(undefined);
  const [statusFilter, setStatusFilter] = useState(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ownedApis, setOwnedApis] = useState(true);
  const history = useHistory();
  const [user] = useUser();
  const initialRender = useRef(true);
  const userOwnership = useContext(UserOwnershipContext);

  const {
    response: apiResponse,
    isLoading,
    invokeApi: getApis,
  } = useGetApisPaginated();
  const [displayedApis, setDisplayedApis] = useState<API[]>([]);
  const [lastKey, setLastKey] = useState<any>(null);

  const {
    invokeApi: addApi,
    isLoading: addIsLoading,
    statusCode,
    response,
  } = useAddApi();

  useEffect(() => {
    if (userOwnership.teams && !isLoading) {
      if (userOwnership.teams.length > 0) {
        const teams = ownedApis
          ? encodeURIComponent(JSON.stringify(userOwnership.teams))
          : null;
        getApis({ teams });
      } else {
        setOwnedApis(false);
        getApis();
      }
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [userOwnership.teams]);

  useEffect(() => {
    if (statusCode === 500) {
      toast.error(
        "There was an error completing your request. Please try again."
      );
    }
    if (statusCode >= 200 && statusCode <= 300 && response) {
      toast.success("Your request was completed successfully.");
      history.push(`/api/${response.pk}`);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [statusCode]);

  useEffect(() => {
    if (apiResponse) {
      initialRender.current = false;
      setDisplayedApis((prevState) => {
        return [...prevState, ...apiResponse.apis];
      });
      apiResponse.lastKey
        ? setLastKey(encodeURIComponent(JSON.stringify(apiResponse.lastKey)))
        : setLastKey(null);
    }
  }, [apiResponse]);

  useEffect(() => {
    if (!initialRender.current) {
      if (ownedApis) {
        setSearchTerm(searchInput);
        getApis({
          status: statusFilter,
          q: searchInput,
          teams: encodeURIComponent(JSON.stringify(userOwnership.teams)),
        });
        setDisplayedApis([]);
        initialRender.current = true;
      } else {
        setSearchTerm(searchInput);
        getApis({
          status: statusFilter,
          q: searchInput,
        });
        setDisplayedApis([]);
        initialRender.current = true;
      }
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [ownedApis]);

  const loadMore = () => {
    if (!isLoading) {
      const teams = ownedApis
        ? encodeURIComponent(JSON.stringify(userOwnership.teams))
        : null;
      getApis({
        lastKey,
        q: searchTerm,
        teams,
      });
    }
  };

  const onSearchFieldChange = (e: any) => {
    setSearchInput(e.target.value);
  };

  const onSearchSubmit = () => {
    if (!isLoading) {
      setSearchTerm(searchInput);
      const teams = ownedApis
        ? encodeURIComponent(JSON.stringify(userOwnership.teams))
        : null;
      getApis({ status: statusFilter, q: searchInput, teams });
      setDisplayedApis([]);
      initialRender.current = true;
    }
  };

  const onResetSearch = () => {
    if (!isLoading) {
      setStatusFilter(undefined);
      setSearchInput(undefined);
      setSearchTerm(undefined);
      getApis();
      initialRender.current = true;
    }
  };

  const filterChanged = (e: any) => {
    setStatusFilter(e.target.value);
    const teams = ownedApis
      ? encodeURIComponent(JSON.stringify(userOwnership.teams))
      : null;
    getApis({ status: e.target.value, q: searchTerm, teams });
    setDisplayedApis([]);
    initialRender.current = true;
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const filterByOptions = [
    {
      name: "All",
      value: "All",
    },
    {
      name: "Completed",
      value: ENTITY_STATI.COMPLETE,
    },
    {
      name: "In Progress",
      value: ENTITY_STATI.DRAFT,
    },
  ];

  const onSubmitForm = async (e: any) => {
    const payload = {
      ...e,
      pk: e.name,
      sk: "INFO",
      type: "API",
    };
    await addApi(null, payload, true);
  };

  const redirectTo = (link: string) => {
    history.push(link);
  };

  return (
    <ApisWrapper>
      <ApiSearchContainer>
        <ApiActionContainer>
          <SearchField>
            <TextField
              affixRight="SearchMagnifyGlass"
              affixRightClickable
              onAffixRightClick={onSearchSubmit}
              fieldStatus="default"
              label="Search by API name, team name, or description"
              onChange={onSearchFieldChange}
              onKeyDown={(event: KeyboardEvent) =>
                event.key === "Enter" ? onSearchSubmit() : null
              }
              value={searchTerm}
            />
          </SearchField>
          <StyledDropdown>
            <Dropdown
              helperText=""
              label="Filter by Status"
              onChange={filterChanged}
              options={filterByOptions}
              value={statusFilter}
            />
          </StyledDropdown>
          {userOwnership.teams && userOwnership.teams.length > 0 && (
            <StyledSwitch
              label="Owned APIs"
              labelPosition="right"
              onClick={() =>
                setOwnedApis((prevState) => {
                  return !prevState;
                })
              }
              size="default"
              checked={ownedApis}
              disabled={initialRender.current}
            />
          )}
        </ApiActionContainer>
        {user?.isAuthorizedByScope("*:api:write") && (
          <Button color="secondary" onClick={toggleModal} variant="default">
            Publish API
          </Button>
        )}
      </ApiSearchContainer>
      {initialRender.current ? (
        <ApiSetContainer>
          <ProductListSkeleton />
        </ApiSetContainer>
      ) : (
        <ApiSetContainer>
          <InfiniteScroll
            loadMore={loadMore}
            hasMore={!!lastKey}
            loader={
              <StyledSpinner>
                <AsurionDoodleSpinner
                  title="Asurion dots loader demo SVG"
                  width="120px"
                />
              </StyledSpinner>
            }
          >
            {displayedApis.length === 0 && !lastKey && (
              <ErrorPage
                subtitle="If you applied any filters, try and see if you made any errors"
                title="No items found"
                actionLabel="Search all APIs"
                button={{
                  color: "secondary",
                  onClick: () => {
                    onResetSearch();
                  },
                }}
              />
            )}
            {displayedApis.length > 0 && (
              <DynamicGrid gapSize={1} minWidth={25} maxColumns={3}>
                {displayedApis?.map((api: API) => (
                  <StyledCard
                    key={api.pk}
                    type="call-to-action"
                    onClick={() =>
                      redirectTo(`/api/${encodeURIComponent(api.pk)}`)
                    }
                  >
                    <>
                      <ApiTagsContainer>
                        <StyledTag
                          size="regular"
                          text={api.isPublic ? "Public" : "Private"}
                          type={api.isPublic ? "attention" : "gray"}
                        />
                        <StyledTag
                          size="regular"
                          text={
                            api.status === ENTITY_STATI.DRAFT
                              ? "In progress"
                              : "Completed"
                          }
                          type={
                            api.status === ENTITY_STATI.DRAFT
                              ? "gray"
                              : "attention"
                          }
                        />
                      </ApiTagsContainer>
                      <ApiTitleText title={api.pk} weight="heavy" size={4}>
                        {api.pk}
                      </ApiTitleText>
                      <ApiDescription>{api.description}</ApiDescription>
                      <ApiSubtitleText>Owned by: {api.team}</ApiSubtitleText>
                      <ApiFooter>
                        <ApiMetaContainer>
                          <StyledButton
                            ariaLabel="AsurionLogo"
                            color="secondary"
                            iconSide="right"
                            iconSrc="AsurionLogo"
                            options={{
                              useFigmaStyles: false,
                            }}
                            size="small"
                            variant="flat"
                          />
                          <ApiMetaLink
                            to={`/api/${encodeURIComponent(api.pk)}`}
                          >
                            <Button
                              size="small"
                              variant="outline"
                              color="secondary"
                            >
                              Explore
                            </Button>
                          </ApiMetaLink>
                        </ApiMetaContainer>
                      </ApiFooter>
                    </>
                  </StyledCard>
                ))}
              </DynamicGrid>
            )}
          </InfiniteScroll>
        </ApiSetContainer>
      )}
      <Modal isOpen={isModalOpen} onBackgroundClick={toggleModal}>
        <ApiForm
          onCancel={toggleModal}
          onSubmit={onSubmitForm}
          isLoading={addIsLoading}
        />
      </Modal>
    </ApisWrapper>
  );
}

export default Apis;
