import { useMemo } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import "@rmwc/drawer/styles";
import type {} from "styled-components/cssprop"; // *  https://styled-components.com/docs/api#usage-with-typescript
import "react-toastify/dist/ReactToastify.css";
import { ModalProvider } from "styled-react-modal";
import { ErrorPage, updateTheme } from "@soluto-private/mx-asurion-ui-react";
import { ToastContainer } from "react-toastify";
import { SkeletonTheme } from "react-loading-skeleton";
import "suneditor/dist/css/suneditor.min.css";
import "@stoplight/elements/styles.min.css";
import "react-loading-skeleton/dist/skeleton.css";

import { Navbar, Footer, ProtectedRoute } from "./lib/components";
import {
  Home,
  Api,
  ApiEdit,
  Products,
  Account,
  LoginPage,
  Apis,
  Product,
  AboutUs,
  Help,
  Team,
  UserDetails,
  Admin,
  ChangePassword,
  ResetPassword,
  VerificationCodeEntry,
} from "./pages";

import {
  MediaProvider,
  useTheme,
  UserProvider,
  UserProviderCustom,
  ExternalUserGuard,
  UserGuard,
  AdminGuard,
  UnAuthenticatedGuard,
  InternalUserGuard,
  StoplightLayoutProvider,
} from "./lib/hooks";
import { COLORS } from "./constants";
import "./App.css";
import { GlobalStyle } from "./themes";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  position: relative;
  padding: 0;
  margin-top: 75px;
  flex-grow: 3;
  background-color: ${({ theme }) => theme.primaryBackgroundColor};
  color: ${({ theme }) => theme.textOnPrimary || COLORS.BLACK};
  transition: all 0.25s linear;
  min-height: calc(80vh);
  @media print {
    margin-top: 0;
  }
`;

const AppContainer = styled.div`
  margin: 0;
  padding: 0;
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledContainer = styled(ToastContainer)`
  // https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
  .Toastify__toast {
    background-color: ${({ theme }) => theme.secondaryBackgroundColor};
    color: ${({ theme }) => theme.textOnPrimary};
  }
  .Toastify__toast-body {
    font-family: Apercu;
    font-weight: bold;
  }
`;

function App() {
  const { theme, toggleTheme } = useTheme();

  useMemo(() => {
    updateTheme(theme);
  }, [theme]);

  return (
    <ThemeProvider theme={theme}>
      <MediaProvider>
        <ModalProvider>
          <UserProvider>
            <UserProviderCustom>
              <StoplightLayoutProvider>
                <>
                  <GlobalStyle />
                  <SkeletonTheme
                    baseColor={`${theme.secondary}40`}
                    highlightColor={`${theme.secondaryBackgroundColor}40`}
                  >
                    <Router>
                      <AppContainer>
                        <Navbar toggleTheme={() => toggleTheme()} />
                        <StyledContainer icon={false} style={{ top: "5em" }} />
                        <Wrapper>
                          <Switch>
                            <Route exact path="/">
                              <Redirect to="/home" />
                            </Route>
                            <Route exact path="/home">
                              {/* TODO Create an getting-started page */}
                              <Home />
                            </Route>
                            <Route exact path="/about-us">
                              {/* TODO Create an about-us page */}
                              <AboutUs />
                            </Route>
                            <Route exact path="/forgot-password">
                              <ResetPassword />
                            </Route>
                            <Route exact path="/verify-code">
                              <VerificationCodeEntry />
                            </Route>
                            <ProtectedRoute
                              exact
                              guards={[UserGuard]}
                              redirectTo="/login"
                              path="/help"
                            >
                              <Help />
                            </ProtectedRoute>
                            <ProtectedRoute
                              guards={[AdminGuard]}
                              redirectTo="/"
                              path="/admin"
                            >
                              <Admin />
                            </ProtectedRoute>
                            <ProtectedRoute
                              guards={[UserGuard]}
                              redirectTo="/login"
                              path="/account/:id"
                              exact
                            >
                              <Account />
                            </ProtectedRoute>
                            <ProtectedRoute
                              guards={[UserGuard]}
                              redirectTo="/login"
                              path="/api/:idParams"
                              exact
                            >
                              <Api />
                            </ProtectedRoute>
                            <ProtectedRoute
                              guards={[InternalUserGuard]}
                              redirectTo="/login"
                              path="/apis"
                              exact
                            >
                              <Apis />
                            </ProtectedRoute>
                            <ProtectedRoute
                              guards={[InternalUserGuard]}
                              redirectTo="/login"
                              path="/api/:id/settings"
                              exact
                            >
                              <ApiEdit />
                            </ProtectedRoute>
                            <ProtectedRoute
                              path="/login"
                              exact
                              guards={[UnAuthenticatedGuard]}
                              redirectTo="/home"
                            >
                              <LoginPage />
                            </ProtectedRoute>
                            <ProtectedRoute
                              guards={[UserGuard]}
                              redirectTo="/login"
                              path="/products"
                              exact
                            >
                              <Products />
                            </ProtectedRoute>
                            <ProtectedRoute
                              guards={[UserGuard]}
                              redirectTo="/login"
                              path="/products/:productName"
                              exact
                              component={Product}
                            />
                            <ProtectedRoute
                              guards={[UserGuard]}
                              redirectTo="/login"
                              path="/products/:productName/:productTabSlug"
                              exact
                            >
                              <Product />
                            </ProtectedRoute>
                            <ProtectedRoute
                              guards={[UserGuard]}
                              redirectTo="/login"
                              path="/products/:productName/docs/:productDoc"
                              exact
                            >
                              <Product />
                            </ProtectedRoute>
                            <ProtectedRoute
                              path="/products/:productName/docs/new"
                              guards={[UserGuard]}
                            >
                              <Product />
                            </ProtectedRoute>
                            <ProtectedRoute
                              guards={[UserGuard]}
                              redirectTo="/login"
                              path="/help/docs/:helpDoc"
                              exact
                            >
                              <Help />
                            </ProtectedRoute>
                            <ProtectedRoute
                              path="/help/docs/new"
                              guards={[UserGuard]}
                            >
                              <Help />
                            </ProtectedRoute>
                            <ProtectedRoute
                              path="/user/profile"
                              guards={[UserGuard]}
                            >
                              <UserDetails />
                            </ProtectedRoute>
                            <ProtectedRoute
                              path="/teams/:teamName"
                              guards={[UserGuard]}
                            >
                              <Team />
                            </ProtectedRoute>
                            <ProtectedRoute
                              path="/change-password"
                              guards={[ExternalUserGuard, UnAuthenticatedGuard]}
                              redirectTo="/home"
                              exact
                            >
                              <ChangePassword />
                            </ProtectedRoute>
                            <Route>
                              <ErrorPage
                                code="404"
                                actionLabel="Go to homepage"
                                button={{ color: "secondary" }}
                              />
                            </Route>
                          </Switch>
                        </Wrapper>
                        <Footer />
                      </AppContainer>
                    </Router>
                  </SkeletonTheme>
                </>
              </StoplightLayoutProvider>
            </UserProviderCustom>
          </UserProvider>
        </ModalProvider>
      </MediaProvider>
    </ThemeProvider>
  );
}
export default App;
