import styled from "styled-components";
import { getFontSize, Button } from "@soluto-private/mx-asurion-ui-react";
import { Element } from "react-scroll";

export const HelpActionButton = styled(Button)`
  text-decoration: none;

  & > *:last-child {
    margin-left: 4px;
  }
`;
export const HelpDocMenuWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
  @media print {
    display: none;
  }
`;
export const HelpWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
`;

export const HelpDocContainer = styled.div`
  flex-grow: 1;
  width: 100%;
`;

export const HelpDocWrapper = styled.div`
  margin: 0 auto;
  padding-left: 15px;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
  margin-bottom: 64px;
  align-items: stretch;
  max-width: 1250px;
  min-width: 100%;
`;

export const NewDocHeader = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  & > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const ContentWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 2rem 1.5rem;
`;

export const SidebarContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: ${getFontSize(2)};
  & {
    span {
      width: ${getFontSize(2)};
    }
  }
`;

export const StyledButton = styled(Button)`
  color: ${(props) => props.theme.textOnPrimary};
  margin: ${getFontSize(1)} 0 0 -10px;
  padding-left: 5px;
  text-decoration: none;
  &&& {
    &:hover {
      color: ${(props) => props.theme.invertedTextOnPrimary};
      background-color: transparent;
    }
  }
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.01em;
`;

export const StyledSidebarHeading = styled.h1`
  color: ${(props) => props.theme.textOnPrimary};
  margin: ${getFontSize(1)} 0 0 -10px;
`;

export const HelpDocContentSection = styled(Element)`
  padding: 25px 5vw;
  border-bottom: 1px solid #e3e8ee;
  &&& {
    min-height: calc(100vh - 75px);
  }
`;
