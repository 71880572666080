import styled from "styled-components";
import { Button, getFontSize, Text } from "@soluto-private/mx-asurion-ui-react";
import { useRef } from "react";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  min-width: 400px;
  width: 40vw;
  height: 100%;
`;

const SelectButton = styled(Button)`
  align-self: center;
`;

const LeftContainer = styled.div`
  flex: 0 1 50%;
  min-height: 100%;
  max-width: 50%;
  padding: 0.5em;
`;
const CenterContainer = styled.div`
  display: flex;
  height: 100%;
  flex-wrap: wrap;
  align-items: center;
`;
const RightContainer = styled.div`
  flex: 0 1 50%;
  min-height: 100%;
  max-width: 50%;
  padding: 0.5em;
`;
const HalfContainers = styled.div`
  flex: 0 0 100%;
  max-height: 50%;
  min-height: 50%;
  padding: 0.5em;
  overflow: hidden;
`;

const Select = styled.select`
  width: 100%;
  height: 100%;
  padding: 0.5em;
  font-family: Apercu;
`;

const Title = styled(Text)`
  font-size: ${getFontSize(3)};
`;

const SubmitButton = styled(Button)`
  align-self: center;
`;

const StyledDiv = styled.div`
  width: 40vw;
  display: flex;
  justify-content: center;
  margin-bottom: 4px;
`;

interface MultiSelectProps {
  selectedDisplayList: string[];
  availableDisplayList: string[];
  title: string;
  onSave: () => void;
}

export function MultiSelect({
  selectedDisplayList,
  availableDisplayList,
  title,
  onSave,
}: MultiSelectProps) {
  function listNamesOptionsData(recepient: string, index: number) {
    return (
      <option value={recepient} key={index}>
        {recepient}
      </option>
    );
  }
  const selectedElement = useRef(null);
  const finalSelectedElement = useRef(null);
  const addValue = () => {
    const selected: any = selectedElement.current;
    const finalSelected: any = finalSelectedElement.current;
    for (let i = 0; i < selected.length; i += 1) {
      if (selected.options[i].selected === true) {
        const selItem = selected.selectedIndex;
        const newOption = selected[selItem].cloneNode(true);
        selected.removeChild(selected[selItem]);
        finalSelected.appendChild(newOption);
      }
    }
  };

  const removeValue = () => {
    const selected: any = selectedElement.current;
    const finalSelected: any = finalSelectedElement.current;
    for (let i = 0; i < finalSelected.length; i += 1) {
      if (finalSelected.options[i].selected === true) {
        const selItem = finalSelected.selectedIndex;
        const newOption = finalSelected[selItem].cloneNode(true);
        finalSelected.removeChild(finalSelected[selItem]);
        selected.appendChild(newOption);
      }
    }
  };

  return (
    <>
      <Title>{title}</Title>
      <StyledContainer>
        <LeftContainer>
          <Select multiple name="SelectList" ref={selectedElement}>
            {availableDisplayList.map((result, index) => {
              return listNamesOptionsData(result, index);
            })}
          </Select>
        </LeftContainer>
        <CenterContainer>
          <HalfContainers>
            <SelectButton onClick={addValue}>&#8250;</SelectButton>
          </HalfContainers>
          <HalfContainers>
            <SelectButton onClick={removeValue}>&#8249;</SelectButton>
          </HalfContainers>
        </CenterContainer>
        <RightContainer>
          <Select multiple name="SelectList" ref={finalSelectedElement}>
            {selectedDisplayList.map((result, index) => {
              return listNamesOptionsData(result, index);
            })}
          </Select>
        </RightContainer>
      </StyledContainer>
      <StyledDiv>
        <SubmitButton
          onClick={() => {
            onSave();
          }}
        >
          <Text>Save</Text>
        </SubmitButton>
      </StyledDiv>
    </>
  );
}

export default MultiSelect;
