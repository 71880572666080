import styled from "styled-components";
import {
  getFontSize,
  Button,
  PredictiveTextField,
  AsurionDoodleSpinner,
  Text,
} from "@soluto-private/mx-asurion-ui-react";
import { useEffect, useRef, useState } from "react";
import { API } from "@apim/lib-portal-entities";
import { useGetApis } from "../../api/apis.api";
import { useProductLinkMultipleApi } from "../../api/products.api";

const StyledForm = styled.form`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.secondaryBackgroundColor};
  padding: 0;
  margin: 0;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.secondaryBackgroundColor};
  border-radius: 10px;
  padding: 20px;
  min-width: 400px;
  width: 40vw;
  height: auto;
`;

const StyledButton = styled(Button)`
  margin: 5px 5px;
`;

const ButtonsDiv = styled.div`
  flex-wrap: wrap;
  display: flex;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 0;
  height: 80px;
`;

const Body = styled.div`
  display: flex;
  flex-grow: 0;
  height: auto;
  padding: 1em;
`;

const Title = styled.h1`
  font-size: ${getFontSize(3)};
  font-family: Apercu;
  color: ${(props) => props.theme.invertedTextOnSecondary};
`;

const Row = styled.div<{ flexAlign?: "right" | "left" }>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: ${({ flexAlign }) =>
    flexAlign === "right" ? "flex-end" : "flex-start"};
  margin: 20px 0px;
  padding: 0px;
`;

const FieldContainer = styled.div`
  && {
    min-width: 45%;
    width: 100%;
    padding: 0 10px;
  }
`;

const SubmitButton = styled(Button)`
  align-self: flex-end;
`;

interface LinkApiFormProps {
  isPublic: boolean;
  apis: API[];
  product: string;
  onCancel?: any;
  handleSuccess?: (params?: any) => void;
}

interface ApiSuggestion {
  header: string;
  id: string;
  subHeader: string;
  value: string;
}

export function LinkApiForm(props: LinkApiFormProps) {
  const { isPublic, product, onCancel, handleSuccess, apis } = props;
  const initialRender = useRef(true);
  const [selectedApis, setSelectedApis] = useState<string[]>([]);
  const [apiSearchTerm, setApiSearchTerm] = useState("");
  const { response: fetchedApis, getApis } = useGetApis();
  const {
    error,
    isLoading: isSavingApiLink,
    invokeApi: linkApiToProduct,
  } = useProductLinkMultipleApi(product);
  const [apiSuggestions, setApiSuggestions] = useState<ApiSuggestion[]>();
  const availableApis = apis.map((api) => {
    return api.sk.split("#")[1];
  });
  const transformData = (apis: API[] | undefined) => {
    const transformedData: {
      header: string;
      id: string;
      subHeader: string;
      value: string;
    }[] = [];
    if (apis) {
      apis.forEach((api) => {
        const apiSuggestion = {
          header: api.pk,
          id: api.pk,
          subHeader: api.description,
          value: api.URI,
        };
        if (isPublic) {
          if (!availableApis.includes(api.pk) && api.isPublic) {
            transformedData.push(apiSuggestion);
          }
        } else if (!availableApis.includes(api.pk)) {
          transformedData.push(apiSuggestion);
        }
      });
    }
    return transformedData;
  };

  useEffect(() => {
    getApis();
  }, [getApis]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    const transformedData = transformData(fetchedApis!);
    setApiSuggestions(transformedData);
    // eslint-disable-next-line
  }, [fetchedApis]);

  useEffect(() => {
    const filteredApis = fetchedApis?.filter((api) =>
      api.pk.includes(apiSearchTerm)
    );
    setApiSuggestions(transformData(filteredApis));
    // eslint-disable-next-line
  }, [apiSearchTerm, fetchedApis]);

  const handleInputChange = (value: string) => {
    setApiSearchTerm(value);
  };

  const handleSelectedApi = (selectedApi: ApiSuggestion) => {
    setApiSearchTerm(selectedApi.header);
    // Limiting number of APIs add limit to 3 at single point of time
    if (!selectedApis.includes(selectedApi.id)) {
      setSelectedApis([...selectedApis, selectedApi.id]);
    }
  };

  const removeApi = (api: string) => {
    const apisSelected = selectedApis.filter((record) => record !== api);
    setSelectedApis([...apisSelected]);
  };

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    await linkApiToProduct(null, selectedApis);
    if (!error) {
      handleSuccess && handleSuccess(product);
    }
    setApiSearchTerm("");
    setSelectedApis([]);
  };

  return (
    <StyledContainer>
      <Header>
        <Title>Link APIs to {product}</Title>
        <Button variant="flat" onClick={onCancel}>
          Cancel
        </Button>
      </Header>
      <Body>
        <StyledForm onSubmit={handleFormSubmit}>
          <Row>
            <FieldContainer>
              <PredictiveTextField
                handleChange={handleInputChange}
                iconSrc=""
                label="API Name"
                onSelection={handleSelectedApi}
                suggestions={apiSuggestions}
                value={apiSearchTerm}
              />
            </FieldContainer>
          </Row>
          <Row>
            <ButtonsDiv>
              {selectedApis &&
                selectedApis.map((api: string) => (
                  <StyledButton
                    iconSrc="CloseX"
                    size="small"
                    type="button"
                    variant="outline"
                    iconSide="left"
                    onClick={() => removeApi(api)}
                    key={api}
                  >
                    {api}
                  </StyledButton>
                ))}
            </ButtonsDiv>
          </Row>
          <Row flexAlign="right">
            <SubmitButton
              color="secondary"
              type="submit"
              disabled={isSavingApiLink}
            >
              <>
                <Text>Link APIs</Text>
                {isSavingApiLink && <AsurionDoodleSpinner width="20px" />}
              </>
            </SubmitButton>
          </Row>
        </StyledForm>
      </Body>
    </StyledContainer>
  );
}

export default LinkApiForm;
