import styled from "styled-components";

import { INavigationTabsContainerProps } from "./types";

const NavigationTabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  margin: 0 8px;
  color: ${({ theme }) => theme.textOnPrimary};
  background-color: ${({ theme }) => theme.primaryBackgroundColor};
`;

export function NavigationTabs(
  props: INavigationTabsContainerProps
): JSX.Element {
  const { className, children } = props;
  return (
    <NavigationTabsContainer className={className}>
      {children}
    </NavigationTabsContainer>
  );
}

export default NavigationTabs;
