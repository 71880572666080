import styled from "styled-components";
import { Button, getFontSize } from "@soluto-private/mx-asurion-ui-react";
import { useHistory } from "react-router-dom";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: ${getFontSize(6)};
  align-items: center;
`;

const MessageBlock = styled.div`
  min-width: 400px;
  min-height: 400px;
  width: 60%;
  padding: 0;
  height: 80%;
  display: flex;
`;

const Message = styled.div`
  flex-grow: 1;
  padding: ${getFontSize(4)};
`;

const Title = styled.h1`
  font-size: 2em;
`;

const ImageComponent = styled.img`
  min-height: 100%;
  margin: 0;
  flex-grow: 1;
  width: 400px;
  height: 400px;
  filter: ${({ theme }) =>
    theme.textOnPrimary === "#ffffff" ? "invert(1)" : "invert(0)"};
`;

interface IErrorPageProps {
  message: string;
  onClick?: () => void;
  actionMessage: string;
}

export function ErrorPage({
  message,
  onClick,
  actionMessage,
}: IErrorPageProps) {
  const history = useHistory();

  const goToHomePage = () => {
    history.push("/");
  };

  return (
    <Wrapper>
      <MessageBlock>
        <ImageComponent src="/img/error-page.png" />
        <Message>
          <Title>{message}</Title>
          <Button onClick={onClick ? () => onClick() : () => goToHomePage()}>
            {actionMessage}
          </Button>
        </Message>
      </MessageBlock>
    </Wrapper>
  );
}

export default ErrorPage;
