import { useState } from "react";

import { TabButton } from "../TabButton";
import { Menu } from "../Menu";
import { IDropdownTabMenuProps } from "./types";
import {
  DropdownHeader,
  DropdownMenuContainer,
  DropdownTabMenuWrapper,
} from "./elements";

export function DropdownTabMenu(props: IDropdownTabMenuProps) {
  const { attachIcon, title, menuItems } = props;
  const [showMenu, setShowMenu] = useState(false);
  const iconPosition = attachIcon || "suffix";

  const onDropdownClickedEvent = () => {
    setShowMenu(!showMenu);
  };

  return (
    <DropdownTabMenuWrapper onMouseLeave={() => setShowMenu(false)}>
      <DropdownHeader>
        <TabButton
          {...(iconPosition === "suffix" ? { appendIcon: "ChevronDown" } : {})}
          {...(iconPosition === "prefix" ? { prependIcon: "ChevronDown" } : {})}
          onClick={() => onDropdownClickedEvent()}
          active={showMenu}
        >
          {title}
        </TabButton>
      </DropdownHeader>
      {showMenu && (
        <DropdownMenuContainer>
          <Menu
            menuItems={menuItems}
            collapseParent={() => setShowMenu(false)}
          />
        </DropdownMenuContainer>
      )}
    </DropdownTabMenuWrapper>
  );
}

export default DropdownTabMenu;
