import Skeleton from "react-loading-skeleton";
import styled from "styled-components";

const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

const SkeletonDataWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface UsersSkeletonProps {
  headerWidth: string;
  dataWidth: string;
}

export function UsersSkeleton({ headerWidth, dataWidth }: UsersSkeletonProps) {
  return (
    <SkeletonContainer>
      <Skeleton duration={1500} count={1} height="2rem" width={headerWidth} />
      <SkeletonDataWrapper>
        <div>
          <Skeleton
            duration={1500}
            count={10}
            height="2rem"
            width={dataWidth}
          />
        </div>
        <div>
          <Skeleton
            duration={1500}
            count={10}
            height="2rem"
            width={dataWidth}
          />
        </div>
        <div>
          <Skeleton
            duration={1500}
            count={10}
            height="2rem"
            width={dataWidth}
          />
        </div>
        <div>
          <Skeleton
            duration={1500}
            count={10}
            height="2rem"
            width={dataWidth}
          />
        </div>
      </SkeletonDataWrapper>
    </SkeletonContainer>
  );
}

export default UsersSkeleton;
