import { useApiCall } from "../hooks";
import { GenericDTO, HttpMethod } from "../../constants";

export function useGetHelpDoc(docName: string) {
  return useApiCall<any | null>(`/catalog/help-doc/${docName}`, HttpMethod.GET);
}

export function useGetHelpDocCDN() {
  return useApiCall<any | null>(``, HttpMethod.GET);
}

export function useGetAllDocs() {
  return useApiCall<any | null>(
    `/catalog/help-doc/get-doc/all`,
    HttpMethod.GET
  );
}

export function useGetAllPartnerDocs() {
  return useApiCall<any | null>(
    `/catalog/help-doc/get-doc/partner`,
    HttpMethod.GET
  );
}

// export function useUpdateHelpDoc(helpDocName: string) {
//   return useApiCall<any>(
//     `/catalog/help-doc/doc/${helpDocName}`,
//     HttpMethod.PUT,
//     {
//       headers: {
//         "Content-Type": "text/plain",
//       },
//     }
//   );
// }

export function useUpdateHelpDoc(fileName: string) {
  const { invokeApi, ...rest } = useApiCall<GenericDTO>(
    `/catalog/help-doc/create-doc/update/${fileName}`,
    HttpMethod.PUT,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return { ...rest, invokeApi };
}

export function useDeleteHelp(docName: string) {
  const { invokeApi, ...rest } = useApiCall<GenericDTO>(
    `/catalog/help-doc/${docName}`,
    HttpMethod.DELETE
  );

  return { ...rest, invokeApi };
}

export function useCreateHelpDoc(role: string) {
  const { invokeApi, ...rest } = useApiCall<GenericDTO>(
    `/catalog/help-doc/create-doc/${role}`,
    HttpMethod.POST,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return { ...rest, invokeApi };
}
