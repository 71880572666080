import styled from "styled-components";
import { useFormik, FormikValues } from "formik";
import {
  TextField,
  Button,
  getFontSize,
} from "@soluto-private/mx-asurion-ui-react";
import * as Yup from "yup";
import { Auth } from "aws-amplify";
import { useLocation, useHistory } from "react-router-dom";

const SetupSchema = Yup.object().shape({
  userName: Yup.string().required("Required"),
});

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const FormContainer = styled.div`
  width: 500px;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  margin: ${getFontSize(3)};
`;

const Row = styled.div`
  margin: 10px 5px;
`;

const StyledForm = styled.form`
  margin: 0;
  display: flex;
  flex-direction: column;
  padding: 0;
`;

// * `useQuery` would still be our go-to query-param hook,
// * I just created this so I can unescape `+` symbol
const getQueryStringParams = (query: string) =>
  query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split("&")
        .reduce((params: any, param: string) => {
          const [key, value] = param.split("=");
          // eslint-disable-next-line no-param-reassign
          params[key] = value ?? null;
          return params;
        }, {})
    : {};

export function ResetPassword() {
  const { search } = useLocation();
  const query = getQueryStringParams(search);
  const userName = query?.username ?? null;
  const history = useHistory();

  const handleSubmit = async (values: FormikValues) => {
    const { userName } = values;
    try {
      const user = await Auth.forgotPassword(userName);
      if (user) {
        history.push(`/verify-code?username=${userName}`);
      }
      return null;
    } catch (e) {
      throw new Error(`${e}`);
    }
  };
  const formik = useFormik({
    initialValues: {
      userName,
    },
    onSubmit: handleSubmit,
    validationSchema: SetupSchema,
    validateOnBlur: true,
  });

  return (
    <Wrapper>
      <FormContainer>
        <StyledForm onSubmit={formik.handleSubmit as any}>
          <Row>
            <TextField
              label="Email address"
              defaultValue={formik.values.userName}
              name="userName"
              type="text"
              fieldStatus={formik.errors.userName ? "error" : "default"}
              helperText={
                formik.errors.userName ? (formik.errors.userName as string) : ""
              }
              onChange={(value) => {
                formik.handleChange(value);
              }}
            />
          </Row>
          <Button type="submit">Submit</Button>
        </StyledForm>
      </FormContainer>
    </Wrapper>
  );
}

export default ResetPassword;
