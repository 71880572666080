import styled from "styled-components";
import { INavbarUserProps } from "../types";

export const NavbarUser = styled.div<INavbarUserProps>`
  align-items: center;
  justify-content: flex-end;
  display: ${(props) => (props.isSmall ? "none" : "flex")};
  color: ${(props) => props.theme.textOnPrimary};
  z-index: 1;
`;

export default NavbarUser;
