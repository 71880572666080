import { colors } from "@soluto-private/mx-asurion-ui-react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { ASURION_LOGO_PATH } from "../../../../constants";

export interface INavbarBrandProps {
  brandImgUrl?: string;
}

const NavbarBrandWrapper = styled(Link)`
  height: 100%;
  display: flex;
  min-width: 15vw;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  color: ${colors.black};
  width: 12vw;
`;

const NavbarBrandImage = styled.img`
  width: 55px;
  height: 55px;
`;

const NavbarLogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const NavbarLogoContainer = styled.img<any>`
  filter: ${({ theme }) => {
    const { textOnPrimary } = theme;
    return textOnPrimary === "#C1C9D2"
      ? `invert(91%) sepia(21%) saturate(129%) hue-rotate(178deg) brightness(86%) contrast(89%);`
      : `invert(21%) sepia(22%) saturate(480%) hue-rotate(163deg) brightness(95%) contrast(90%);`;
  }};
`;

export function NavbarBrand(props: INavbarBrandProps): JSX.Element {
  const { brandImgUrl } = props;

  return (
    <NavbarBrandWrapper to="/">
      {brandImgUrl && <NavbarBrandImage src={brandImgUrl} />}
      <NavbarLogoWrapper>
        <NavbarLogoContainer
          src={ASURION_LOGO_PATH}
          alt="Asurion"
          width="150px"
        />
      </NavbarLogoWrapper>
    </NavbarBrandWrapper>
  );
}

export default NavbarBrand;
