import { useEffect, useState } from "react";
import styled from "styled-components";
import { useFormik } from "formik";
import {
  getFontSize,
  TextField,
  Button,
  RadioButton,
  PredictiveTextField,
  AsurionDoodleSpinner,
  Text,
  Checkbox,
} from "@soluto-private/mx-asurion-ui-react";
import * as Yup from "yup";
import { USER_TYPES } from "@apim/lib-portal-entities/lib/models/Foundation";
import { toast } from "react-toastify";

import { useDebounce } from "../../hooks";
import {
  useAddProductArchitect,
  useGetUsers,
  useInviteProductUser,
} from "../../api";
import { COLORS, USER_NAME_VALIDATION } from "../../../constants";

const UserFormSchema = Yup.object().shape({
  company: Yup.string().required("Company name required."),
  firstName: Yup.string()
    .matches(
      USER_NAME_VALIDATION,
      "Only alphanumeric characters are allowed for the user name."
    )
    .required("First name required."),
  lastName: Yup.string()
    .matches(
      USER_NAME_VALIDATION,
      "Only alphanumeric characters are allowed for the user name."
    )
    .required("Last name required."),
  email: Yup.string().required("Email required.").email(),
});

// TODO specify the type definition
interface UserFormType {
  [key: string]: any;
}

const StyledForm = styled.form`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.secondaryBackgroundColor};
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.secondaryBackgroundColor};
  border-radius: 10px;
  padding: 20px;
  min-width: 400px;
  width: 40vw;
  min-height: 10px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 0;
  height: 80px;
`;

const Body = styled.div`
  display: flex;
  flex-grow: 0;
  height: auto;
`;

const Title = styled.h1`
  font-size: ${getFontSize(3)};
  font-family: Apercu;
  color: ${(props) => props.theme.invertedTextOnSecondary};
`;

const MessageContent = styled.h1`
  font-size: ${getFontSize(3)};
  font-family: Apercu;
  margin-top: revert;
  border: ${(props) => props.theme.invertedTextOnSecondary};
  color: ${(props) => props.theme.invertedTextOnSecondary};
  border-style: solid;
  padding-left: 10px;
`;
const Row = styled.div<{ flexAlign?: "right" | "left" }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: ${({ flexAlign }) =>
    flexAlign === "right" ? "flex-end" : "flex-start"};
  margin: 20px 0;
`;

const FieldContainer = styled.div`
  && {
    min-width: 45%;
    width: 100%;
    padding: 0 10px;
  }
  color: ${(props) => props.theme.textOnPrimary};
`;

const SubmitButton = styled(Button)`
  align-self: flex-end;
`;

const RadioButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${(props) => props.theme.textOnPrimary};
`;

const SearchDivLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

interface EditUserFormProps {
  status: string;
  user?: UserFormType;
  onSubmitCallback?: any;
  onCancel?: any;
  productName?: string;
}

interface UserOptionDTO {
  header: string;
  id: string;
  subHeader: string;
  value: string;
}

export function EditUserForm(props: EditUserFormProps) {
  const { status, user, onSubmitCallback, onCancel, productName } = props;
  const [isExternal, setIsExternal] = useState(true);

  const [existingSearch, setIsExistingSearch] = useState(true);
  const {
    response: users,
    invokeApi: getUsers,
    isLoading: getUsersIsLoading,
  } = useGetUsers();
  const [userOptions, setUserOptions] = useState<UserOptionDTO[]>();
  const {
    invokeApi: inviteExternalUser,
    isLoading: isInviteExternalUserLoading,
    response: inviteExternalUserResponse,
  } = useInviteProductUser(productName ?? "");
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const [selectedUser, setSelectedUser] = useState<string>("");
  const {
    invokeApi: addProductArchitect,
    isLoading: isAddProductArchitectLoading,
    response: addProductArchitectResponse,
  } = useAddProductArchitect(selectedUser, productName ?? "");

  useEffect(() => {
    if (debouncedSearchTerm) {
      getUsers({
        type: isExternal ? USER_TYPES.EXTERNAL : USER_TYPES.INTERNAL,
        name: debouncedSearchTerm,
      });
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [debouncedSearchTerm]);

  useEffect(() => {
    const transformedData: {
      header: string;
      id: string;
      subHeader: string;
      value: string;
    }[] = [];
    if (users) {
      users.forEach((user) => {
        const userSuggestion = {
          header: `${user.firstname} ${user.lastname}`,
          id: user.pk,
          subHeader: `${user.pk} - ${user.company}`,
          value: user.pk,
        };
        const userString = JSON.stringify(userSuggestion);
        if (userString.includes(searchTerm))
          transformedData.push(userSuggestion);
      });
      setUserOptions(transformedData);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [users]);

  useEffect(() => {
    if (inviteExternalUserResponse || addProductArchitectResponse) {
      onSubmitCallback();
      const response: any =
        inviteExternalUserResponse || addProductArchitectResponse;
      if (response && response.status === "success") {
        toast.success(response.message);
      } else if (response && response.status === "warn") {
        toast.warn(response.message);
      } else if (response && response.status === "error") {
        toast.error(response.message);
      }
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [inviteExternalUserResponse, addProductArchitectResponse]);

  const onExternalFormSubmit = (values: UserFormType, user?: UserFormType) => {
    if (!user) {
      const payload = { ...values };
      inviteExternalUser(null, payload);
    }
    // TODO add "EDIT USER HANDLER"
  };

  const onInternalFormSubmit = () => {
    addProductArchitect(null, {});
  };

  const externalUserForm = useFormik({
    initialValues: {
      email: user?.email || "",
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      company: user?.company || "",
    },
    onSubmit: (values) => {
      if (values.email !== "") {
        values.email = values.email.toLowerCase();
      }
      onExternalFormSubmit(values, user);
    },
    validationSchema: UserFormSchema,
    validateOnBlur: true,
  });

  const internalUserForm = useFormik({
    initialValues: {
      pk: "",
    },
    onSubmit: onInternalFormSubmit,
  });

  const handleSelectedUser = (selectedUser: UserOptionDTO) => {
    if (users) {
      const index = users.findIndex((user) => {
        return user.pk === selectedUser.id;
      });
      externalUserForm.setFieldValue("email", users[index].pk, false);
      externalUserForm.setFieldValue(
        "firstName",
        users[index].firstname,
        false
      );
      externalUserForm.setFieldValue("lastName", users[index].lastname, false);
      externalUserForm.setFieldValue("company", users[index].company, false);
    }
  };

  return (
    <StyledContainer>
      <Header>
        <Title>{user ? "Edit User" : "Add User"}</Title>
        <Button variant="flat" onClick={onCancel}>
          Cancel
        </Button>
      </Header>
      <Body>
        <StyledForm
          onSubmit={
            isExternal
              ? externalUserForm.handleSubmit
              : internalUserForm.handleSubmit
          }
        >
          {!user && (
            <RadioButtonsWrapper>
              <RadioButton
                label="Invite External User"
                name="userType"
                onChange={() => {
                  setIsExternal(true);
                  setUserOptions([]);
                  setSearchTerm("");
                }}
                checked={isExternal}
              />
              <RadioButton
                label="Add Product Architect"
                color="secondary"
                name="userType"
                onChange={() => {
                  setIsExternal(false);
                  setUserOptions([]);
                  setSearchTerm("");
                }}
                checked={!isExternal}
              />
            </RadioButtonsWrapper>
          )}
          {isExternal &&
            (status !== "COMPLETE" ? (
              <MessageContent>
                You can only invite external users for product with completed
                status
              </MessageContent>
            ) : (
              <>
                <Row>
                  <FieldContainer>
                    {getUsersIsLoading && (
                      <SearchDivLoading>
                        <Text color={COLORS.NEUTRAL_DEEPER}>Searching</Text>
                        <AsurionDoodleSpinner width="20px" />
                      </SearchDivLoading>
                    )}
                    <PredictiveTextField
                      handleChange={(value) => setSearchTerm(value)}
                      label="Search user"
                      onSelection={handleSelectedUser}
                      suggestions={userOptions}
                      value={searchTerm}
                    />
                  </FieldContainer>
                </Row>
                <Row>
                  <FieldContainer hidden={!existingSearch}>
                    <MessageContent>
                      Please inform partner user to login to portal to see the
                      new product
                    </MessageContent>
                  </FieldContainer>
                </Row>
                <Row>
                  <FieldContainer>
                    <Checkbox
                      checked={existingSearch}
                      label="Search existing user"
                      onChange={() => {
                        setIsExistingSearch((prevState) => !prevState);
                        externalUserForm.resetForm();
                      }}
                      name="isExisting"
                    />
                  </FieldContainer>
                </Row>
                <Row>
                  <FieldContainer hidden={existingSearch}>
                    <MessageContent>
                      Email Invite will be sent to the partner user.
                    </MessageContent>
                  </FieldContainer>
                </Row>
                <Row>
                  <FieldContainer>
                    <TextField
                      disabled={existingSearch}
                      label="Email"
                      defaultValue={externalUserForm.values.email}
                      value={externalUserForm.values.email}
                      name="email"
                      fieldStatus={
                        externalUserForm.errors.email ? "error" : "default"
                      }
                      onChange={externalUserForm.handleChange}
                      helperText={
                        externalUserForm.errors.email
                          ? (externalUserForm.errors.email as string)
                          : ""
                      }
                    />
                  </FieldContainer>
                </Row>
                <Row>
                  <FieldContainer>
                    <TextField
                      disabled={existingSearch}
                      label="First Name"
                      name="firstName"
                      defaultValue={externalUserForm.values.firstName}
                      value={externalUserForm.values.firstName}
                      fieldStatus={
                        externalUserForm.errors.firstName ? "error" : "default"
                      }
                      onChange={externalUserForm.handleChange}
                      helperText={
                        externalUserForm.errors.firstName
                          ? (externalUserForm.errors.firstName as string)
                          : ""
                      }
                    />
                  </FieldContainer>
                </Row>
                <Row>
                  <FieldContainer>
                    <TextField
                      disabled={existingSearch}
                      label="Last Name"
                      defaultValue={externalUserForm.values.lastName}
                      value={externalUserForm.values.lastName}
                      name="lastName"
                      fieldStatus={
                        externalUserForm.errors.lastName ? "error" : "default"
                      }
                      onChange={externalUserForm.handleChange}
                      helperText={
                        externalUserForm.errors.lastName
                          ? (externalUserForm.errors.lastName as string)
                          : ""
                      }
                    />
                  </FieldContainer>
                </Row>
                <Row>
                  <FieldContainer>
                    <TextField
                      disabled={existingSearch}
                      label="Company"
                      name="company"
                      defaultValue={externalUserForm.values.company}
                      value={externalUserForm.values.company}
                      fieldStatus={
                        externalUserForm.errors.company ? "error" : "default"
                      }
                      onChange={externalUserForm.handleChange}
                      helperText={
                        externalUserForm.errors.company
                          ? (externalUserForm.errors.company as string)
                          : ""
                      }
                    />
                  </FieldContainer>
                </Row>
              </>
            ))}
          {!isExternal && (
            <Row>
              <FieldContainer>
                {getUsersIsLoading && (
                  <SearchDivLoading>
                    <Text color={COLORS.NEUTRAL_DEEPER}>Searching</Text>
                    <AsurionDoodleSpinner width="20px" />
                  </SearchDivLoading>
                )}
                <PredictiveTextField
                  label="User"
                  suggestions={userOptions}
                  includeSubheader
                  handleChange={(value) => {
                    setSearchTerm(value);
                  }}
                  onSelection={(value) => {
                    internalUserForm.values.pk = value.header;
                    setSelectedUser(value.id);
                    setSearchTerm(value.id);
                  }}
                  value={searchTerm}
                />
              </FieldContainer>
            </Row>
          )}
          <Row flexAlign="right">
            <SubmitButton
              type="submit"
              disabled={
                isAddProductArchitectLoading || isInviteExternalUserLoading
              }
            >
              <>
                <Text>Save</Text>
                {(isAddProductArchitectLoading ||
                  isInviteExternalUserLoading) && (
                  <AsurionDoodleSpinner width="20px" />
                )}
              </>
            </SubmitButton>
          </Row>
        </StyledForm>
      </Body>
    </StyledContainer>
  );
}

export default EditUserForm;
