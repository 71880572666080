import styled from "styled-components";
import { getFontSize, Tag } from "@soluto-private/mx-asurion-ui-react";

import { Button } from "../../lib/components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
`;

export const Body = styled.div`
  display: flex;
  width: 100%;
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 200px;
  padding: ${getFontSize(3)};
`;

export const TagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-height: 100px;
  overflow: hidden;
`;

export const StyledTag = styled(Tag)`
  margin: 5px 5px 0 0;
`;

export const SidebarContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: ${getFontSize(2)};
  &&& {
    span {
      width: ${getFontSize(2)};
    }
  }
`;

export const MemberSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const StyledButton = styled(Button)`
  color: ${(props) => props.theme.textOnPrimary};
  margin: ${getFontSize(1)} 0 0 -10px;
`;

export const TagInfoContainer = styled.div`
  display: flex;
  margin-top: ${getFontSize(3)};
  flex-direction: column;
`;
