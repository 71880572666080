import { colors, Icon, IconSrc } from "@soluto-private/mx-asurion-ui-react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ASURION_COLORS } from "../../constants";

export interface ITabLinkProps {
  prependIcon?: IconSrc;
  appendIcon?: IconSrc;
  active?: boolean;
  children?: any;
  title: string;
  url: string;
}

const TabLinkBase = styled(Link)`
  padding: 12px 16px;
  border: none;
  border-bottom: 4px solid
    ${(props: ITabLinkProps) =>
      props.active ? colors.black : colors.neutralBrightest};
  min-width: 4px;
  background-color: ${({ theme }) => theme.primaryBackgroundColor};
  font-family: Apercu;
  cursor: pointer;
  display: inline-block;
  &:hover {
    border-bottom: 4px solid ${({ theme }) => theme.textOnPrimary};
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
  text-decoration: none;
  &:visited {
    color: ${colors.black};
  }
  color: ${({ theme }) => theme.textOnPrimary};
  display: flex;
`;

const TabLinkIcon = styled(Icon)`
  margin: 0 6px;
  filter: invert(
    ${({ theme }) => (theme.textOnPrimary === ASURION_COLORS.white ? 1 : 0)}
  );
`;

const TabLinkContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  color: ${({ theme }) => theme.textOnPrimary};
  justify-content: center;
  font-size: 16px;
`;

export function TabLink(props: ITabLinkProps): JSX.Element {
  const { url, prependIcon, active, appendIcon, title } = props;
  return (
    <TabLinkBase to={url} {...props} active={active}>
      <TabLinkContentWrapper>
        {prependIcon ? <TabLinkIcon src={prependIcon} /> : null}
        {title}
        {appendIcon ? <TabLinkIcon src={appendIcon} /> : null}
      </TabLinkContentWrapper>
    </TabLinkBase>
  );
}

export default TabLink;
