import SunEditor from "suneditor-react";

import { RichTextViewerContainer } from "./elements";
import { RichTextViewerProps } from "./types";

export function RichTextViewer({ content, className }: RichTextViewerProps) {
  return (
    <RichTextViewerContainer className={className}>
      <SunEditor
        setContents={content}
        disable
        hideToolbar
        setOptions={{ mode: "balloon" }}
        setDefaultStyle="padding: 1rem; font-family: Apercu; font-size: 1rem; min-height: auto; height: auto;"
      />
    </RichTextViewerContainer>
  );
}

export default RichTextViewer;
