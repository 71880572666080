import styled, { useTheme } from "styled-components";
import {
  Text,
  Button,
  getFontSize,
  Tag,
  TagProps,
} from "@soluto-private/mx-asurion-ui-react";
import { Element, Link } from "react-scroll";
import Modal from "styled-react-modal";
import { toast } from "react-toastify";
import { Col, Row } from "react-grid-system";
import DataTable from "react-data-table-component";
import { ENTITY_STATI } from "@apim/lib-portal-entities";
import { useHistory } from "react-router-dom";
import React, { useContext, useState } from "react";
import { COLORS } from "../../../constants";
import { UserOwnershipContext, useUser } from "../../../lib/hooks";
import { useProductUnLinkApi } from "../../../lib/api";
import { DeleteForm, EditProductForm } from "../../../lib/components/Forms";

type DisplayProps = "API" | "Users" | "Edit" | "Delete";

export const ProductWrapper = styled.div`
  width: auto;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
`;

export interface ITagProps extends TagProps {
  isColored: boolean;
}

export const ProductContainer = styled.div<{ isSideBarOpen?: boolean }>`
  width: auto;
  background-color: ${(props) => props.theme.secondaryBackgroundColor};
`;

export const ProductScrollToTopButton = styled(Button)`
  position: fixed;
  left: 50%;
  bottom: 40px;
  z-index: 1;
  cursor: pointer;
`;

export const ProductContentSection = styled(Element)`
  padding-left: 1vw;
  padding-top: 2vw;
  padding-bottom: 2vw;
  padding-right: 1vw;
  border-bottom: 1px solid ${(props) => props.theme.invertedTextOnSecondary};
  // &&& {
  //   min-height: calc(100vh - 75px);
  // }
`;

export const StyledButtonSidebar = styled(Button)`
  color: ${(props) => props.theme.invertedTextOnSecondary};
  margin: ${getFontSize(1)} 0 0 0px;
  text-decoration: none;
  &&& {
    &:hover {
      color: ${(props) => props.theme.invertedTextOnSecondary};
      background-color: transparent;
    }
  }
`;

export const ProductSkeletonWrapper = styled.div`
  padding: 25px 5vw;
  border-bottom: 1px solid ${(props) => props.theme.invertedTextOnSecondary};
  &&& {
    min-height: calc(100vh - 75px);
  }
`;

export const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const ProductComponentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ProductRow = styled.div`
  width: 100%;
  margin-bottom: 50px;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 0;
  float: right;
  @media print {
    display: none;
  }
`;

export const SidebarContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: 12px;
  padding-left: 5px;
  flex-direction: column;
  // & {
  //   span {
  //     width: ${getFontSize(2)};
  //   }
  // }
`;

export const SidebarContentWrapperNewDoc = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 12px;
  & {
    span {
      width: ${getFontSize(2)};
    }
  }
`;

export const SectionContentDivider = styled.hr`
  border-top: 1px solid ${(props) => props.theme.invertedTextOnSecondary};
  margin: 70px 0px;
`;

export const ProductTagContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 0;
  & > * {
    margin-right: 8px;
  }
`;

export const StyledButton = styled(Button)`
  font-weight: 500;
  color: ${COLORS.WHITE};
  margin: ${getFontSize(1)} 0 0 -10px;
  font-size: 14px;
  text-decoration: none;
  &&& {
    &:hover {
      color: ${(props) => props.theme.invertedTextOnSecondary};
      background-color: transparent;
    }
  }
`;

export const StyledScrollLink = styled(Link)`
  font-weight: 500;
  padding-left: 5px;
  width: 100%;
  color: ${(props) => props.theme.textOnPrimary};
  margin: ${getFontSize(1)} 0 0 -10px;
  font-size: 1rem;
  text-decoration: none;
  &&& {
    &:hover {
      color: ${(props) => props.theme.invertedTextOnPrimary};
      cursor: pointer;
    }
    &:hover.active-link {
      border-radius: 8px;
      background: ${(props) => props.theme.secondaryBackgroundColor};
    }
    &.active-link {
      border-radius: 8px;
      background: ${(props) => props.theme.secondaryBackgroundColor};
    }
  }
`;

export const ProductFilesHeader = styled.div`
  padding-bottom: 12px;
  margin-bottom: 32px;
`;

const ControlWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 50px;
`;

export const SectionTitle = styled.h2`
  margin-right: ${getFontSize(4)};
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 20px;
  display: inline;
`;

export const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1em;
  margin-top: 1em;
`;

export const MetaWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 10px;
`;

export const MetaWrapperDropdown = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 10px;
`;
export const MetaRowDropdown = styled.div`
  width: 170px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
export const MetaRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const StyledTag = styled(Tag)<ITagProps>`
  display: inline;
  margin-right: 5px;
  ${(props) => props.isColored && `background-color: ${props.theme.secondary};`}
  ${(props) =>
    props.isColored && `color: ${props.theme.secondaryBackgroundColor};`}
`;

export const ActionsWrapper = styled.div`
  display: inline;
  margin-top: 20px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 10px;
`;

export const HeaderActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ManageProductWrapper = styled.div`
  display: flex;
  column-gap: 1rem;
`;

/**
 * Render product header
 *
 * @param name Product Name
 * @param created Product creation date
 * @param status Product status
 */
export function ProductHeader({
  pk,
  status,
  isPublic,
  description,
  canModifyProduct,
  product,
  setProduct,
  deleteProduct,
  isDeletingProduct,
}: any) {
  const userOwnership = useContext(UserOwnershipContext);
  const [user] = useUser();
  const [isModalOpenForm, setIsModalOpenForm] = useState(false);
  const [selectedDisplay, setSelectedDisplay] = useState<DisplayProps>("API");
  const history = useHistory();
  const toggleModalForm = () => {
    setIsModalOpenForm(!isModalOpenForm);
  };
  const handleProductEditSuccess = async (updatedProduct: any) => {
    if (product.pk !== updatedProduct) {
      history.replace(`/products/${updatedProduct.pk}`);
      userOwnership.products?.push(updatedProduct.pk);
    }
    setProduct(updatedProduct);
    toggleModalForm();
  };
  const setModalContent = (content: DisplayProps) => {
    switch (content) {
      case "Edit":
        return (
          <EditProductForm
            handleSuccess={(product) => handleProductEditSuccess(product)}
            handleCancel={toggleModalForm}
            product={product}
          />
        );
      case "Delete":
        return (
          <DeleteForm
            entity="Product"
            name={pk}
            message="Are you sure you want delete this product? This process is
        irreversible."
            isDeleteLoading={isDeletingProduct}
            onDelete={() => {
              deleteProduct();
            }}
            onCancel={toggleModalForm}
          />
        );
      default:
        break;
    }
    return null;
  };
  return (
    <ProductComponentContainer>
      <Modal isOpen={isModalOpenForm} onBackgroundClick={toggleModalForm}>
        {setModalContent(selectedDisplay)}
      </Modal>
      <ControlWrapper>
        <MetaWrapper>
          <MetaRow>
            {user && user.isInternal() && (
              <>
                <StyledTag
                  text={isPublic ? "Public" : "Private"}
                  type={isPublic ? "attention" : "gray"}
                  isColored={isPublic}
                />
                <StyledTag
                  text={
                    status === ENTITY_STATI.COMPLETE
                      ? "Completed"
                      : "In progress"
                  }
                  type={status === ENTITY_STATI.COMPLETE ? "attention" : "gray"}
                  isColored={status === ENTITY_STATI.COMPLETE}
                />
              </>
            )}
          </MetaRow>
        </MetaWrapper>
        <HeaderActionsWrapper>
          <Text size={5} weight="heavy">
            {pk}
          </Text>
          {canModifyProduct && !userOwnership.archProducts?.includes(pk) && (
            <ManageProductWrapper>
              <Button
                variant="outline"
                size="small"
                onClick={() => {
                  setSelectedDisplay("Edit");
                  setIsModalOpenForm(true);
                }}
              >
                Edit Product
              </Button>
              <Button
                variant="outline"
                size="small"
                onClick={() => {
                  setSelectedDisplay("Delete");
                  setIsModalOpenForm(true);
                }}
              >
                Delete Product
              </Button>
            </ManageProductWrapper>
          )}
        </HeaderActionsWrapper>
      </ControlWrapper>
      <MetaWrapper>
        <MetaRow>
          <textarea rows={1} cols={120}>
            {description}
          </textarea>
        </MetaRow>
      </MetaWrapper>
    </ProductComponentContainer>
  );
}
function RenderSpecDeleteButton(canModify: boolean, callback: () => void) {
  return (
    canModify && (
      <Button
        onClick={callback}
        ariaLabel="Bin"
        iconSide="right"
        color="secondary"
        iconSrc="Bin"
        size="small"
        variant="default"
      />
    )
  );
}

export function ProductLinkedApis({ pk, apis, canModify }: any) {
  const theme = useTheme();
  const history = useHistory();
  const [selectedUnlinkApi, setSelectedUnlinkApi] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const columns = [
    {
      name: "Name",
      selector: (api: any) => api.titleElement,
      sortable: true,
      style: {
        cursor: "pointer",
        "text-decoration": "underline",
      },
    },
    {
      name: "",
      button: true,
      cell: (row: any) =>
        RenderSpecDeleteButton(canModify, () => {
          setSelectedUnlinkApi(row.title);
          setIsModalOpen(true);
        }),
    },
  ];

  const {
    invokeApi: invokeProductUnLinkApi,
    error: deleteUnlinkError,
    isLoading: unlinkApiLoading,
  } = useProductUnLinkApi(pk, selectedUnlinkApi);

  const handleDelete = async () => {
    await invokeProductUnLinkApi();
    if (deleteUnlinkError) {
      toast.error(`Error occured: ${deleteUnlinkError}`);
    } else {
      toast.success("API unlinked successfully.");
      history.go(0);
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const transformLinks: any = (items: any) => {
    return items.map((item: any) => {
      const { title } = item;
      const titleElement = (
        <Link
          activeClass="active-link"
          to={title}
          spy
          smooth
          duration={50}
          offset={-75}
          isDynamic
        >
          {title}
        </Link>
      );
      return {
        ...item,
        titleElement,
      };
    });
  };

  return (
    <>
      <SectionHeader>
        <SectionTitle>Product APIs</SectionTitle>
        <Modal isOpen={isModalOpen} onBackgroundClick={toggleModal}>
          <DeleteForm
            entity="Linked API"
            name={selectedUnlinkApi}
            message="Are you sure you want to unlink this API is irreversible."
            isDeleteLoading={unlinkApiLoading}
            onDelete={handleDelete}
            onCancel={toggleModal}
          />
        </Modal>
      </SectionHeader>
      <DataTable
        columns={columns}
        data={transformLinks(apis)}
        customStyles={{
          rows: {
            style: {
              backgroundColor: theme.secondaryBackgroundColor,
              color: theme.invertedTextOnSecondary,
            },
          },
          table: {
            style: {
              backgroundColor: theme.secondaryBackgroundColor,
              color: theme.invertedTextOnSecondary,
            },
          },
          cells: {
            style: {
              backgroundColor: theme.secondaryBackgroundColor,
              color: theme.invertedTextOnSecondary,
              "&:hover": {
                cursor: "pointer",
                color: theme.invertedTextOnPrimary,
              },
            },
          },
          headCells: {
            style: {
              backgroundColor: theme.secondaryBackgroundColor,
              color: theme.invertedTextOnSecondary,
            },
          },
          tableWrapper: {
            style: {
              backgroundColor: theme.secondaryBackgroundColor,
              color: theme.invertedTextOnSecondary,
            },
          },
          head: {
            style: {
              backgroundColor: theme.secondaryBackgroundColor,
              color: theme.invertedTextOnSecondary,
            },
          },
          headRow: {
            style: {
              backgroundColor: theme.secondaryBackgroundColor,
              color: theme.invertedTextOnSecondary,
            },
          },
          header: {
            style: {
              backgroundColor: theme.secondaryBackgroundColor,
              color: theme.invertedTextOnSecondary,
            },
          },
          noData: {
            style: {
              backgroundColor: theme.secondaryBackgroundColor,
              color: theme.invertedTextOnSecondary,
            },
          },
        }}
        onRowClicked={() => {}}
      />
    </>
  );
}

/**
 * Render Product Body
 *
 * @param overview Overview file of API
 * @param apis Set of APIs registered under product
 * @param tags Product tags
 * @param slug Product slug
 */

/**
 * Render list of attachments
 *
 * @param slug Product slug for attachment url
 * @param attachments Attachment list
 */
export function ProductFiles({ attachments }: any) {
  return attachments && attachments.length > 0 ? (
    <ProductComponentContainer>
      <ProductFilesHeader>
        <Text>Uploads + Attachments</Text>
      </ProductFilesHeader>
      <Row>
        <Col lg={6} md={8} xs={12}>
          {/* {attachments.map((attachment, key) => (
            <ProductAttachmentLink
              href={`/products/${pk}/attachments/${attachment}`}
              target="_blank"
              key={key}
            >
              <Icon src="Attachment" />
              <Text>{attachment}</Text>
            </ProductAttachmentLink>
          ))} */}
        </Col>
      </Row>
    </ProductComponentContainer>
  ) : null;
}
