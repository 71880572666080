import { Auth } from "aws-amplify";

// * REF: https://github.com/aws-amplify/amplify-js/issues/2560
export async function refreshToken(callback?: () => void) {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = (await Auth.currentSession()) as any;
    await cognitoUser.refreshSession(
      currentSession.refreshToken,
      (err: Error, session: any) => {
        if (!err) {
          callback && callback();
        }
        return session;
      }
    );
  } catch (e) {
    throw new Error(`Unable to refresh Token ${e}`);
  }
}

export default refreshToken;
