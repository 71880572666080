import { useContext, useEffect, useState, createContext } from "react";

import { BREAKPOINTS } from "../../constants";

interface Media {
  width: number;
  height: number;
}
const MediaContext = createContext<Media>({ width: 0, height: 0 });

interface MediaProviderProp {
  children: JSX.Element;
}

function MediaProvider(props: MediaProviderProp) {
  const { children } = props;
  const [media, setMedia] = useState<Media>({
    width: window.document.body.clientWidth,
    height: window.document.body.clientHeight,
  });

  const handleSettingMedia = () => {
    setMedia({
      width: window.document.body.clientWidth,
      height: window.document.body.clientHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleSettingMedia, false);
    window.addEventListener("load", handleSettingMedia, false);

    return function cleanup() {
      window?.removeEventListener("resize", handleSettingMedia);
      window?.removeEventListener("load", handleSettingMedia);
    };
  }, []);
  return (
    <MediaContext.Provider value={media}>{children}</MediaContext.Provider>
  );
}

// * Use the keys in BREAKPOINTS as key in the MediaBreakpoint
// * Reference: https://stackoverflow.com/questions/44243060/use-enum-as-restricted-key-type-in-typescript
type MediaBreakpoint = { [key in keyof typeof BREAKPOINTS]: boolean } & {
  width: number;
  height: number;
  [key: string]: any;
};

const useMedia = () => {
  const dimension = useContext(MediaContext);

  return Object.entries(BREAKPOINTS).reduce((accumulator, [size, value]) => {
    accumulator[size] =
      size === "LG" ? dimension.width >= value : dimension.width < value;
    accumulator.width = dimension.width;
    accumulator.height = dimension.height;
    return accumulator;
  }, {} as MediaBreakpoint);
};

export { MediaContext, useMedia, MediaProvider };
