import { Button, Text, TextField } from "@soluto-private/mx-asurion-ui-react";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Hidden } from "react-grid-system";
import * as Yup from "yup";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Modal from "styled-react-modal";

import { useCreateProductDoc, useDeleteProductDocument } from "../api";
import { RichTextEditor, RichTextViewer } from "./RichTextEditor";
import { COLORS, DOCUMENT_NAME_VALIDATION } from "../../constants";
import { DeleteForm } from "./Forms";

interface IProductDocProps {
  documentation: any;
  onChange: (document?: string) => void;
  canModify: boolean;
}

interface IDocumentTitleContainerProps {
  title: string;
  createdAt?: string;
  status?: string;
}

interface IProductDocMenuProps {
  onCancelClicked: () => void;
  onSaveClicked: () => void;
  onPreviewClicked: () => void;
  onDeleteClicked?: () => void;
  controlsDisabled?: boolean;
  saveText?: string;
  cancelText?: string;
  previewText?: string;
  deleteText?: string;
}

export type ProductDocProps = IProductDocProps;
export type ProductDocMenuProps = IProductDocMenuProps;
export type DocumentTitleContainerProps = IDocumentTitleContainerProps;

export const ProductDocWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
  margin-bottom: 64px;
  align-items: stretch;
  max-width: 1250px;
  @media print {
    max-width: 100%;
    display: block;
    margin: 0;
  }
`;

export const ProductDocContainer = styled.div`
  flex-grow: 1;
`;

const ProductDocNavContainer = styled.div`
  width: 150px;
  flex-shrink: 0;
  padding: 0 16px;
`;

const ProductDocMenuWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
  @media print {
    display: none;
  }
`;

const SectionLink = styled.a`
  display: block;
  margin-bottom: 8px;
  &,
  &:hover {
    text-decoration: none;
  }
`;

const DocumentTitleText = styled(Text)`
  display: block;
`;

const DocumentSectionsTitle = styled(DocumentTitleText)`
  margin-bottom: 8px;
`;

const DocumentHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const DocumentTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  & > *:not(:last-child) {
    margin-bottom: 4px;
  }
`;

const DocumentTitleTextFieldContainer = styled.div`
  width: 30vw;
`;

const ProductActionButton = styled(Button)`
  text-decoration: none;

  & > *:last-child {
    margin-left: 4px;
  }
`;

const ProductActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  @media print {
    display: none;
  }
`;

const WarningButton = styled(Button)`
  border-color: ${COLORS.RED};
  background-color: ${COLORS.WHITE};
  color: ${COLORS.RED};
  &&& {
    &:hover {
      border-color: ${COLORS.WHITE};
      background-color: ${COLORS.RED};
      color: ${COLORS.WHITE};
    }
  }
  margin-right: 5px;
`;

export function DocumentTitleContainer({
  title,
  createdAt,
  status,
}: DocumentTitleContainerProps) {
  return (
    <DocumentTitleWrapper>
      <DocumentTitleText size={5} weight="heavy">
        {title}
      </DocumentTitleText>
      {createdAt && (
        <Text>
          Created at
          {createdAt}
        </Text>
      )}
      {status && (
        <Text>
          Status
          {status}
        </Text>
      )}
    </DocumentTitleWrapper>
  );
}

export function ProductDocMenu({
  onSaveClicked,
  onCancelClicked,
  onPreviewClicked,
  onDeleteClicked,
  controlsDisabled,
  saveText,
  cancelText,
  previewText,
  deleteText,
}: ProductDocMenuProps) {
  return (
    <ProductDocMenuWrapper>
      <ProductActionButton
        variant="outline"
        size="small"
        color="secondary"
        onClick={onCancelClicked}
        disabled={controlsDisabled}
      >
        {cancelText || "Cancel"}
      </ProductActionButton>
      <ProductActionButton
        variant="outline"
        size="small"
        disabled={controlsDisabled}
        onClick={onPreviewClicked}
      >
        {previewText || "Preview"}
      </ProductActionButton>
      {onDeleteClicked && (
        <WarningButton
          size="small"
          disabled={controlsDisabled}
          onClick={onDeleteClicked}
        >
          {deleteText || "Delete"}
        </WarningButton>
      )}
      <ProductActionButton
        variant="default"
        size="small"
        color="secondary"
        disabled={controlsDisabled}
        onClick={onSaveClicked}
      >
        {saveText || "Save"}
      </ProductActionButton>
    </ProductDocMenuWrapper>
  );
}

const DocumentFormSchema = Yup.object().shape({
  documentTitle: Yup.string()
    .min(3, "Document name required to be at least 3 characters long")
    .matches(
      DOCUMENT_NAME_VALIDATION,
      "Only alphanumeric characters,space,-,_,+ are allowed for the name."
    )
    .required("Document name required."),
});

export function ProductDoc({
  documentation,
  onChange,
  canModify,
}: ProductDocProps) {
  const [editMode, setEditMode] = useState(false);
  const history = useHistory();
  const [previewMode, setPreviewMode] = useState(false);
  const [documentTitle] = useState<string>(documentation.documentTitle);
  const {
    response: docUpdateResponse,
    isLoading: isDocUpdating,
    error: docUpdateError,
    invokeApi: updateProductDoc,
  } = useCreateProductDoc(documentation.pk);
  const {
    response: docDeleteResponse,
    isLoading: docDeleteIsLoading,
    invokeApi: deleteProductDoc,
  } = useDeleteProductDocument(documentation.pk, documentation.documentTitle);
  const [sectionTitles, setSectionTitles] = useState([] as string[]);
  const [pendingPayload, setPendingPayload] = useState(""); // * Modified document content
  const [editorContent, setEditorContent] = useState(
    documentation.payload || ""
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const formik = useFormik({
    initialValues: {
      documentTitle,
    },
    onSubmit: () => {},
    validateOnBlur: true,
    validationSchema: DocumentFormSchema,
  });

  // Subscribe to doc update events
  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      if (docUpdateResponse && !docUpdateError) {
        setEditMode(false);
        setPendingPayload("");
        if (documentTitle !== formik.values.documentTitle) {
          window.location.reload();
        } else {
          onChange();
        }
      } else if (docUpdateError) {
        throw new Error(`${docUpdateError}`);
      }
    }

    return () => {
      unmounted = true;
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [docUpdateError, docUpdateResponse, onChange]);

  useEffect(() => {
    if (docDeleteResponse) {
      setIsModalOpen(false);
      toast.success(docDeleteResponse.message);
      history.push(`/products/${documentation.pk}`);
      onChange();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [docDeleteResponse]);

  // Subscribe to changes on documentation prop, remounting of sections
  useEffect(() => {
    let unmounted = false;
    let sections: string[] = [];

    const sectionTitleCollection = Array.from(
      document.getElementsByClassName("doc_section_title")
    );
    sectionTitleCollection.map((sectionTitleParent: Element) => {
      sections = [...sections, sectionTitleParent.id];

      return true;
    });

    if (!unmounted) {
      setSectionTitles(sections);
    }

    return () => {
      unmounted = true;
    };
  }, [editorContent]);

  // Subscribe to changes from Product parent component
  useEffect(() => {
    setEditMode(false);
    setEditorContent(documentation.payload);
  }, [documentation]);

  // Document change event
  const handleOnDocumentChange = (payload: string) => {
    setPendingPayload(payload);
  };

  // Document save event
  const handleOnDocumentSave = async () => {
    if (
      pendingPayload === "" &&
      formik.values.documentTitle === documentTitle
    ) {
      setEditMode(false);
      return;
    }

    const payloadBuffer = new Blob([pendingPayload], {
      type: "text/plain",
    });
    const formData = new FormData();
    formData.append("file", payloadBuffer, documentation.payloadName);
    formData.append("parent", "PRODUCT");
    formData.append("resource", documentation.pk);
    formData.append("updatedDocTitle", formik.values.documentTitle);
    formData.append("previousDocTitle", documentation.sk);
    await updateProductDoc(
      {
        resource: documentation.pk,
      },
      formData,
      {
        "Content-Type": "multipart/form-data",
      }
    );
  };

  const handleOnDocumentDelete = async () => {
    await deleteProductDoc();
  };

  const handleOnDocumentPreview = () => {
    setPreviewMode(true);
    setEditMode(false);
  };

  const handleEditModeSwitch = () => {
    setPreviewMode(false);
    setEditMode(true);
  };

  const handleCancelClick = () => {
    setPendingPayload("");
    setEditMode(false);
  };

  const exportPage = () => {
    window.print();
  };

  return (
    <ProductDocWrapper>
      <Modal
        isOpen={isModalOpen}
        onBackgroundClick={() => setIsModalOpen(false)}
      >
        <DeleteForm
          entity="Document"
          name={documentTitle}
          message="Are you sure you want delete this document? This process is
        irreversible."
          isDeleteLoading={docDeleteIsLoading}
          onDelete={handleOnDocumentDelete}
          onCancel={() =>
            setIsModalOpen((prevState) => {
              return !prevState;
            })
          }
        />
      </Modal>
      <ProductDocContainer>
        <DocumentHeaderContainer>
          {editMode ? (
            <>
              <DocumentTitleTextFieldContainer>
                <TextField
                  label="Document Name"
                  value={formik.values.documentTitle}
                  name="documentTitle"
                  fieldStatus={
                    formik.errors.documentTitle ? "error" : "default"
                  }
                  helperText={
                    formik.errors.documentTitle
                      ? formik.errors.documentTitle
                      : ""
                  }
                  onChange={(value) => {
                    formik.handleChange(value);
                  }}
                />
              </DocumentTitleTextFieldContainer>
              <ProductDocMenu
                onSaveClicked={handleOnDocumentSave}
                onPreviewClicked={handleOnDocumentPreview}
                onCancelClicked={handleCancelClick}
                onDeleteClicked={() => setIsModalOpen(true)}
                controlsDisabled={isDocUpdating}
              />
            </>
          ) : (
            <>
              <DocumentTitleContainer title={documentTitle} />
              <ProductActionWrapper>
                {canModify && (
                  <ProductActionButton
                    variant="default"
                    iconSide="right"
                    iconSrc="EditPencilChange"
                    color="secondary"
                    size="small"
                    onClick={handleEditModeSwitch}
                    disabled={isDocUpdating}
                  >
                    {previewMode ? "Continue Editing" : "Edit"}
                  </ProductActionButton>
                )}
                <ProductActionButton
                  variant="outline"
                  size="small"
                  iconSide="right"
                  iconSrc="DataDownload"
                  color="secondary"
                  onClick={exportPage}
                >
                  Export Document
                </ProductActionButton>
              </ProductActionWrapper>
            </>
          )}
        </DocumentHeaderContainer>
        {editMode ? (
          <RichTextEditor
            content={pendingPayload === "" ? editorContent : pendingPayload}
            onChange={handleOnDocumentChange}
            disabled={isDocUpdating}
            isLoading={isDocUpdating}
          />
        ) : (
          <RichTextViewer
            content={
              previewMode && pendingPayload !== ""
                ? pendingPayload
                : editorContent
            }
          />
        )}
      </ProductDocContainer>
      <Hidden md>
        <ProductDocNavContainer>
          {sectionTitles.length > 0 && !editMode && (
            <DocumentSectionsTitle weight="heavy">
              Jump to section
            </DocumentSectionsTitle>
          )}
          {sectionTitles.length > 0 && !editMode
            ? sectionTitles.map((title: string) => (
                <SectionLink href={`#${title}`} key={title}>
                  {title}
                </SectionLink>
              ))
            : null}
        </ProductDocNavContainer>
      </Hidden>
    </ProductDocWrapper>
  );
}
