import styled, { useTheme } from "styled-components";
import {
  getFontSize,
  Tag,
  Icon,
  Checkbox,
  Text,
  AsurionDoodleSpinner,
  Modal as AsurionModal,
  Breadcrumb,
  TagProps,
} from "@soluto-private/mx-asurion-ui-react";
import Modal from "styled-react-modal";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import { ENTITY_STATI } from "@apim/lib-portal-entities";

import { Sidebar, UploadSpecForm, ApiForm, Button } from "../../lib/components";
import {
  useGetApi,
  useGetApiRecords,
  useDeleteApi,
  useDeleteApiDoc,
  useUpdateAPIDocFlag,
  useEditApiInfo,
} from "../../lib/api";
import { useUser } from "../../lib/hooks";
import {
  ApiDetailContainer,
  FieldText,
  ActionsContainer,
  ApiActionButton,
  DeleteButton,
} from "./elements";

interface ITagProps extends TagProps {
  isColored: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  margin: 0;
  padding: 0;
`;

export const StyledActionsContainer = styled(ActionsContainer)`
  justify-content: flex-end;
`;

export const ApiEditBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 200px;
  padding: ${getFontSize(3)};
`;

export const StyledButton = styled(Button)`
  color: ${(props) => props.theme.textOnPrimary};
  margin: ${getFontSize(1)} 0 0 -10px;
`;

export const SidebarContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: ${getFontSize(2)};
  &&& {
    span {
      width: ${getFontSize(2)};
    }
  }
`;

const StyledDeleteButton = styled(DeleteButton)`
  margin-left: 5px;
`;

export const ApiSpecControlWrapper = styled.div`
  display: flex;
  margin: 1rem;
`;

export const ApiSpecHeader = styled.div`
  display: flex;
  padding: 0 ${getFontSize(3)};
  justify-content: space-between;
`;

export const TagWrapper = styled.div`
  display: flex;
  column-gap: 5px;
  margin: 10px 0;
`;

const StyledTag = styled(Tag)<ITagProps>`
  ${(props) => props.isColored && `background-color: ${props.theme.secondary};`}
  ${(props) =>
    props.isColored && `color: ${props.theme.secondaryBackgroundColor};`}
`;

type EditType = "edit-api" | "edit-api-spec" | "delete-api" | "delete-api-spec";

function RenderCheckbox(type: string, data: any, responseCallback: () => void) {
  // eslint-disable-next-line react/destructuring-assignment
  const payload = { ...data, [type]: !!data[type] };
  const { sk, pk, isPublic, isDefault } = payload;
  const [active, setActive] = useState<boolean>(!!payload[type]);
  const encodedSK = encodeURIComponent(sk.split("#")[1] ?? "");
  const { invokeApi: updateSpecFlag, isLoading } = useUpdateAPIDocFlag(
    pk,
    encodedSK
  );

  return (
    <Checkbox
      checked={active}
      label=""
      disabled={isLoading}
      name="radioGroupName"
      onChange={async () => {
        await setActive(!active);
        await updateSpecFlag(null, {
          isPublic: type === "isPublic" ? !data[type] : isPublic, // eslint-disable-line react/destructuring-assignment
          isDefault: type === "isDefault" ? !data[type] : isDefault, // eslint-disable-line react/destructuring-assignment
          env: sk.split("#")[2],
        });
        responseCallback(); // * Start getting api records
      }}
      options={{
        multiline: false,
      }}
      value="defaultValue"
    />
  );
}

function RenderSpecDeleteButton(callback: () => void) {
  return (
    <Button
      onClick={callback}
      ariaLabel="Bin"
      iconSide="right"
      iconSrc="Bin"
      size="small"
      variant="default"
    />
  );
}

export function ApiEdit() {
  const { id } = useParams<{ id: string }>();
  const [canModify, setCanModify] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteAPIModalOpen, setIsDeleteAPIModalOpen] = useState(false);
  const [selectedSpec, setSelectedSpec] = useState<string>("");
  const [editMode, setEditMode] = useState<EditType>("edit-api");
  const [specs, setSpecs] = useState<any>();
  const [user] = useUser();
  const history = useHistory();
  const theme = useTheme();

  // * CRUD hooks
  const {
    response: updateResponse,
    error: updateResponseError,
    isLoading: updateIsLoading,
    invokeApi: invokeUpdateApi,
  } = useEditApiInfo(id);
  const {
    response: deleteSpecResponse,
    invokeApi: invokeDeleteSpec,
    error: deleteSpecError,
    isLoading: deleteSpecLoading,
  } = useDeleteApiDoc(id, selectedSpec);
  const { invokeApi: deleteApi } = useDeleteApi(id);
  const {
    response: api,
    invokeApi: getApi,
    isLoading: isFetchingAPI,
  } = useGetApi(id);
  const {
    response: apiRecords,
    invokeApi: getApiRecords,
    isLoading: isFetchingApiRecords,
  } = useGetApiRecords(id);
  // * CRUD hooks end

  useEffect(() => {
    if (apiRecords && user) {
      const ownerTeams = apiRecords?.owners?.map((rel) => rel.pk);
      const userTeam = user?.getUserOwnershipsWithMinRights(["*:api:write"]);
      if (userTeam.teams.length) {
        userTeam.teams.forEach((team) => {
          if (ownerTeams?.includes(team)) {
            setCanModify(true);
          }
        });
      }
    }
  }, [user, apiRecords]);

  useEffect(() => {
    if (updateResponse) {
      setIsModalOpen(false);
      toast.success(
        `Update for API '${updateResponse.apiName}' is successful.`
      );
      history.push(`/api/${updateResponse.apiName}`);
    }
    if (updateResponseError) {
      setIsModalOpen(false);
      toast.error(updateResponseError?.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateResponse, updateResponseError]);

  useEffect(() => {
    getApi();
    getApiRecords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (deleteSpecResponse && !deleteSpecError) {
      getApiRecords();
    }
  }, [getApiRecords, deleteSpecResponse, deleteSpecError]);

  useEffect(() => {
    if (apiRecords) {
      const { docs } = apiRecords;
      if (docs) {
        setSpecs([...docs]);
      }
    }
  }, [apiRecords]);

  const navigateToSection = (section: string) => {
    document
      .getElementById(section)
      ?.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const toggleModal = (event: EditType) => {
    setEditMode(event);
    if (event.includes("delete")) {
      setIsDeleteAPIModalOpen(!isDeleteAPIModalOpen);
    } else {
      setIsModalOpen(!isModalOpen);
    }
  };

  const handleFlagChange = async () => {
    await getApiRecords();
  };

  const apiDocColumns = [
    {
      name: "Specification",
      selector: (row: any) => {
        const formattedSk = row.sk.replace("DOC#", "").split("#");
        return `${formattedSk[0]}`;
      },
      sortable: true,
    },
    {
      name: "Environment",
      selector: (row: any) => {
        const formattedSk = row.sk.split("#");
        return `${formattedSk[2]}`;
      },
      sortable: true,
    },
    {
      name: "Public",
      button: true,
      cell: (row: any) => RenderCheckbox("isPublic", row, handleFlagChange),
    },
    {
      name: "Default",
      button: true,
      cell: (row: any) => RenderCheckbox("isDefault", row, handleFlagChange),
    },
    {
      name: "",
      button: true,
      cell: (row: any) =>
        RenderSpecDeleteButton(() => {
          toggleModal("delete-api-spec");
          setSelectedSpec(encodeURIComponent(row.sk.replace("DOC#", "")));
        }),
    },
  ];

  const onApiUpdateSubmit = async (e: any) => {
    const payload = { ...e, pk: e.name, sk: "INFO" };
    try {
      await invokeUpdateApi(null, payload, true);
    } catch (e: any) {
      throw new Error(e as string);
    }
  };

  const handleDelete = async () => {
    if (editMode === "delete-api") {
      await deleteApi();
      toast.success("API deleted successfully.");
      history.push("/apis");
    } else if (editMode === "delete-api-spec") {
      await invokeDeleteSpec();
      toast.success("API specification deleted successfully.");
      setIsDeleteAPIModalOpen(!isDeleteAPIModalOpen);
    }
  };

  return (
    <Wrapper>
      <Modal
        isOpen={isModalOpen}
        onBackgroundClick={() => setIsModalOpen(false)}
      >
        {editMode === "edit-api-spec" && (
          <UploadSpecForm
            api={id}
            onCancel={() => setIsModalOpen(false)}
            onSuccess={(specEnv?: string) => {
              setIsModalOpen(false);
              if (specEnv) {
                history.push({
                  pathname: `/api/${id}`,
                  search: `?spec-env=${specEnv}`,
                });
              } else {
                window.location.reload();
              }
            }}
          />
        )}
        {editMode === "edit-api" && (
          <ApiForm
            onCancel={() => setIsModalOpen(false)}
            api={api as any}
            onSubmit={onApiUpdateSubmit}
            isLoading={updateIsLoading}
          />
        )}
      </Modal>
      <AsurionModal
        title={`Delete ${
          editMode === "delete-api-spec" ? `${id} specification` : id
        }?`}
        isOpen={isDeleteAPIModalOpen}
        primaryButtonLabel="Okay"
        secondaryButtonLabel="Cancel"
        onPrimaryClick={handleDelete}
        onSecondaryClick={() => setIsDeleteAPIModalOpen(false)}
        onCloseClick={() => setIsDeleteAPIModalOpen(false)}
      >
        <Text>
          Are you sure you want to delete this API
          {editMode === "delete-api-spec" ? " specification" : "?"} This process
          is irreversible.
        </Text>
      </AsurionModal>
      <Sidebar open>
        <SidebarContentWrapper>
          <StyledButton
            variant="text"
            iconSrc="TV"
            key="overview-btn-key"
            size="medium"
            onClick={() => navigateToSection("general")}
          >
            General
          </StyledButton>
          <StyledButton
            variant="text"
            iconSrc="ExternalMonitor"
            key="user-btn-key"
            size="medium"
            onClick={() => navigateToSection("specs")}
          >
            Specs
          </StyledButton>
        </SidebarContentWrapper>
      </Sidebar>
      <ApiEditBody>
        <Section id="general">
          <Breadcrumb>
            <Button
              size="large"
              variant="text"
              color="secondary"
              onClick={() => history.goBack()}
            >
              {id}
            </Button>
            <Text style={{ marginTop: 0 }} as="h1" weight="heavy" size={3}>
              Settings
            </Text>
          </Breadcrumb>
          <ApiDetailContainer>
            <>
              <Text style={{ marginTop: 0 }} as="h1" weight="heavy" size={4}>
                {api?.pk ?? "Loading..."}
              </Text>
              {!isFetchingAPI && (
                <TagWrapper>
                  <StyledTag
                    text={api?.isPublic ? "Public" : "Private"}
                    type={api?.isPublic ? "attention" : "gray"}
                    size="regular"
                    isColored={api?.isPublic === true}
                  />
                  <StyledTag
                    text={
                      api?.status === ENTITY_STATI.COMPLETE
                        ? "Completed"
                        : "In progress"
                    }
                    type={
                      api?.status === ENTITY_STATI.COMPLETE
                        ? "attention"
                        : "gray"
                    }
                    size="regular"
                    isColored={api?.status === ENTITY_STATI.COMPLETE}
                  />
                </TagWrapper>
              )}
            </>
            {!isFetchingAPI && (
              <p>
                <FieldText>URI: </FieldText>
                {api?.URI ?? ""}
              </p>
            )}

            {!isFetchingAPI && (
              <p>
                <FieldText>Description: </FieldText>
                {api?.description}
              </p>
            )}
            {isFetchingAPI && <AsurionDoodleSpinner />}

            {canModify && (
              <StyledActionsContainer>
                <ApiActionButton
                  size="small"
                  variant="outline"
                  color="secondary"
                  iconSrc="EditPencilChange"
                  iconSide="right"
                  onClick={() => toggleModal("edit-api")}
                >
                  Edit
                </ApiActionButton>
                <StyledDeleteButton
                  size="small"
                  variant="outline"
                  color="secondary"
                  iconSrc="Bin"
                  iconSide="right"
                  onClick={() => toggleModal("delete-api")}
                >
                  Delete
                </StyledDeleteButton>
              </StyledActionsContainer>
            )}
          </ApiDetailContainer>
        </Section>
        <Section id="specs">
          <ApiSpecHeader>
            <Text as="h1" weight="heavy">
              API Specifications
            </Text>
            <ApiSpecControlWrapper>
              <Button
                size="small"
                variant="outline"
                color="secondary"
                iconSrc="DataUpload"
                iconSide="right"
                onClick={() => toggleModal("edit-api-spec")}
              >
                Upload
              </Button>
            </ApiSpecControlWrapper>
          </ApiSpecHeader>
          <DataTable
            columns={apiDocColumns}
            customStyles={{
              rows: {
                style: {
                  backgroundColor: theme.secondaryBackgroundColor,
                  color: theme.invertedTextOnSecondary,
                },
              },
              table: {
                style: {
                  backgroundColor: theme.secondaryBackgroundColor,
                  color: theme.invertedTextOnSecondary,
                },
              },
              cells: {
                style: {
                  backgroundColor: theme.secondaryBackgroundColor,
                  color: theme.invertedTextOnSecondary,
                },
              },
              headCells: {
                style: {
                  backgroundColor: theme.secondaryBackgroundColor,
                  color: theme.invertedTextOnSecondary,
                },
              },
              tableWrapper: {
                style: {
                  backgroundColor: theme.secondaryBackgroundColor,
                  color: theme.invertedTextOnSecondary,
                },
              },
              head: {
                style: {
                  backgroundColor: theme.secondaryBackgroundColor,
                  color: theme.invertedTextOnSecondary,
                },
              },
              headRow: {
                style: {
                  backgroundColor: theme.secondaryBackgroundColor,
                  color: theme.invertedTextOnSecondary,
                },
              },
              header: {
                style: {
                  backgroundColor: theme.secondaryBackgroundColor,
                  color: theme.invertedTextOnSecondary,
                },
              },
              noData: {
                style: {
                  backgroundColor: theme.secondaryBackgroundColor,
                  color: theme.invertedTextOnSecondary,
                },
              },
              progress: {
                style: {
                  backgroundColor: theme.secondaryBackgroundColor,
                  color: theme.invertedTextOnSecondary,
                },
              },
            }}
            data={specs ?? []}
            onRowClicked={() => {}}
            progressPending={isFetchingApiRecords || deleteSpecLoading}
            progressComponent={<AsurionDoodleSpinner />}
            sortIcon={<Icon size="small" src="ArrowUp" />}
          />
        </Section>
      </ApiEditBody>
    </Wrapper>
  );
}

export default ApiEdit;
