import styled from "styled-components";
import { getFontSize } from "@soluto-private/mx-asurion-ui-react";
import {
  Link as ReactRouterLink,
  Link as RouterLink,
  LinkProps,
} from "react-router-dom";

import { COLORS } from "../../constants";

export interface TextLinkProps extends LinkProps {
  withHoverEffect?: boolean;
}

export interface ImgLinkProps extends LinkProps {
  src?: string;
}

// * Values are taken directly from asurion.com
// * Specific type of Link, for footer.
const FooterLink = styled(RouterLink)<TextLinkProps>`
  &: hover {
    color: ${COLORS.WHITE};
  }
`;

const ReactLink = styled(ReactRouterLink)<TextLinkProps>`
  && {
    color: ${(props) =>
      props.withHoverEffect ? COLORS.LIGHT_GRAY : COLORS.WHITE};
    font-size: ${(props) =>
      props.withHoverEffect ? getFontSize(1) : getFontSize(1)};
    text-decoration: none;
  }

  &: hover {
    color: ${COLORS.WHITE};
  }
`;

const FooterImgLink = styled(RouterLink)<ImgLinkProps>`
  background: url(${(props) => props?.src || ""});
  color: ${COLORS.WHITE};
  background-size: contain;
  background-repeat: no-repeat;

  &: hover {
    color: ${COLORS.WHITE};
  }
`;

const UnorderedList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
  height: auto;
`;

export { FooterLink, UnorderedList, FooterImgLink, ReactLink };
