import { Button as SolutoButton } from "@soluto-private/mx-asurion-ui-react";
import styled from "styled-components";

import { GetFilter } from "../utils";

export const Button = styled(SolutoButton)`
  &&& {
    ${(props) =>
      props.variant === "outline" ? "background-color: transparent" : ""};
  }

  &&&:hover {
    ${(props) =>
      props.variant === "outline" ? "background-color: transparent" : ""};
  }

  &&& > svg {
    ${(props) => GetFilter(props.theme.secondary)?.filter};
  }
`;

export default Button;
