import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AsurionDoodleSpinner } from "@soluto-private/mx-asurion-ui-react";

import { SidebarContentWrapper, StyledButton } from "./HelpDocStyled";

import { Sidebar } from "../../lib/components";
import { useUser } from "../../lib/hooks";
import { CollapsiblePanel } from "../../lib/components/CollapsePanel";
import { SidebarProps } from "../../lib/components/Sidebar/types";

const isLoading = false;
interface IsidebarHelpMenu {
  docsResponse: any;
  isFetchingDocs: boolean;
}

export function SidebarHelpMenu({
  docsResponse,
  isFetchingDocs,
}: IsidebarHelpMenu) {
  const [useUserData] = useUser();
  const isAdmin = useUserData?.isAuthorizedByScope(`TEAM#aaim:product:manage`);
  const canModify = isAdmin;
  const [allDocContent, setAllDocContent] = useState<any>();
  const history = useHistory();
  const transformData = (data: any) => {
    const transformedData: TransformedDataInterface = {};
    data.forEach((value: any) => {
      const sideBarData: SideBarLinks = {
        title: value.sk.split("#")[1],
        url: `/help/docs/${encodeURIComponent(value.payload)}`,
        signedUrl: value.signedUrl,
        icon: "EmailEnvelope",
        key: value.sk.split("#")[1],
      };
      if (!transformedData[value.pk]) {
        transformedData[value.pk] = [sideBarData];
      } else {
        transformedData[value.pk].push(sideBarData);
      }
    });
    return transformedData;
  };
  const getUserRoles = () => {
    const userRoles = ["PartnerTemplate", "All"];
    useUserData?.isAuthorizedByScope("*:product:manage")
      ? userRoles.push("ProductOwnerRole")
      : "";
    useUserData?.isAuthorizedByScope("*:api:write")
      ? userRoles.push("ApiDeveloper")
      : "";
    useUserData?.isAuthorizedByScope("*:user:manage")
      ? userRoles.push("TeamOwnerRole")
      : "";
    useUserData?.isAuthorizedByScope("*:team:read")
      ? userRoles.push("ProductArchitectRole")
      : "";

    return userRoles;
  };

  const rolesData = getUserRoles();
  useEffect(() => {
    docsResponse && setAllDocContent(transformData(docsResponse));
    // eslint-disable-next-line
  }, [docsResponse]);

  interface SideBarLinks {
    title: string;
    url: string;
    signedUrl: string;
    icon: string;
    key: string;
  }
  interface TransformedDataInterface {
    [key: string]: SideBarLinks[];
  }

  const viewControl = () => {
    history.push(`/help/docs/new`);
  };
  return (
    <Sidebar open>
      {isFetchingDocs ? (
        <AsurionDoodleSpinner />
      ) : (
        <>
          <SidebarContentWrapper>
            {allDocContent &&
              Object.keys(allDocContent)?.map((key) => {
                return rolesData.includes(key) ? (
                  <CollapsiblePanel
                    keyName={key}
                    items={allDocContent[key] as SidebarProps}
                  />
                ) : (
                  ""
                );
              })}
          </SidebarContentWrapper>
          <SidebarContentWrapper>
            {canModify && (
              <StyledButton
                disabled={isLoading}
                variant="text"
                key="doc-btn-key"
                size="medium"
                onClick={() => viewControl()}
              >
                Create New Help Document
              </StyledButton>
            )}
          </SidebarContentWrapper>
        </>
      )}
    </Sidebar>
  );
}

export default SidebarHelpMenu;
