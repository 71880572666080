import styled from "styled-components";

export const DropdownTabMenuWrapper = styled.div``;

export const DropdownHeader = styled.div`
  height: 100%;
  display: flex;
`;

export const DropdownMenuContainer = styled.div`
  position: absolute;
`;
