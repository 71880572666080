import { Button, getFontSize } from "@soluto-private/mx-asurion-ui-react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { Dropdown } from "../../../lib/components";
import { COLORS } from "../../../constants";

export const Wrapper = styled.div<{ isMobile?: boolean }>`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0;
  min-height: 600px;
  padding: 0;

  .paragraph-title {
    font-weight: 800;
    font-size: ${getFontSize(2)};
  }

  & tr:nth-child(1) {
    th,
    td {
      color: ${(props) => props.theme.primary};
    }
  }

  // * Used for tables - will select all tr except the first one
  tr + tr {
    th,
    td {
      color: ${(props) => props.theme.primary};
    }
  }

  & {
    // * Used for table headers in redoc
    h1,
    h2,
    span {
      font-family: Apercu;
    }

    .operation-type + span {
      font-size: ${getFontSize(1)};
    }

    .-depth1 {
      font-size: ${getFontSize(2)};
      padding: ${getFontSize(1)};
    }

    h2,
    h5 {
      color: ${(props) => props.theme.textOnPrimary};
    }

    h5 span {
      color: ${(props) => props.theme.textOnPrimary};
    }

    .dropdown-selector-value {
      color: ${(props) => props.theme.textOnPrimary};
    }
  }

  .api-content {
    margin: 20px 0 0 10px;
  }

  .menu-content {
    padding-top: 120px;
  }

  .redoc-wrap {
    ${({ isMobile }) => (isMobile ? "padding-top: 100px" : "")};
    margin-top: -35px;
  }
`;

export const ApiDetailWrapper = styled.div`
  display: flex;
  position: relative;
  border-bottom: 4px solid ${COLORS.NEUTRAL_DEEPER};
  height: auto;
  width: 100%;
  z-index: 2;
`;

export const LoaderWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ApiDetailContainer = styled.div`
  display: flex;
  position: relative;
  height: auto;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: ${getFontSize(4)};
`;

export const FieldText = styled.span`
  font-weight: 800;
  font-size: ${getFontSize(2)};
`;

export const TagsWrapper = styled.div`
  display: flex;
  column-gap: 5px;
  margin: 10px 0;
`;

export const ApiActionButton = styled(Button)`
  right: ${getFontSize(4)};
  top: ${getFontSize(4)};
  margin: 0 5px;
  @media print {
    display: none;
  }
`;

export const ApiContainer = styled.div`
  display: flex;
  position: relative;
  width: 200px;
  z-index: 2;
  margin: ${getFontSize(2)};
  margin-left: 0px;
  flex-direction: column;
  justify-content: flex-start;
  @media print {
    display: none;
  }
`;

export const StyledDropdown = styled(Dropdown)`
  width: 200px;
  background-color: ${(props) => props.theme.primaryBackgroundColor};
  color: ${(props) => props.theme.textOnPrimary};
`;

export const DeleteButton = styled(Button)`
  border-color: ${COLORS.RED};
  background-color: ${COLORS.WHITE};
  color: ${COLORS.RED};
  &&& {
    &:hover {
      border-color: ${COLORS.WHITE};
      background-color: ${COLORS.RED};
      color: ${COLORS.WHITE};
    }
  }
`;

export const SpecDeleteButton = styled(DeleteButton)`
  margin: 5px 0;
  width: 200px;
`;

export const ActionsContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  right: 1.5rem;
  width: 500px;
  @media print {
    display: none;
  }
`;

export const ActionsContainerBreadCrumbs = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  width: 500px;
  @media print {
    display: none;
  }
`;

export const BreadcrumbLink = styled(Link)`
  color: ${(props) => props.theme.textOnPrimary};
  &&& {
    text-decoration: underline;
  }
  &&& {
    &&&:visited {
      color: ${(props) => props.theme.textOnPrimary};
    }
  }
`;

export const SpecContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${getFontSize(4)};
`;
