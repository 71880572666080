import React from "react";
import { ITabProps } from "./types";

export function Tab(props: ITabProps) {
  const { children } = props;
  return <div>{children}</div>;
}

// * Set displayName property for type checking
// * Reference: https://stackoverflow.com/a/43914310
Tab.displayName = "Tab";

export default Tab;
