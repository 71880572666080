import { Button, Text, TextField } from "@soluto-private/mx-asurion-ui-react";
import Modal from "styled-react-modal";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { scroller } from "react-scroll";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import * as Yup from "yup";
import { DOCUMENT_NAME_VALIDATION } from "../../../constants";
import {
  useCreateProductDoc,
  useDeleteProductDocument,
  useGetProductDocCDN,
} from "../../../lib/api";
import { DeleteForm } from "../../../lib/components";
import {
  RichTextViewer,
  RichTextEditor,
} from "../../../lib/components/RichTextEditor";
import DocumentSkeleton from "./DocumentSkeleton";

interface IProductDocument {
  productName: string;
  documentPath: string;
  documentTitle: string;
  canModifyDocument: boolean;
  signedUrl: string;
  setDocumentTitle: (prevTitle: string, newTitle: string) => void;
}

const DocumentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DocumentHeaderField = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 1px;
`;
const DocumentHeaderActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 5px;
`;

const DocumentFormSchema = Yup.object().shape({
  documentTitle: Yup.string()
    .min(3, "Document name required to be at least 3 characters long")
    .matches(
      DOCUMENT_NAME_VALIDATION,
      "Only alphanumeric characters,space,-,_,+ are allowed for the name."
    )
    .required("Document name required."),
});

const ProductDocContainer = styled.div`
  flex-grow: 0;
  overflow-x: wrap;
`;

function ProductDocument({
  productName,
  documentPath,
  documentTitle,
  canModifyDocument,
  setDocumentTitle,
  signedUrl,
}: IProductDocument) {
  const {
    response: productDocResponse,
    isLoading: productDocIsLoading,
    invokeApi: getProductDoc,
  } = useGetProductDocCDN();
  const {
    response: docUpdateResponse,
    isLoading: isDocUpdating,
    error: docUpdateError,
    invokeApi: updateProductDoc,
  } = useCreateProductDoc(productName);
  const {
    response: docDeleteResponse,
    isLoading: docDeleteIsLoading,
    invokeApi: deleteProductDoc,
  } = useDeleteProductDocument(productName, documentTitle);

  const firstRender = useRef(true);
  const ref: any = useRef<HTMLDivElement>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [onEditMode, setOnEditMode] = useState<boolean>(false);
  const history = useHistory();

  const [editedDocumentContent, setEditedDocumentContent] =
    useState<string>("");
  const [documentTitleHeader, setDocumentTitleHeader] =
    useState<string>(documentTitle);

  const formik = useFormik({
    initialValues: {
      documentTitle: documentTitleHeader,
    },
    onSubmit: () => {},
    validateOnBlur: true,
    validationSchema: DocumentFormSchema,
  });

  useEffect(() => {
    const getDoc = async () => {
      await getProductDoc(null, undefined, null, signedUrl);
    };
    getDoc();
    firstRender.current = false;
  }, []);
  // Subscribe to doc update events
  useEffect(() => {
    if (docUpdateResponse && !docUpdateError) {
      setOnEditMode(false);
      setDocumentTitle(documentTitleHeader, formik.values.documentTitle);
      setDocumentTitleHeader(formik.values.documentTitle);
      const getDoc = async () => {
        await getProductDoc(
          null,
          undefined,
          null,
          docUpdateResponse.signedUrl as string
        );
      };
      getDoc();
    } else if (docUpdateError) {
      throw new Error(`${docUpdateError}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docUpdateError, docUpdateResponse]);

  useEffect(() => {
    if (docDeleteResponse) {
      setIsModalOpen(false);
      toast.success(docDeleteResponse.message);
      history.push(`/products/${productName}`);
      document.location.reload();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [docDeleteResponse]);

  const handleOnDocumentSave = async () => {
    if (
      editedDocumentContent === "" &&
      formik.values.documentTitle === documentTitleHeader
    ) {
      setOnEditMode(false);
      return;
    }

    const payloadBuffer = new Blob([editedDocumentContent], {
      type: "text/plain",
    });
    const splittedName = documentPath.split("/") ?? [];
    const payloadName = splittedName[splittedName.length - 1];
    const formData = new FormData();
    formData.append("file", payloadBuffer, payloadName);
    formData.append("parent", "PRODUCT");
    formData.append("resource", productName);
    formData.append("updatedDocTitle", formik.values.documentTitle);
    formData.append("previousDocTitle", `DOC#${documentTitleHeader}`);
    await updateProductDoc(
      {
        resource: productName,
      },
      formData,
      {
        "Content-Type": "multipart/form-data",
      }
    );
  };

  const handleOnDocumentDelete = async () => {
    await deleteProductDoc();
  };

  return (
    <div ref={ref}>
      {onEditMode ? (
        <ProductDocContainer>
          <DocumentHeader>
            <div style={{ width: "30vw" }}>
              <TextField
                label="Document Name"
                value={formik.values.documentTitle}
                name="documentTitle"
                fieldStatus={formik.errors.documentTitle ? "error" : "default"}
                helperText={
                  formik.errors.documentTitle ? formik.errors.documentTitle : ""
                }
                onChange={(value) => {
                  formik.handleChange(value);
                }}
              />
            </div>
            <DocumentHeaderActionsWrapper>
              <Button
                size="small"
                onClick={() => handleOnDocumentSave()}
                disabled={isDocUpdating}
              >
                {isDocUpdating ? "Saving" : "Save document"}
              </Button>
              <Button
                variant="outline"
                size="small"
                onClick={() => {
                  setOnEditMode(false);
                  scroller.scrollTo(documentTitle, {
                    duration: 200,
                    smooth: true,
                    offset: -75,
                  });
                }}
              >
                Cancel
              </Button>
            </DocumentHeaderActionsWrapper>
          </DocumentHeader>
          <RichTextEditor
            content={editedDocumentContent}
            onChange={(value) => {
              setEditedDocumentContent(value);
            }}
          />
        </ProductDocContainer>
      ) : (
        <ProductDocContainer>
          <Modal
            isOpen={isModalOpen}
            onBackgroundClick={() => setIsModalOpen(false)}
          >
            <DeleteForm
              entity="Document"
              name={documentTitle}
              message="Are you sure you want delete this document? This process is
        irreversible."
              isDeleteLoading={docDeleteIsLoading}
              onDelete={handleOnDocumentDelete}
              onCancel={() =>
                setIsModalOpen((prevState) => {
                  return !prevState;
                })
              }
            />
          </Modal>
          <DocumentHeader>
            {isDocUpdating || productDocIsLoading || firstRender.current ? (
              <Skeleton count={1} height="2rem" width="300px" />
            ) : (
              <Text size={5} weight="heavy">
                {documentTitleHeader}
              </Text>
            )}
            <DocumentHeaderField>
              {canModifyDocument && (
                <Button
                  variant="outline"
                  size="small"
                  onClick={() => {
                    setOnEditMode(true);
                    setEditedDocumentContent(productDocResponse);
                  }}
                  disabled={productDocIsLoading}
                >
                  Edit document
                </Button>
              )}
              {canModifyDocument && (
                <Button
                  variant="outline"
                  size="small"
                  onClick={() => {
                    setIsModalOpen(true);
                  }}
                  disabled={productDocIsLoading}
                >
                  Delete document
                </Button>
              )}
            </DocumentHeaderField>
          </DocumentHeader>
          {isDocUpdating || productDocIsLoading || firstRender.current ? (
            <DocumentSkeleton />
          ) : (
            <RichTextViewer content={productDocResponse} />
          )}
        </ProductDocContainer>
      )}
    </div>
  );
}

export default ProductDocument;
