import styled, { css } from "styled-components";

const richTextElementsStyle = css`
  strong {
    font-weight: 700;
  }

  p {
    line-height: 1.3rem;
  }

  p span {
    line-height: normal;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: normal;
  }

  a {
    text-decoration: underline;
  }
  a:hover {
    color: ${({ theme }) => theme.secondaryHover};
  }

  .doc_section_title {
    font-weight: 700 !important;
    font-size: 1.5rem !important;
    margin-top: 8px !important;
    text-transform: uppercase !important;
    cursor: pointer;
  }

  .doc_section_title > *,
  .doc_section_title > *:hover {
    margin-bottom: 0;
    text-decoration: none;
    color: ${({ theme }) => theme.textOnPrimary};
  }

  .doc_section_title::after {
    content: "";
    width: 32px;
    border-bottom: 4px solid #999;
    transform: rotate(180deg);
    display: block;
    margin: 12px 0 16px;
  }
`;

export const RichTextEditorWrapper = styled.div`
  margin: 16px 0;
  a {
    color: red;
  }

  ${richTextElementsStyle}
`;

export const RichTextViewerContainer = styled.div`
  margin-top: 8px;
  & > *.sun-editor {
    border: none;
  }

  &
    > *.sun-editor
    > *.se-container
    > *.se-wrapper
    > *.se-wrapper-inner.se-wrapper-wysiwyg.sun-editor-editable {
    background-color: ${({ theme }) => theme.primaryBackgroundColor};
    color: ${({ theme }) => theme.textOnPrimary};
  }

  .sun-editor-editable hr {
    border-color: ${({ theme }) => theme.textOnPrimary};
  }

  .sun-editor-editable table th {
    background-color: ${({ theme }) => theme.primaryBackgroundColor};
    color: ${({ theme }) => theme.textOnPrimary};
  }

  .sun-editor-editable table thead {
    border-bottom-color: ${({ theme }) => theme.textOnPrimary};
  }

  & > *.sun-editor > *.se-container > .se-wrapper {
    user-select: text;
    -o-user-select: text;
    -moz-user-select: text;
    -khtml-user-select: text;
    -webkit-user-select: text;
    -ms-user-select: text;
  }

  a {
    color: ${({ theme }) => theme.textOnPrimary};
  }

  ${richTextElementsStyle}
`;
