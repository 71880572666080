import { Principal } from "@apim/lib-portal-auth";
import Amplify, { Auth, Hub } from "aws-amplify";
import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import axios from "axios";
import { ENTITY_TYPES } from "@apim/lib-portal-entities";

import {
  IDENTITY_POOL_ID,
  USER_POOL_ID,
  WEB_CLIENT_ID,
  OAUTH_DOMAIN,
  UserRole,
} from "../../../constants";
import { useLocalStorage } from "../localStorage.hook";
import { useApiCall } from "../api.hook";
import { UserOwnerShip } from "../../../models";

// * TODO adjust typings later
// import { User } from '../../../constants';

Amplify.configure({
  Auth: {
    identityPoolId: IDENTITY_POOL_ID,
    region: "us-east-1",
    identityPoolRegion: "us-east-1",
    userPoolId: USER_POOL_ID,
    userPoolWebClientId: WEB_CLIENT_ID,
    mandatorySignIn: true,
  },
  Storage: {
    AWSS3: {
      bucket: "apim-markdown-documentation",
      region: "us-east-1",
    },
  },
});

const oauth = {
  domain: OAUTH_DOMAIN,
  // * TODO if admin scope for cognito is required ..
  scope: ["email", "profile", "openid"],
  redirectSignIn: window.location.origin,
  redirectSignOut: window.location.origin,
  responseType: "code",
  options: {
    AdvancedSecurityDataCollectionFlag: false,
  },
};

Auth.configure({
  oauth,
});

// * https://blog.bitsrc.io/setting-up-axios-interceptors-for-all-http-calls-in-an-application-71bc2c636e4e
// * https://thedutchlab.com/blog/using-axios-interceptors-for-refreshing-your-api-token
export const setAuthToken = () => {
  async function getAuthSession() {
    return Auth.currentSession();
  }
  axios.interceptors.request.use(
    async (config) => {
      try {
        if (!config.baseURL?.includes("asurionapi.com")) {
          return config;
        }
        const authSession = await getAuthSession();
        const authToken = `Bearer ${authSession
          .getAccessToken()
          .getJwtToken()}`;
        const idToken = authSession.getIdToken().getJwtToken();
        const authHeaders =
          authToken && idToken
            ? { Authorization: authToken, idtoken: idToken }
            : {};
        // eslint-disable-next-line no-param-reassign
        config.headers = {
          ...config.headers,
          ...authHeaders,
        };
      } catch (err) {
        throw new Error(`interceptor error ${err}`);
      }

      return config;
    },
    (error) => Promise.reject(error)
  );
};

export type UserContextType = Principal | null | undefined;
export type UserSetStateType = Dispatch<SetStateAction<UserContextType>>;
export type IsSigninInSetStateType = Dispatch<SetStateAction<boolean>>;
type UserContextState = {
  user: UserContextType;
  setUser: UserSetStateType;
  isSigningIn: boolean;
  setIsSigningIn: IsSigninInSetStateType;
  signInError: string | null;
};

export const UserContext = createContext<UserContextState>({
  user: undefined,
  setUser: () => {},
  isSigningIn: false,
  setIsSigningIn: () => {},
  signInError: null,
});

type UserProviderProps = {
  children: JSX.Element;
};

export const UserOwnershipContext = createContext<UserOwnerShip>({});

export function UserProviderCustom(props: UserProviderProps) {
  const { children } = props;
  const { user } = useContext(UserContext);

  const [userId, setUserId] = useState("");

  const { invokeApi: getUserRoles, response: userRoleListResponse } =
    useApiCall<UserRole[]>(`/access/user/${userId.replace("^", "")}/roles`);

  const [userOwnership, setUserOwnership] = useState<UserOwnerShip>({
    products: [],
    teams: [],
  });

  useEffect(() => {
    if (user) {
      console.log(user);
      setUserId(user.getUserDetails().email.toLowerCase().replace("^", ""));
    }
  }, [user]);

  useEffect(() => {
    if (userId) {
      getUserRoles();
    }
    /* eslint-disable */
  }, [userId]);

  useEffect(() => {
    let teams: string[] = [];
    let products: string[] = [];
    let archProducts: string[] = [];
    if (userRoleListResponse) {
      userRoleListResponse?.forEach((obj: UserRole) => {
        if (obj.ownershipType === ENTITY_TYPES.PRODUCT) {
          if (!products.includes(obj.ownership)) {
            products.push(obj.ownership);
          }
          if (obj.role.includes("ProductArchitectRole")) {
            archProducts.push(obj.ownership);
          }
        } else if (
          obj.ownershipType === ENTITY_TYPES.TEAM &&
          user?.isInternal()
        ) {
          if (!teams.includes(obj.ownership)) {
            teams.push(obj.ownership);
          }
        }
      });

      setUserOwnership((prevState: UserOwnerShip) => {
        return {
          ...prevState,
          teams,
          products,
          archProducts,
        };
      });
    }
    /* eslint-disable */
  }, [userRoleListResponse]);

  const memoizedUserOwnershipData = useMemo(
    () => ({
      userOwnership,
      setUserOwnership,
    }),
    [userOwnership, setUserOwnership]
  );
  // Currently it is rendering four times, need to check why

  return (
    <UserOwnershipContext.Provider
      value={memoizedUserOwnershipData.userOwnership}
    >
      {children}
    </UserOwnershipContext.Provider>
  );
}

// * This is based from a project I've worked on before. Implementation might change because of SAML auth
// * but the idea is the same
export function UserProvider(props: UserProviderProps) {
  const { children } = props;
  const [user, setUser] = useState<UserContextType>(undefined);
  const [isSigningIn, setIsSigningIn] = useState(true);
  const [signInError, setSigninError] = useState("");
  const [, setPrincipal] = useLocalStorage<UserContextType>("principal", null);
  const memoizedUserData = useMemo(
    () => ({
      user,
      setUser,
      isSigningIn,
      setIsSigningIn,
      signInError,
    }),
    [user, setUser, isSigningIn, setIsSigningIn, signInError]
  );

  const setUserLocal = useCallback(async () => {
    try {
      const user = await Auth.currentSession();
      if (user) {
        const authToken = `Bearer ${user.getAccessToken().getJwtToken()}`;
        const idToken = user.getIdToken().getJwtToken();

        const principal: Principal =
          Principal.getPrincipalWithHeaders({
            authorization: authToken,
            idtoken: idToken,
          }) || null;
        if (principal) {
          setUser(principal);
          setPrincipal(principal);
          setAuthToken();
          setIsSigningIn(false);
        }
      }
    } catch {
      // * No user
      // eslint-disable-next-line no-console
      console.log("No user detected");
      setIsSigningIn(false);
    }
  }, [setPrincipal]);

  const listener = async (data: any) => {
    switch (data.payload.event) {
      case "signIn":
        setUserLocal();
        break;
      case "signUp":
        setUserLocal();
        break;
      case "oAuthSignOut":
        setUser(null);
        setPrincipal(null);
        break;
      // Added this event since. External users when signing out triggers this event.
      case "signOut":
        setUser(null);
        setPrincipal(null);
        setIsSigningIn(false);
        break;
      case "signIn_failure":
        setUser(null);
        setPrincipal(null);
        setIsSigningIn(false);
        setSigninError(data.payload.data.message);
        break;
      case "tokenRefresh":
        setUserLocal();
        break;
      case "tokenRefresh_failure":
        setUser(null);
        setPrincipal(null);
        break;
      case "configured":
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setUserLocal();
    // set this up only once during initial render
    Hub.listen("auth", listener);
  }, []);

  return (
    <UserContext.Provider value={memoizedUserData}>
      {children}
    </UserContext.Provider>
  );
}
