import styled from "styled-components";
import { INavbarMenuWrapperProps } from "../types";

export const NavbarWrapper = styled.nav<INavbarMenuWrapperProps>`
  height: 75px;
  display: flex;
  justify-content: space-between;
  z-index: 99999;
  color: ${({ theme }) => theme.textOnPrimary};
  align-items: center;
  background-color: ${({ theme }) => theme.primaryBackgroundColor};
  position: fixed;
  top: 0;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.textOnPrimary};
`;

export default NavbarWrapper;
