import { useEffect, useRef, useState } from "react";
import {
  Button,
  getFontSize,
  Text,
  RadioButton,
} from "@soluto-private/mx-asurion-ui-react";
import { ENTITY_TYPES } from "@apim/lib-portal-entities";
import styled from "styled-components";
import Modal from "styled-react-modal";
import { USER_TYPES } from "@apim/lib-portal-entities/lib/models/Foundation";
import { Link } from "react-router-dom";

import { COLORS, DropdownType, UserRole } from "../../../constants";
import { UsersSkeleton } from "./UsersSkeleton";
import {
  useAddUserRole,
  useDeleteUserRole,
  useGetProducts,
  useGetRoles,
  useGetTeams,
  useGetUserRole,
  useGetUsers,
} from "../../../lib/api";
import { AddRoleForm } from "../../../lib/components";
import { useDebounce } from "../../../lib/hooks";
import CreateAPIKeyForm from "../../../lib/components/Forms/CreateAPIKeyForm";
import AssignUserRoleForm from "../../../lib/components/Forms/AssignUserRoleForm";

const StyledTable = styled.table`
  border-collapse: collapse;
  margin: 25p 0;
  min-width: 100%;
`;

const StyledHeader = styled.th`
  background-color: ${(props) => props.theme.secondary};
  color: ${(props) => props.theme.textOnSecondary};
  text-align: left;
  padding: 12px 15px;
`;

const StyledCell = styled.td`
  padding: 12px 15px;
  color: ${COLORS.BLACK};
`;

const StyledCellAction = styled.td`
  padding: 12px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 10px;
`;

const StyledRow = styled.tr`
  border-bottom: 1px solid ${COLORS.NEUTRAL_BRIGHT};
  &:nth-of-type(even) {
    background-color: ${COLORS.NEUTRAL_BRIGHTEST};
  }
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  min-width: 400px;
  width: 60vw;
  min-height: 70vh;
  overflow-x: scroll;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 0;
  height: 80px;
`;

const Title = styled.h1`
  font-size: ${getFontSize(3)};
  font-family: Apercu;
`;

const DeleteButton = styled(Button)`
  border-color: ${COLORS.RED};
  background-color: ${COLORS.WHITE};
  color: ${COLORS.RED};
  &&& {
    &:hover {
      border-color: ${COLORS.WHITE};
      background-color: ${COLORS.RED};
      color: ${COLORS.WHITE};
    }
  }
`;

const RadioButtonsWrapper = styled.div`
  color: ${COLORS.BLACK};
`;

const LinkMain = styled(Link)`
  word-wrap: break-word;
  color: ${COLORS.BRAND_PURPLE_DEEP};
  &:hover {
    color: ${COLORS.BRAND_PURPLE_DEEPER};
  }
`;

const FilterWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 5px;
  margin: 40px 0 5px 0;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 5px;
  margin-bottom: 1rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
`;

interface UsersProps {
  onProfileViewClick: (pk: string) => void;
  searchTerm: string;
}

enum ModalView {
  MANAGE_ROLE = "manage role",
  CREATE_API_KEY = "create api key",
  ASSIGN_USER_ROLE = "assign user role",
}

export function Users({ onProfileViewClick, searchTerm }: UsersProps) {
  const firstRender = useRef(true);

  const debounceTerm = useDebounce(searchTerm, 500);

  const {
    response: getUsersResponse,
    isLoading: getUsersIsLoading,
    invokeApi: getUsers,
  } = useGetUsers();

  const [isExternal, setIsExternal] = useState(false);

  const { response: roleListResponse, invokeApi: getRoleList } = useGetRoles();
  const { response: teamListResponse, invokeApi: getTeamList } = useGetTeams();
  const { response: productListResponse, getProducts: getProductList } =
    useGetProducts();
  const [selectedUser, setSelectedUser] = useState("");
  const {
    response: roles,
    isLoading: rolesIsLoading,
    invokeApi: getUserRoles,
  } = useGetUserRole(selectedUser);
  const {
    response: addUserRoleResponse,
    isLoading: addRoleIsLoading,
    invokeApi: addUserRole,
  } = useAddUserRole(selectedUser);
  const {
    response: deleteRoleResponse,
    isLoading: deleteRoleIsLoading,
    invokeApi: deleteUserRole,
  } = useDeleteUserRole(selectedUser);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ownershipType, setOwnershipType] = useState(ENTITY_TYPES.PRODUCT);
  const [displayedRoles, setDisplayedRoles] = useState(roles);
  const [roleList, setRoleList] = useState<DropdownType[]>([]);
  const [ownershipList, setOwnershipList] = useState<DropdownType[]>([]);
  const [modalView, setModalView] = useState<ModalView>();

  useEffect(() => {
    if (debounceTerm) {
      getUsers({
        type: isExternal ? USER_TYPES.EXTERNAL : USER_TYPES.INTERNAL,
        name: debounceTerm,
      });
    } else {
      getUsers({
        type: isExternal ? USER_TYPES.EXTERNAL : USER_TYPES.INTERNAL,
      });
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [debounceTerm]);

  useEffect(() => {
    if (!firstRender.current) {
      getUsers({
        type: isExternal ? USER_TYPES.EXTERNAL : USER_TYPES.INTERNAL,
      });

      if (!isExternal && roleListResponse) {
        const transformedRoles = roleListResponse.map((item) => ({
          name: item.pk,
          value: item.pk,
        }));
        setRoleList(transformedRoles);
      } else {
        setRoleList([{ name: "PartnerTemplate", value: "PartnerTemplate" }]);
      }
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isExternal]);

  useEffect(() => {
    setDisplayedRoles(roles);
  }, [roles]);

  useEffect(() => {
    if (deleteRoleResponse || addUserRoleResponse || selectedUser) {
      getUserRoles();
    }
  }, [addUserRoleResponse, deleteRoleResponse, selectedUser, getUserRoles]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      getRoleList();
      getProductList({});
      getTeamList();
      return;
    }

    if (roleListResponse) {
      const transformedRoles = roleListResponse.map((item) => ({
        name: item.pk,
        value: item.pk,
      }));
      setRoleList(transformedRoles);
    }
    if (productListResponse && teamListResponse) {
      const transformedOwnership =
        ownershipType === ENTITY_TYPES.PRODUCT
          ? productListResponse.map((item) => ({
              name: item.pk as string,
              value: item.pk as string,
            }))
          : teamListResponse.map((item) => ({
              name: item.pk as string,
              value: item.pk as string,
            }));
      if (ownershipType === ENTITY_TYPES.PRODUCT) {
        transformedOwnership.splice(0, 0, {
          name: "Default",
          value: "Default",
        });
      }
      setOwnershipList(transformedOwnership);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [ownershipType, teamListResponse, roleListResponse, productListResponse]);

  useEffect(() => {
    const filteredRoles = roles?.filter(
      (role) => role.ownershipType === ownershipType
    );
    setDisplayedRoles(filteredRoles);
  }, [roles, ownershipType]);

  const openManageRoleModal = (pk: string) => {
    setIsModalOpen(true);
    setModalView(ModalView.MANAGE_ROLE);
    setSelectedUser(pk);
  };

  const openCreateAPIKey = () => {
    setIsModalOpen(true);
    setModalView(ModalView.CREATE_API_KEY);
  };

  const openAssignUserRole = () => {
    setIsModalOpen(true);
    setModalView(ModalView.ASSIGN_USER_ROLE);
  };

  const onDeleteUserRole = (role: UserRole) => {
    deleteUserRole(null, role);
  };

  const onAddUserRole = (e: any) => {
    const payload = { ...e, ownership: e.ownership, role: e.role };
    addUserRole(null, {
      ownership: payload.ownership,
      role: payload.role,
      ownershipType,
    });
  };

  return (
    <>
      <Wrapper>
        <RadioButtonsWrapper>
          <RadioButton
            label="Internal Users"
            name="userType"
            onChange={() => {
              setIsExternal(false);
            }}
            checked={!isExternal}
          />
          <RadioButton
            label="External Users"
            name="userType"
            onChange={() => {
              setIsExternal(true);
            }}
            checked={isExternal}
          />
        </RadioButtonsWrapper>
        <ButtonWrapper>
          <Button
            color="secondary"
            size="small"
            variant="default"
            onClick={() => openAssignUserRole()}
          >
            Assign User Role
          </Button>
          <Button
            color="secondary"
            size="small"
            variant="default"
            onClick={() => openCreateAPIKey()}
          >
            Create API Key
          </Button>
        </ButtonWrapper>
      </Wrapper>

      {getUsersIsLoading && (
        <UsersSkeleton headerWidth="85vw" dataWidth="20vw" />
      )}
      {!getUsersIsLoading && (
        <StyledTable>
          <thead>
            <tr>
              <StyledHeader>Email</StyledHeader>
              <StyledHeader>First name</StyledHeader>
              <StyledHeader>Last name</StyledHeader>
              <StyledHeader>Actions</StyledHeader>
            </tr>
          </thead>
          <tbody>
            {getUsersResponse?.map((item) => (
              <StyledRow key={item.pk}>
                <StyledCell>{item.pk}</StyledCell>
                <StyledCell>{item.firstname}</StyledCell>
                <StyledCell>{item.lastname}</StyledCell>
                <StyledCellAction>
                  <Button
                    color="secondary"
                    size="small"
                    variant="default"
                    onClick={() => onProfileViewClick(item.pk)}
                  >
                    View Profile
                  </Button>
                  <Button
                    color="secondary"
                    size="small"
                    variant="default"
                    onClick={() => openManageRoleModal(item.pk)}
                  >
                    Manage Role
                  </Button>
                </StyledCellAction>
              </StyledRow>
            ))}
          </tbody>
        </StyledTable>
      )}
      <Modal
        isOpen={isModalOpen}
        onBackgroundClick={() => setIsModalOpen(false)}
      >
        {modalView === ModalView.CREATE_API_KEY && (
          <CreateAPIKeyForm setIsModalOpen={setIsModalOpen} />
        )}
        {modalView === ModalView.ASSIGN_USER_ROLE && (
          <AssignUserRoleForm setIsModalOpen={setIsModalOpen} />
        )}
        {modalView === ModalView.MANAGE_ROLE && (
          <StyledContainer>
            <Header>
              <Title>
                Roles of
                {selectedUser}
              </Title>
              <Button variant="flat" onClick={() => setIsModalOpen(false)}>
                Cancel
              </Button>
            </Header>
            <AddRoleForm
              handleSubmit={onAddUserRole}
              ownerships={ownershipList}
              roles={roleList}
              addIsLoading={addRoleIsLoading}
            />
            <FilterWrapper>
              <Text>Ownership: </Text>
              <RadioButton
                label="Product"
                name="ownershipType"
                onChange={() => setOwnershipType(ENTITY_TYPES.PRODUCT)}
                checked={ownershipType === ENTITY_TYPES.PRODUCT}
              />
              {!isExternal && (
                <RadioButton
                  label="Team"
                  name="ownershipType"
                  onChange={() => setOwnershipType(ENTITY_TYPES.TEAM)}
                  value="false"
                  checked={ownershipType === ENTITY_TYPES.TEAM}
                />
              )}
            </FilterWrapper>
            {rolesIsLoading && (
              <UsersSkeleton headerWidth="60vw" dataWidth="13vw" />
            )}
            {!rolesIsLoading && (
              <StyledTable>
                <thead>
                  <tr>
                    <StyledHeader>Ownership</StyledHeader>
                    <StyledHeader>Ownership Type</StyledHeader>
                    <StyledHeader>Role</StyledHeader>
                    <StyledHeader>Actions</StyledHeader>
                  </tr>
                </thead>
                <tbody>
                  {displayedRoles?.map((role) => (
                    <StyledRow key={`${role.pk}-${role.sk}`}>
                      <StyledCell>
                        {role.ownershipType === ENTITY_TYPES.PRODUCT ? (
                          <LinkMain to={`/products/${role.ownership}`}>
                            {role.ownership}
                          </LinkMain>
                        ) : (
                          <LinkMain to={`/teams/${role.ownership}`}>
                            {role.ownership}
                          </LinkMain>
                        )}
                      </StyledCell>
                      <StyledCell>{role.ownershipType}</StyledCell>
                      <StyledCell>{role.role}</StyledCell>
                      <StyledCell>
                        <DeleteButton
                          color="secondary"
                          variant="outline"
                          size="small"
                          iconSrc="Bin"
                          iconSide="right"
                          onClick={() => onDeleteUserRole(role)}
                          disabled={deleteRoleIsLoading}
                        >
                          Remove
                        </DeleteButton>
                      </StyledCell>
                    </StyledRow>
                  ))}
                </tbody>
              </StyledTable>
            )}
          </StyledContainer>
        )}
      </Modal>
    </>
  );
}

export default Users;
