import styled from "styled-components";
import { getFontSize } from "@soluto-private/mx-asurion-ui-react";

import { COLORS, ASURION_COLORS } from "../../../constants";
import { GetFilter } from "../../../lib/utils";

export const BannerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BannerImage = styled.img`
  max-width: 500px;
  width: 100%;
  padding-left: 40px;
  /* filter: invert(
    ${(props) => (props.theme.textOnPrimary === ASURION_COLORS.white ? 1 : 0)}
  ); */
  ${(props) => {
    const filter = GetFilter(props.theme.textOnPrimary);
    return filter?.filter;
  }}
`;

export const BannerHeaderText = styled.h1`
  font-size: ${getFontSize(5)};
  text-align: left;
`;

export const BannerSubHeaderText = styled.h2`
  font-size: ${getFontSize(4)};
  color: ${COLORS.NEUTRAL_DEEPER};
`;

export const BannerLinkWrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  padding-top: 40px;
`;
