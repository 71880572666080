import { colors, Icon, IconSrc } from "@soluto-private/mx-asurion-ui-react";
import React from "react";
import styled from "styled-components";

export interface ITabButtonProps extends React.HTMLProps<HTMLButtonElement> {
  prependIcon?: IconSrc;
  appendIcon?: IconSrc;
  active?: boolean;
  disabled?: boolean;
}

const TabButtonBase = styled.button`
  padding: 12px 16px;
  border: none;
  border-bottom: 4px solid
    ${(props: ITabButtonProps) =>
      props.active ? colors.black : colors.neutralBrightest};
  min-width: 4px;
  font-family: Apercu;
  cursor: pointer;
  color: ${({ theme }) => theme.textOnPrimary};
  background-color: ${({ theme }) => theme.primaryBackgroundColor};
  &:hover {
    border-bottom: 4px solid ${({ theme }) => theme.textOnPrimary};
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

const TabButtonIcon = styled(Icon)`
  margin: 0 6px;
  filter: ${({ theme }) =>
    theme.textOnPrimary === "#ffffff" ? "invert(1)" : "invert(0)"};
`;

const TabButtonContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  font-size: 16px;
  color: ${({ theme }) => theme.textOnPrimary};
  background-color: ${({ theme }) => theme.primaryBackgroundColor};
`;

export function TabButton(props: ITabButtonProps): JSX.Element {
  const {
    onClick,
    active,
    className,
    id,
    disabled,
    prependIcon,
    children,
    appendIcon,
  } = props;
  return (
    <TabButtonBase
      onClick={onClick}
      active={active}
      className={className}
      id={id}
      disabled={disabled}
    >
      <TabButtonContentWrapper>
        {prependIcon ? <TabButtonIcon src={prependIcon} /> : null}
        {children}
        {appendIcon ? <TabButtonIcon src={appendIcon} /> : null}
      </TabButtonContentWrapper>
    </TabButtonBase>
  );
}

export default TabButton;
