import { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import {
  getFontSize,
  Button,
  TextField,
  Text,
  AsurionDoodleSpinner,
} from "@soluto-private/mx-asurion-ui-react";
import * as Yup from "yup";
import { useFormik, FormikValues } from "formik";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";

import { toast } from "react-toastify";
import { SSO_URL, ASURION_LOGO_PATH, ASURION_COLORS } from "../constants";
import { useMedia, useUser } from "../lib/hooks";
import { GetFilter } from "../lib/utils";

const ssoLogin = async () => {
  try {
    window.location.assign(SSO_URL);
    // await api.post('/api/auth/login/', { username, password })
    // return;
  } catch (e: any) {
    throw new Error(`Error: ${e.message}`);
  }
};

const LoginSchema = Yup.object().shape({
  username: Yup.string().required("Required").email(),
  password: Yup.string().required("Required"),
});

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  margin: 0;
  margin-top: ${getFontSize(5)};
  min-height: 600px;
  padding: 0;

  & th:nth-child(1) {
    color: ${(props) => props.theme.textOnSecondary};
  }

  & th:nth-child(2) {
    color: ${(props) => props.theme.textOnPrimary};
  }
`;

const CredentialWrapper = styled.div<{ borderColor: string; isLarge: boolean }>`
  border-radius: 5px;
  border: 1px solid;
  border-color: ${ASURION_COLORS.neutralBright};
  width: ${(props) => (props.isLarge ? "60%" : "480px")};
  height: ${(props) => (props.isLarge ? "480px" : "80%")};
  margin: 2em;
  display: flex;
  border-color: ${(props) => props.color};
`;

const AuthSelectionWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.secondary};
  padding: ${getFontSize(1)};
`;

const FormWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  margin: auto;
  padding: ${getFontSize(2)};
  flex-direction: column;
`;

const NavbarLogoContainer = styled.img`
  padding: 0 20px;
  margin: ${getFontSize(1)};
  height: ${getFontSize(5)};
  color: ${({ theme }) => theme.textOnPrimary};
  filter: ${() => {
    const filter = GetFilter("#0000");
    return filter?.filter;
  }};
`;

const LoginButton = styled(Button)`
  margin: ${getFontSize(2)};
`;

const SignUpButton = styled(Button)`
  margin: ${getFontSize(2)};
`;

const SSOButton = styled(Button)`
  margin: ${getFontSize(2)};
  color: ${({ theme }) => theme.textOnSecondary};
  &&:hover {
    color: black;
  }
`;

const UsernameTextfieldWrapper = styled.div`
  margin: ${getFontSize(1)} ${getFontSize(2)};
`;

const PasswordTextfieldWrapper = styled.div`
  margin: ${getFontSize(1)} ${getFontSize(2)};
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export function LoginPage() {
  const themeContext = useContext(ThemeContext);
  const [, , isSigningIn, setIsSigningIn] = useUser();
  const breakpoints = useMedia();
  const history = useHistory();

  const goToHomepage = () => {
    history.push("/");
  };

  const goToForgotPassword = () => {
    history.push("/forgot-password");
  };

  const login = async (values: FormikValues) => {
    try {
      setIsSigningIn(true);
      const { username, password } = values;
      const user = await Auth.signIn(username, password);
      if (user) {
        goToHomepage();
      }
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    onSubmit: login,
    validationSchema: LoginSchema,
    validateOnBlur: true,
  });

  // * https://github.com/Redocly/redoc/issues/560
  return (
    <Wrapper>
      <CredentialWrapper
        borderColor={themeContext.primary}
        isLarge={breakpoints.LG}
      >
        <FormWrapper>
          <StyledForm onSubmit={formik.handleSubmit}>
            <UsernameTextfieldWrapper>
              <TextField
                label="Email"
                name="username"
                defaultValue={formik.values.username}
                fieldStatus={formik.errors.username ? "error" : "default"}
                helperText={
                  formik.errors.username
                    ? (formik.errors.username as string)
                    : ""
                }
                onChange={(value) => {
                  formik.handleChange(value);
                }}
              />
            </UsernameTextfieldWrapper>
            <PasswordTextfieldWrapper>
              <TextField
                label="Password"
                type="password"
                name="password"
                fieldStatus={formik.errors.password ? "error" : "default"}
                helperText={
                  formik.errors.password
                    ? (formik.errors.password as string)
                    : ""
                }
                onChange={(value) => {
                  formik.handleChange(value);
                }}
                defaultValue={formik.values.password}
              />
            </PasswordTextfieldWrapper>

            <LoginButton type="submit" disabled={isSigningIn} color="secondary">
              <>
                <Text>Login</Text>
                {isSigningIn && <AsurionDoodleSpinner width="20px" />}
              </>
            </LoginButton>
          </StyledForm>
          <SignUpButton
            color="secondary"
            variant="flat"
            onClick={goToForgotPassword}
          >
            Forgot Password
          </SignUpButton>
        </FormWrapper>
        <AuthSelectionWrapper>
          <NavbarLogoContainer src={ASURION_LOGO_PATH} />
          <SSOButton
            size="small"
            variant="flat"
            color="secondary"
            onClick={ssoLogin}
          >
            Sign in via SSO
          </SSOButton>
        </AuthSelectionWrapper>
      </CredentialWrapper>
    </Wrapper>
  );
}

export default LoginPage;
