import { ENTITY_TYPES, Team } from "@apim/lib-portal-entities";
import {
  AsurionDoodleSpinner,
  Button,
} from "@soluto-private/mx-asurion-ui-react";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import styled, { useTheme } from "styled-components";
import Modal from "styled-react-modal";

import { useCreateTeam, useGetTeams } from "../../../lib/api";
import { TeamForm } from "../../../lib/components";
import { useDebounce } from "../../../lib/hooks";

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const columns = [
  {
    name: "Team Name",
    selector: (row: Team) => row.pk,
    sortable: true,
    width: "20vw",
  },
  {
    name: "Owner",
    selector: (row: Team) => row.owner,
    width: "20vw",
  },
  {
    name: "Description",
    selector: (row: Team) => row.description,
    width: "50vw",
  },
];

interface TeamProps {
  searchTerm: string;
}

export function Teams({ searchTerm }: TeamProps) {
  const {
    response: getTeamsResponse,
    isLoading: isGetTeamsLoading,
    invokeApi: getTeams,
  } = useGetTeams();
  const theme = useTheme();

  const debounceTerm = useDebounce(searchTerm, 500);

  const {
    response: createTeamResponse,
    isLoading: isCreateTeamLoading,
    invokeApi: createTeam,
  } = useCreateTeam();
  const [teams, setTeams] = useState<Team[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (debounceTerm) {
      getTeams({ name: debounceTerm });
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [debounceTerm]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    getTeams();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [createTeamResponse]);

  useEffect(() => {
    if (getTeamsResponse) {
      setTeams(getTeamsResponse);
    }
  }, [getTeamsResponse]);

  const onSubmitForm = (e: any) => {
    const team = {
      pk: e.teamName,
      description: e.description,
      status: e.status,
      owner: e.owner,
      type: ENTITY_TYPES.TEAM,
      apiCount: 0,
    };
    createTeam(null, team, true);
  };

  return (
    <div>
      <ActionWrapper>
        <Button
          color="secondary"
          iconSide="right"
          iconSrc="PlusAdd"
          onClick={toggleModal}
          size="small"
          variant="default"
        >
          Create team
        </Button>
      </ActionWrapper>
      <DataTable
        columns={columns}
        data={teams}
        customStyles={{
          rows: {
            style: {
              backgroundColor: theme.secondaryBackgroundColor,
              color: theme.invertedTextOnSecondary,
            },
          },
          table: {
            style: {
              backgroundColor: theme.secondaryBackgroundColor,
              color: theme.invertedTextOnSecondary,
            },
          },
          cells: {
            style: {
              backgroundColor: theme.secondaryBackgroundColor,
              color: theme.invertedTextOnSecondary,
            },
          },
          headCells: {
            style: {
              backgroundColor: theme.secondaryBackgroundColor,
              color: theme.invertedTextOnSecondary,
            },
          },
          tableWrapper: {
            style: {
              backgroundColor: theme.secondaryBackgroundColor,
              color: theme.invertedTextOnSecondary,
            },
          },
          head: {
            style: {
              backgroundColor: theme.secondaryBackgroundColor,
              color: theme.invertedTextOnSecondary,
            },
          },
          headRow: {
            style: {
              backgroundColor: theme.secondaryBackgroundColor,
              color: theme.invertedTextOnSecondary,
            },
          },
          header: {
            style: {
              backgroundColor: theme.secondaryBackgroundColor,
              color: theme.invertedTextOnSecondary,
            },
          },
          noData: {
            style: {
              backgroundColor: theme.secondaryBackgroundColor,
              color: theme.invertedTextOnSecondary,
            },
          },
        }}
        progressPending={isGetTeamsLoading}
        progressComponent={<AsurionDoodleSpinner width="80px" />}
      />
      <Modal isOpen={isModalOpen} onBackgroundClick={toggleModal}>
        <TeamForm
          onCancel={toggleModal}
          onSubmit={onSubmitForm}
          isLoading={isCreateTeamLoading}
        />
      </Modal>
    </div>
  );
}

export default Teams;
