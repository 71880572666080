import styled from "styled-components";
import { getFontSize } from "@soluto-private/mx-asurion-ui-react";
import { NavLink } from "react-router-dom";
import { COLORS } from "../../../../constants";
import { INavbarLinkItemProps } from "../types";

export const NavbarLink = styled(NavLink)<INavbarLinkItemProps>`
  margin: 7px;
  font-size: ${getFontSize(2)};
  color: ${(props) => props.theme.textOnPrimary};
  text-decoration: none;
  &:hover {
    color: ${COLORS.NEUTRAL_DEEP};
    transition: all 0.2s ease-out;
  }
  ${(props) =>
    props.isSmall &&
    `
        text-align: center;
        width: 100%;
        display: table;
        &:hover {
            border-radius: 0;
            transition: all 0.2s ease-out;
        }
    `}
`;

export default NavbarLink;
