import styled from "styled-components";
import { Link, getFontSize } from "@soluto-private/mx-asurion-ui-react";

import { COLORS } from "../../../constants";
import {
  UnorderedList,
  FooterLink,
  FooterImgLink,
  TextLinkProps,
  ImgLinkProps,
} from "../Link";
import { DivProps } from "./types";

export const StyledUnorderedList = styled((props) => (
  <UnorderedList {...props} />
))`
  && {
    flex-grow: 1;
    width: 33%;
  }
`;

export const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${COLORS.BLACK};
  left: 0%;
  right: 0%;
  bottom: 0%;
`;

export const StyledLink = styled(Link)`
  max-width: 7.5rem;
`;

export const ContentWrapper = styled.div`
  max-width: 1568px;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 0;
  width: 100%;
  height: auto;
`;

export const FirstRowContentWrapper = styled.div<DivProps>`
  && {
    display: flex;
    min-width: 306px;
    width: ${(props) => (props.isMobile || props.isSmall ? "100%" : "33%")};
  }
`;

// * `filter: invert(1);` turns the black color to white for the svg
export const AsurionLogo = styled.img<Partial<DivProps>>`
  filter: invert(1);
  width: ${(props) => (props.isLarge ? "140px" : "120px")};
  margin-bottom: ${(props) => (props.isLarge ? "0" : "20px")};
  height: 120px;
`;

export const UnorderedListWrapper = styled.div<DivProps>`
  margin: 0;
  display: flex;
  min-height: 325px;
  width: ${(props) => (props.isMedium ? "100%" : "50%")};
  flex-direction: ${(props) => (props.isSmall ? "column" : "row")};
`;

export const UnorderedListContainer = styled.div<DivProps>`
  display: flex;
  height: auto;
  padding: 85px 0;
  width: 100%;
  flex-direction: ${(props) => (props.isMedium ? "column" : "row")};
`;

export const FirstRow = styled.div<DivProps>`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
  flex-grow: 1;
  justify-content: space-between;
  align-items: ${(props) => (props.isMobile ? "flex-start" : "center")};
  width: 100%;
`;

export const CertificateWrapper = styled.div<DivProps>`
  display: flex;
  flex-grow: 2;
  flex-direction: ${(props) =>
    props?.isSmall || props.isMobile ? "column" : "row"};
`;

// * 992px is not part of Asurion global design system
export const SocialWrapper = styled.div<DivProps>`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;

  @media screen and (max-width: 992px) {
    & {
      justify-content: flex-start;
      height: 92px;
    }
  }
`;

export const MiddleRow = styled.div`
  display: flex;
  width: 100%;
`;

// ! Cannot extend this component using `styled(UnorderedList)`
// ! For more info read: https://github.com/styled-components/styled-components/issues/1449
export const StyledFooterLink = styled(
  ({ withHoverEffect, ...rest }: TextLinkProps) => <FooterLink {...rest} />
)`
  && {
    color: ${(props) =>
      props.withHoverEffect ? COLORS.LIGHT_GRAY : COLORS.WHITE};
    font-size: ${(props) =>
      props.withHoverEffect ? getFontSize(1) : getFontSize(2)};
    text-decoration: none;
  }
  margin: 10px 0px;
  &&: hover {
    filter: brightness(0) invert(1);
  }
  color: ${COLORS.WHITE};
`;

export const CareerIcon = styled.img`
  && {
    display: inline-block;
    line-height: 1;
    font-weight: 400;
    font-style: normal;
    text-decoration: inherit;
    margin-top: -5px;
    text-transform: none;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 12px;
    height: 12px;
    font-size: 12px;
    vertical-align: top;
    text-align: center;
    transition: color 0.1s ease-in;
    filter: inherit;
  }
`;

export const StyledMetaLink = styled((props: TextLinkProps & DivProps) => (
  <FooterLink {...props} />
))`
  &&& {
    font-size: ${getFontSize(1)};
    display: inline-block;
    color: ${COLORS.WHITE};

    &:after {
      content: "|";
      margin: 0 0.5rem;
    }

    &:last-child:after {
      content: "";
    }

    @media screen and (max-width: 992px) {
      & {
        margin-top: 50px;
      }
    }
  }
`;

export const StyledMetaAnchorLink = styled.a`
  &&& {
    font-size: ${getFontSize(1)};
    display: inline-block;
    color: ${COLORS.WHITE};

    &:after {
      content: "|";
      margin: 0 0.5rem;
    }

    &:last-child:after {
      content: "";
    }

    @media screen and (max-width: 992px) {
      & {
        margin-top: 50px;
      }
    }
  }
`;
export const BottomRowTopContent = styled.div<DivProps>`
  display: flex;
  flex-direction: ${(props) => (!props.isLarge ? "column-reverse" : "row")};
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
`;

export const CopyrightWrapper = styled.div<DivProps>`
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  color: ${COLORS.WHITE};
  align-items: center;
`;

export const MetadataWrapper = styled.div<DivProps>`
  display: flex;
  flex-grow: 2;
  flex-wrap: wrap;
  justify-content: flex-end;

  @media screen and (max-width: 992px) {
    & {
      justify-content: flex-start;
    }
  }
`;

export const BottomRowBottomContent = styled.div<DivProps>`
  display: flex;
  width: 100%;
  height: 50%;
  margin: 50px 0 80px 0;
  padding: 0;
  flex-direction: row;

  @media screen and (max-width: 992px) {
    & {
      flex-direction: column;
    }
  }
`;

export const CertRow = styled.div`
  width: auto;
  display: flex;
`;

export const TrustEImgLink = styled(
  ({
    isLarge,
    isMedium,
    isMobile,
    isSmall,
    ...rest
  }: ImgLinkProps & DivProps) => <FooterImgLink {...rest} />
)`
  background-size: contain;
  width: ${(props) => (props.isLarge ? "126px" : "88px")};
  margin-right: 24px;
  height: ${(props) => (props.isLarge ? getFontSize(6) : "1.75rem")};
  background-repeat: no-repeat;
`;

export const BBBEImgLink = styled(
  ({
    isLarge,
    isMedium,
    isMobile,
    isSmall,
    ...rest
  }: ImgLinkProps & DivProps) => <FooterImgLink {...rest} />
)`
  && {
    background-size: ${(props) =>
      props.isLarge ? "220px auto" : "150px auto"};
    width: ${(props) => (props.isLarge ? "106px" : "88px")};
    height: ${getFontSize(6)};
    margin-top: ${(props) =>
      props.isSmall || props.isMobile ? "10px" : "0px"};
    background-repeat: no-repeat;
  }

  &: hover {
    background-size: ${(props) =>
      props.isSmall || props.isMobile ? "200%" : "250%"};
    background-position: 95% 0px;
  }
`;

export const WiseImgLink = styled(
  ({
    isLarge,
    isMedium,
    isMobile,
    isSmall,
    ...rest
  }: ImgLinkProps & DivProps) => <FooterImgLink {...rest} />
)`
  background-size: contain;
  width: 126px;
  height: ${(props) => (props.isLarge ? getFontSize(6) : "32px")};
  margin-left: 10px;
  margin-top: ${(props) => (props.isSmall || props.isMobile ? "10px" : "0px")};
  background-repeat: no-repeat;
`;

export const SocialImgLink = styled((props: ImgLinkProps) => (
  <FooterImgLink {...props} />
))`
  && {
    width: 20px;
    color: transparent;
    display: inline-block;
    text-decoration: none;
    height: 20px;
    background: url(${(props) => props.src || ""}) no-repeat;
    margin: 0 0 0 2rem;
  }

  &.facebookIcon {
    background-position: -160px -10px;

    @media screen and (max-width: 992px) {
      & {
        margin-left: 0px;
      }
    }
  }

  &.facebookIcon:hover {
    background-position: -160px -60px;
  }

  &.twitterIcon {
    background-position: -110px -10px;
  }

  &.twitterIcon:hover {
    background-position: -110px -60px;
  }

  &.youtubeIcon {
    background-position: -60px -10px;
  }

  &.youtubeIcon:hover {
    background-position: -60px -60px;
  }

  &.linkedinIcon {
    background-position: -10px -10px;
  }

  &.linkedinIcon:hover {
    background-position: -10px -60px;
  }
`;

export const BottomRow = styled.div`
  display: flex;
  flex-direction: column;
`;
