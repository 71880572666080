import { IAvatar } from "./types";
import {
  AvatarImage,
  LargeAvatarContainer,
  MediumAvatarContainer,
  SmallAvatarContainer,
  XSmallAvatarContainer,
} from "./elements";

export function Avatar(props: IAvatar) {
  const { avatarSize: avatarPropSize, imageUrl } = props;
  const avatarSize: IAvatar["avatarSize"] = avatarPropSize ?? "xs";
  let SelectedAvatar: any;

  switch (avatarSize) {
    case "xs":
      SelectedAvatar = XSmallAvatarContainer;
      break;

    case "sm":
      SelectedAvatar = SmallAvatarContainer;
      break;
    case "md":
      SelectedAvatar = MediumAvatarContainer;
      break;
    case "lg":
      SelectedAvatar = LargeAvatarContainer;
      break;

    default:
      break;
  }

  return (
    <SelectedAvatar {...props}>
      <AvatarImage src={imageUrl} />
    </SelectedAvatar>
  );
}

export default Avatar;
