import styled, { useTheme } from "styled-components";
import {
  getFontSize,
  Button,
  AsurionDoodleSpinner,
  Text,
} from "@soluto-private/mx-asurion-ui-react";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.secondaryBackgroundColor};
  border-radius: 10px;
  padding: 20px;
  min-width: 400px;
  width: 40vw;
  height: auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 0;
  height: 80px;
`;

const Body = styled.div`
  flex-grow: 0;
  height: auto;
  padding: 1em;
`;

const Title = styled.h1`
  font-size: ${getFontSize(3)};
  font-family: Apercu;
  color: ${(props) => props.theme.invertedTextOnSecondary};
`;

const Row = styled.div<{ flexAlign?: "right" | "left" }>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: ${({ flexAlign }) =>
    flexAlign === "right" ? "flex-end" : "flex-start"};
  margin: 20px 0px;
  padding: 0px;
`;

const SubmitButton = styled(Button)`
  align-self: flex-end;
`;

interface DeleteFormProps {
  entity: string;
  name: string;
  message: string;
  isDeleteLoading: boolean;
  onDelete: () => void;
  onCancel: () => void;
}

export function DeleteForm(props: DeleteFormProps) {
  const { entity, name, message, isDeleteLoading, onDelete, onCancel } = props;
  const theme = useTheme();

  return (
    <StyledContainer>
      <Header>
        <Title>
          Delete {entity} {name}
        </Title>
        <Button variant="flat" onClick={onCancel}>
          Cancel
        </Button>
      </Header>
      <Body>
        <Row>
          <Text color={theme.textOnPrimary}>{message}</Text>
        </Row>
        <Row flexAlign="right">
          <SubmitButton
            color="secondary"
            onClick={onDelete}
            disabled={isDeleteLoading}
          >
            <>
              <Text>Delete {name}</Text>
              {isDeleteLoading && <AsurionDoodleSpinner width="20px" />}
            </>
          </SubmitButton>
        </Row>
      </Body>
    </StyledContainer>
  );
}

export default DeleteForm;
