import { useContext } from "react";
import UserTab from "./UserTab";
import { useGetUsersByProduct } from "../../../lib/api";
import {
  ProductContentSection,
  ProductHeader,
  ProductLinkedApis,
  SectionContentDivider,
} from "./elements";
// import ProductDocument from "./ProductDocument";
import ProductAPI from "./ProductAPI";
import { useUser, UserOwnershipContext } from "../../../lib/hooks";
import ProductDocument from "./ProductDocument";

interface IProductContent {
  product: any;
  docs: any[];
  apis: any[];
  canModifyProduct: boolean;
  isDeletingProduct: boolean;
  userTabRefresh: boolean;
  setDocumentTitle: (prevTitle: string, newTitle: string) => void;
  setProduct: (product: any) => void;
  deleteProduct: (params?: any) => void;
  onNavClick: () => void;
}

function ProductContent({
  setDocumentTitle,
  setProduct,
  deleteProduct,
  isDeletingProduct,
  product,
  userTabRefresh,
  docs,
  apis,
  canModifyProduct,
  onNavClick,
}: IProductContent) {
  const { invokeApi: getProductUsers } = useGetUsersByProduct(
    product ? product.pk : null
  );
  const [user] = useUser();
  const userOwnership = useContext(UserOwnershipContext);

  return (
    <>
      <ProductContentSection key="overview" name="overview" id="overview">
        {product && (
          <>
            <ProductHeader
              pk={product.pk}
              product={product}
              status={product.status}
              isPublic={product.isPublic}
              description={product.description}
              canModifyProduct={canModifyProduct}
              setProduct={setProduct}
              getProductUsers={getProductUsers}
              deleteProduct={deleteProduct}
              isDeletingProduct={isDeletingProduct}
            />
            {user?.isInternal() && (
              <div style={{ marginBottom: "70px" }}>
                <SectionContentDivider />
                <UserTab
                  userTabRefresh={userTabRefresh}
                  product={product}
                  canModify={
                    canModifyProduct &&
                    !userOwnership.archProducts?.includes(product.pk)
                  }
                />
              </div>
            )}
          </>
        )}
      </ProductContentSection>
      <section id="documents">
        {docs.map((value) => {
          return (
            <ProductContentSection
              key={value.title}
              name={value.title}
              id={value.title}
            >
              <ProductDocument
                setDocumentTitle={setDocumentTitle}
                productName={product.pk}
                documentPath={value.documentPath}
                signedUrl={value.signedUrl}
                documentTitle={value.title}
                canModifyDocument={canModifyProduct}
              />
            </ProductContentSection>
          );
        })}
      </section>
      {product && (
        <ProductContentSection key="apis" name="apis" id="apis">
          <ProductLinkedApis
            pk={product.pk}
            apis={apis}
            canModify={canModifyProduct}
          />
        </ProductContentSection>
      )}
      <section id="apis">
        {apis.map((value) => {
          return (
            <ProductContentSection
              key={value.title}
              name={value.title}
              id={value.title}
            >
              <ProductAPI
                api={value}
                productName={product.pk}
                onNavClick={() => {
                  onNavClick();
                }}
              />
            </ProductContentSection>
          );
        })}
      </section>
    </>
  );
}

export default ProductContent;
