import styled from "styled-components";
import { Container } from "react-grid-system";

const AboutUsWrapper = styled(Container)`
  padding-top: 32px;
  min-width: 75vw;
  margin-bottom: 55px;
  font-family: "Segoe UI Web (West European)", Segoe UI, -apple-system,
    BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  display: flex;
  max-width: 800px !important;
  text-align: justify;
`;

const AboutUsFigureWrapper = styled.figure`
  flex-grow: 1;
`;

const AboutUsTextsWrapper = styled(Container)`
  flex-direction: row wrap;
`;

export function AboutUs() {
  return (
    <>
      <AboutUsWrapper>
        <img
          src="/img/aboutasurion.jpeg"
          width="586px"
          height="265.0952380952381px"
          alt="a group of people sitting posing for the camera"
        />
        <AboutUsTextsWrapper>
          <b>About Asurion</b>
          <p>
            We help people stay connected. As the global leader of connected
            life services, we provide over 305 million consumers around the
            world with simple, intuitive technology advice to help them get the
            most from their devices; support to fix their issues and
            connectivity crisis, and device protection to ensure they receive a
            replacement or repair. When a product is missing or simply
            doesn&#39;t work properly, our 17,000 employees are focused on
            solving the problem with people and processes operating 24 hours a
            day, seven days a week, speaking six languages, and working across
            any device, platform, or provider. By partnering with leading
            retailers, mobile carriers and pay-tv providers, we help customers
            enhance their lives through their technology.
          </p>
        </AboutUsTextsWrapper>
      </AboutUsWrapper>
      <AboutUsWrapper>
        <AboutUsFigureWrapper>
          <img
            src="/img/305_customers_FINAL.png"
            width="100px"
            height="100px"
            alt=""
          />
          <figcaption style={{ width: 100, textAlign: "center" }}>
            <span>million customers around the world</span>
          </figcaption>
        </AboutUsFigureWrapper>
        <AboutUsFigureWrapper>
          <img src="/img/17.png" width="100px" height="100px" alt="" />
          <figcaption style={{ width: 100, textAlign: "center" }}>
            <span>thousand employees globally</span>
          </figcaption>
        </AboutUsFigureWrapper>
        <AboutUsFigureWrapper>
          <img src="/img/4.8.png" width="100px" height="100px" alt="" />
          <figcaption style={{ width: 100, textAlign: "center" }}>
            <span>rating out of 5 stars</span>
          </figcaption>
        </AboutUsFigureWrapper>
      </AboutUsWrapper>
      <AboutUsWrapper>
        <div>
          <b>Asurion Fundamentals</b>
          <br />
          <p>
            Our core values, operating principles and customer experience
            principles serve as the foundation of our culture.
          </p>
        </div>
      </AboutUsWrapper>
      <AboutUsWrapper style={{ display: "flex", width: 100 }}>
        <figure style={{ flexGrow: 1 }}>
          <div role="presentation">
            <img
              src="/img/Asurion_CVOP_PosterCV.jpeg"
              width="380px"
              height="602.9977191267513px"
              alt=""
            />
          </div>
          <figcaption>
            <span>
              Our Core Values are the fundamental beliefs of this organization.
              The values that underline our work, how we interact with each
              other and guide us daily.
            </span>
          </figcaption>
        </figure>
        <figure style={{ flexGrow: 1 }}>
          <div role="presentation">
            <img
              src="/img/ASCORP-5671-18_Leadership One Sheeter Refresh_V01.png"
              width="380px"
              height="602.9977191267513px"
              alt=""
            />
          </div>
          <figcaption>
            <span style={{ flexGrow: 1 }}>
              At Asurion, every person&#39;s work matters and every person&#39;s
              work requires leadership. Our Leadership Principles emphasize that
              how we work matters just as much as the work we do.
            </span>
          </figcaption>
        </figure>
      </AboutUsWrapper>
    </>
  );
}

export default AboutUs;
