import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  AsurionDoodleSpinner,
  Button,
  Dropdown,
  PredictiveTextField,
} from "@soluto-private/mx-asurion-ui-react";
import { useFormik } from "formik";

const StyledForm = styled.form`
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 5px;
`;

const SubmitButton = styled(Button)`
  align-self: flex-end;
  color: ${(props) => props.theme.textOnSecondary};
`;

const FieldContainer = styled.div`
  && {
    min-width: 20%;
    width: 40%;
    display: flex;
    justify-items: center;
  }
`;

interface AddRoleFormProps {
  handleSubmit: any;
  roles: any;
  ownerships: any;
  addIsLoading: boolean;
}

export function AddRoleForm({
  handleSubmit,
  ownerships,
  roles,
  addIsLoading,
}: AddRoleFormProps) {
  const [unfilteredSuggestions, setUnfilteredSuggestions] =
    useState<Array<any>>();
  const [suggestions, setSuggestions] = useState<Array<any>>();
  const [selectedOwnership, setSelectedOwnership] = useState<
    string | undefined
  >("");
  const formik = useFormik({
    initialValues: {
      ownership: selectedOwnership,
      role: "",
    },
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    const mappedSuggestions = ownerships?.map(
      (ownership: { value: string; name: string }) => {
        return {
          header: ownership.value,
          id: ownership.value,
          subHeader: ownership.value,
          value: ownership.value,
        };
      }
    );
    setSuggestions(mappedSuggestions);
    setUnfilteredSuggestions(mappedSuggestions);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    if (!selectedOwnership) {
      setSuggestions(unfilteredSuggestions);
    } else {
      const filtered = unfilteredSuggestions?.filter((unfilteredSuggestion) =>
        unfilteredSuggestion.value.toLowerCase().includes(selectedOwnership)
      );
      setSuggestions(filtered);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedOwnership]);

  return (
    <StyledForm onSubmit={formik.handleSubmit}>
      <FieldContainer>
        <PredictiveTextField
          label="Ownership"
          suggestions={suggestions}
          includeSubheader
          handleChange={(value) => {
            setSelectedOwnership(value);
          }}
          onSelection={(value) => {
            setSelectedOwnership(value.value);
          }}
          value={selectedOwnership}
        />
      </FieldContainer>
      <FieldContainer>
        <Dropdown
          label="Role"
          onChange={(value) => {
            formik.handleChange(value);
          }}
          name="role"
          options={roles}
          value={formik.values.role}
        />
      </FieldContainer>
      <SubmitButton type="submit" disabled={addIsLoading}>
        <>
          Add Role
          {addIsLoading && <AsurionDoodleSpinner width="20px" />}
        </>
      </SubmitButton>
    </StyledForm>
  );
}

export default AddRoleForm;
