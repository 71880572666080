import { Link } from "@soluto-private/mx-asurion-ui-react";
import { useContext } from "react";
import { UserContext } from "../../lib/hooks";

import {
  BannerContainer,
  BannerHeaderText,
  BannerImage,
  BannerLinkWrapper,
  BannerSubHeaderText,
} from "./components/Banner";

export function Home() {
  const { user } = useContext(UserContext);
  return (
    <div className="App">
      <div className="App-header">
        <BannerContainer>
          <div>
            <BannerHeaderText>Asurion Developer Portal</BannerHeaderText>
            <BannerSubHeaderText>
              Integrate your business application with Asurion&#39;s Developer
              Portal
            </BannerSubHeaderText>
            {user && (
              <BannerLinkWrapper>
                <Link
                  btnVariantProps={{
                    btnSize: "medium",
                    btnVariant: "default",
                  }}
                  color="secondary"
                  href="/help"
                  iconSrc=""
                  variant="button"
                >
                  Read documentation
                </Link>
              </BannerLinkWrapper>
            )}
          </div>
          <div>
            <BannerImage src="/img/home-banner.svg" />
          </div>
        </BannerContainer>
      </div>
    </div>
  );
}

export default Home;
