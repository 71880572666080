import styled from "styled-components";
import { useHistory } from "react-router-dom";

const ResponsiveMenuContainer = styled.div`
  background-color: ${({ theme }) => theme.primaryBackgroundColor};
  position: absolute;
  top: 56px;
  padding: 20px 0;
  z-index: 999999;
  display: flex !important;
  width: 100%;
  justify-content: center;
`;

const ResponsiveMenuItem = styled.div`
  border-bottom: 1px solid grey;
  cursor: pointer;
  display: block !important;
  width: 100% !important;
`;

interface IResponsiveMenuProps {
  items: IResponsiveItemProps[];
}

interface IResponsiveItemProps {
  title: string;
  url: string;
}

export function ResponsiveMenu({ items }: IResponsiveMenuProps) {
  const history = useHistory();

  return (
    <ResponsiveMenuContainer>
      {items.map((item: IResponsiveItemProps) => (
        <ResponsiveMenuItem
          onClick={() => {
            history.push(item.url);
          }}
        >
          {item.title}
        </ResponsiveMenuItem>
      ))}
    </ResponsiveMenuContainer>
  );
}

export default ResponsiveMenu;
