import styled from "styled-components";

import { ITabGroupItemProps } from "../types";

const TabGroupItemWrapper = styled.div<ITabGroupItemProps>`
  display: ${({ active }) => (active ? "block" : "none")};
  height: auto;
`;

export function TabGroupItem({ active, children }: ITabGroupItemProps) {
  return (
    <TabGroupItemWrapper active={active} className={active ? "active-tab" : ""}>
      {children}
    </TabGroupItemWrapper>
  );
}

export default TabGroupItem;
