import { colors } from "@soluto-private/mx-asurion-ui-react";
import styled from "styled-components";
import { IMenuItemContainerProps } from "../types";

export const MenuItemContainer = styled.div<IMenuItemContainerProps>`
  background-color: ${(props) => props.theme.primaryBackgroundColor};
  &::after {
    content: "";
    display: block;
    margin: 0 auto;
    width: 85%;
    border-bottom: ${(props) =>
      props.isLast ? "none" : `1px solid ${colors.neutralBrightest}`};
  }
  &:hover:after {
    width: 100%;
  }
`;

export default MenuItemContainer;
