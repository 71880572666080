import Skeleton from "react-loading-skeleton";

function ProductSkeleton() {
  return (
    <>
      <Skeleton count={1} height="2rem" width="40%" />
      <br />
      <Skeleton count={2} height="1rem" width="100%" />
      <Skeleton count={3} height="1rem" width="80%" />
      <Skeleton count={1} height="1rem" width="100%" />
      <Skeleton count={4} height="1rem" width="90%" />
      <Skeleton count={10} height="1rem" width="100%" />
    </>
  );
}

export default ProductSkeleton;
