import styled from "styled-components";
import { useState, useRef, useEffect } from "react";
import {
  getFontSize,
  Checkbox,
  Button,
  Dropdown,
  AsurionDoodleSpinner,
} from "@soluto-private/mx-asurion-ui-react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useUpsertApiDoc } from "../../api";
import { UPLOAD_SPEC_REPLACE_CHARACTERS } from "../../../constants";

const StyledForm = styled.form`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.secondaryBackgroundColor};
  padding: 0;
  margin: 0;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.secondaryBackgroundColor};
  border-radius: 10px;
  padding: 20px;
  min-width: 400px;
  width: 40vw;
  height: auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 0;
  height: 80px;
`;

const Body = styled.div`
  display: flex;
  flex-grow: 0;
  height: auto;
  padding: 1em;
`;

const Title = styled.h1`
  font-size: ${getFontSize(3)};
  font-family: Apercu;
  color: ${(props) => props.theme.invertedTextOnSecondary};
`;

const Row = styled.div<{ flexAlign?: "right" | "left" }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: nowrap;
  justify-content: ${({ flexAlign }) =>
    flexAlign === "right" ? "flex-end" : "flex-start"};
  margin: 20px 0px;
  padding: 0px;
`;

const SubmitButton = styled(Button)`
  align-self: flex-end;
`;

const UploadButton = styled(Button)`
  width: 100%;
`;

const HiddenInput = styled.input`
  display: none;
`;

const StyledDropdown = styled(Dropdown)`
  &&& {
    min-width: 100%;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  &&& ~ span.ui-checkbox__desc {
    color: ${(props) => props.theme.textOnPrimary} !important;
  }
`;

interface UploadSpecProp {
  api: string;
  onSuccess?: (env?: string) => void;
  onCancel?: () => void;
}

const ENV_OPTIONS = [
  { name: "PROD", value: "PROD" },
  { name: "NONPROD", value: "NONPROD" },
];

const UploadSpecSchema = Yup.object().shape({
  environment: Yup.string()
    .min(3, "Environment name required to be at least 3 characters long")
    .required("Environment name required."),
});

export function UploadSpecForm(props: UploadSpecProp) {
  const { api, onSuccess, onCancel } = props;
  const [selectedFileName, setSelectedFileName] = useState("");
  const [selectedFile, setSelectedFile] = useState<any>();
  const [envName, setEnvName] = useState("PROD");
  const {
    response: docUploadResponse,
    isLoading: docUploading,
    invokeApi: postDocument,
  } = useUpsertApiDoc(api, envName);

  useEffect(() => {
    if (docUploadResponse && onSuccess) {
      onSuccess(envName?.toLocaleLowerCase());
    }
  }, [docUploadResponse, onSuccess, envName]);

  const onFileSubmit = (
    fileName: string,
    file: any,
    env: string,
    isPublic: boolean,
    isDefault: boolean
  ) => {
    const formData = new FormData();
    formData.append("env", envName);
    formData.append("isDefault", `${isDefault}`);
    formData.append("isPublic", `${isPublic}`);
    formData.append("resource", api);
    formData.append("parent", "API");
    formData.append("file", selectedFile);
    postDocument(
      {
        env: envName,
        resource: api,
      },
      formData,
      {
        "Content-Type": "multipart/form-data",
      }
    );
  };
  const inputRef = useRef<null | HTMLInputElement>(null);

  const onFileChange = (e: any) => {
    const reader = new FileReader();
    if (e.target.files[0]) {
      reader.readAsText(e.target.files[0]);
      reader.onload = () => {
        setSelectedFile(e.target.files[0]);
        setSelectedFileName(
          e.target.files[0].name.replace(UPLOAD_SPEC_REPLACE_CHARACTERS, "_")
        );
      };
    }
  };

  const handleSubmit = (values: {
    environment: string;
    isPublic: boolean;
    isDefault: boolean;
  }) => {
    const { environment, isDefault, isPublic } = values;
    onFileSubmit(
      selectedFileName,
      selectedFile,
      environment,
      isPublic,
      isDefault
    );
  };

  const formik = useFormik({
    initialValues: {
      environment: envName,
      isPublic: false,
      isDefault: false,
    },
    onSubmit: handleSubmit,
    validationSchema: UploadSpecSchema,
  });

  const uploadBtnClick = () => {
    const input: HTMLInputElement | null = inputRef.current
      ? (inputRef.current as HTMLInputElement)
      : null;
    input?.click();
  };

  const onEnvChange: any = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();
    setEnvName(event.target.value);
  };

  return (
    <StyledContainer>
      <Header>
        <Title>Upload Document</Title>
        <Button variant="flat" onClick={onCancel}>
          Cancel
        </Button>
      </Header>
      <Body>
        <StyledForm onSubmit={formik.handleSubmit}>
          <StyledDropdown
            label="Environment"
            options={ENV_OPTIONS}
            name="environment"
            value={envName}
            fieldStatus={formik.errors.environment ? "error" : "default"}
            helperText={
              formik.errors.environment ? formik.errors.environment : ""
            }
            onChange={onEnvChange}
          />
          <Row>
            <StyledCheckbox
              checked={formik.values.isPublic}
              label="Public"
              onChange={formik.handleChange}
              name="isPublic"
            />
            <StyledCheckbox
              checked={formik.values.isDefault}
              label="Default"
              onChange={formik.handleChange}
              name="isDefault"
            />
          </Row>
          <Row>
            <HiddenInput
              type="file"
              accept=".yaml,.json"
              ref={inputRef}
              onChange={onFileChange}
            />
            <UploadButton
              onClick={uploadBtnClick}
              type="button"
              color="secondary"
            >
              {!selectedFileName ? "Upload" : `Upload (${selectedFileName})`}
            </UploadButton>
          </Row>
          <Row flexAlign="right">
            <SubmitButton
              type="submit"
              color="secondary"
              disabled={docUploading || !selectedFile}
            >
              <>
                Save
                {docUploading && <AsurionDoodleSpinner width="20px" />}
              </>
            </SubmitButton>
          </Row>
        </StyledForm>
      </Body>
    </StyledContainer>
  );
}

export default UploadSpecForm;
