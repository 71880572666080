import styled from "styled-components";
import { useFormik, FormikValues } from "formik";
import {
  TextField,
  Button,
  getFontSize,
  Text,
  AsurionDoodleSpinner,
  AlertBanner,
} from "@soluto-private/mx-asurion-ui-react";
import * as Yup from "yup";
import { Auth } from "aws-amplify";
import { useLocation, useHistory } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";

const SetupSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .min(8, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  newPassword: Yup.string()
    .min(8, "Password must be at least 8 characters long.")
    .max(50, "Password must be at maximum 50 characters long.")
    .required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .required("Required"),
});

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const FormContainer = styled.div`
  width: 500px;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  margin: ${getFontSize(3)};
`;

const Row = styled.div`
  margin: 10px 5px;
`;

const StyledForm = styled.form`
  margin: 0;
  display: flex;
  flex-direction: column;
  padding: 0;
`;

// * `useQuery` would still be our go-to query-param hook,
// * I just created this so I can unescape `+` symbol
const getQueryStringParams = (query: string) =>
  query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split("&")
        .reduce((params: any, param: string) => {
          const [key, value] = param.split("=");
          // eslint-disable-next-line no-param-reassign
          params[key] = value ?? null;
          return params;
        }, {})
    : {};

export function ChangePassword() {
  const { search } = useLocation();
  const query = getQueryStringParams(search);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isError, setIsError] = useState(false);
  const [updateResult, setUpdateResult] = useState("");
  const userName = query?.username ?? null;
  const history = useHistory();

  const changePasswordNewUser = async (
    name: string,
    oldPassword: string,
    newPassword: string
  ) => {
    const user = await Auth.signIn(name, oldPassword);
    if (user) {
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        const { userAttributes } = user.challengeParam;
        await Auth.completeNewPassword(user, newPassword, {
          name: `${userAttributes.given_name} ${userAttributes.family_name}`,
        });
        setIsUpdating(false);
        setIsError(false);
        setUpdateResult("Change password succesful.");
      }
    }
  };

  const changePasswordLoggedInUser = async (
    oldPassword: string,
    newPassword: string
  ) => {
    const user = await Auth.currentAuthenticatedUser();
    const result = await Auth.changePassword(user, oldPassword, newPassword);
    setIsUpdating(false);
    setIsError(false);
    setUpdateResult(result);
  };

  const changePasswordSubmit = async (values: FormikValues) => {
    const { userName, oldPassword, newPassword } = values;
    setIsUpdating(true);
    try {
      if (userName) {
        await changePasswordNewUser(userName, oldPassword, newPassword);
      } else {
        await changePasswordLoggedInUser(oldPassword, newPassword);
      }
      setTimeout(() => {
        history.push("/");
      }, 1500);
    } catch (e: any) {
      setIsUpdating(false);
      setIsError(true);
      toast.error(e.message);
      throw new Error(`${e}`);
    }
  };

  const formik = useFormik({
    initialValues: {
      userName,
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    onSubmit: changePasswordSubmit,
    validationSchema: SetupSchema,
    validateOnBlur: true,
  });

  return (
    <Wrapper>
      <FormContainer>
        <StyledForm onSubmit={formik.handleSubmit}>
          {updateResult && (
            <Row>
              <AlertBanner
                message={updateResult}
                alertBannerType={isError ? "error" : "success"}
                isOpen={!!updateResult}
                hideDismissButton
              />
            </Row>
          )}

          <Row>
            <TextField
              label="Old Password"
              defaultValue={formik.values.oldPassword}
              name="oldPassword"
              type="password"
              fieldStatus={formik.errors.oldPassword ? "error" : "default"}
              helperText={
                formik.errors.oldPassword
                  ? (formik.errors.oldPassword as string)
                  : ""
              }
              onChange={(value) => {
                formik.handleChange(value);
              }}
            />
          </Row>
          <Row>
            <TextField
              label="New Password"
              defaultValue={formik.values.newPassword}
              type="password"
              name="newPassword"
              fieldStatus={formik.errors.newPassword ? "error" : "default"}
              helperText={
                formik.errors.newPassword
                  ? (formik.errors.newPassword as string)
                  : ""
              }
              onChange={(value) => {
                formik.handleChange(value);
              }}
            />
          </Row>
          <Row>
            <TextField
              label="Confirm Password"
              defaultValue={formik.values.confirmPassword}
              type="password"
              name="confirmPassword"
              fieldStatus={formik.errors.confirmPassword ? "error" : "default"}
              helperText={
                formik.errors.confirmPassword
                  ? (formik.errors.confirmPassword as string)
                  : ""
              }
              onChange={(value) => {
                formik.handleChange(value);
              }}
            />
          </Row>
          <Button type="submit" disabled={isUpdating}>
            <>
              <Text>Submit</Text>
              {isUpdating && <AsurionDoodleSpinner width="20px" />}
            </>
          </Button>
        </StyledForm>
      </FormContainer>
    </Wrapper>
  );
}

export default ChangePassword;
