import { colors } from "@soluto-private/mx-asurion-ui-react";

export enum ThemeMode {
  DARK,
  LIGHT,
}

// * BASED ON ASURION THEME
// * https://www.figma.com/file/V0lJimUTezYRZlR8hS6fq5/%F0%9F%9F%A2-Global-Pattern-Library?node-id=7038%3A47901
export enum COLORS {
  WHITE = "#FFFFFF",
  BLACK = "#000000",
  PURPLE = "#7027CD",
  DARK_GRAY = "#24282D",
  LIGHT_GRAY = "#A5AAAF",
  GREEN = "#8EE6A3",
  ORANGE = "#F9D51C",
  ORANGE_DEEPER = "#EDB800",
  RED = "#D44041",
  SECONDARY = "#F0F0F5",
  CODE_BLOCK_BG = "#131415",
  BRAND_PURPLE_DEEPER = "#6700B8",
  BRAND_PURPLE_DEEP = "#8223D2",
  BRAND_BLUE_DEEP = "#2743CC",
  BRAND_BLUE_DEEPER = "#253BAA",
  BRAND_BLUE_BRIGHT = "#4A68F9",
  BRAND_BLUE_BRIGHTER = "#6B8BFF",
  NEUTRAL_DEEPEST = "#333F48",
  NEUTRAL_DEEPER = "#6E767D",
  NEUTRAL_DEEP = "#A5AAAF",
  NEUTRAL_BRIGHTEST = "#F0F0F5",
  NEUTRAL_BRIGHTER = "#E6E6EB",
  NEUTRAL_BRIGHT = "#D5D6DA",
}

// * BASED ON ASURION OFFICIAL THEMING & BRANDING
export const ASURION_COLORS = { ...colors };

// * Breakpoints based on https://www.figma.com/file/V0lJimUTezYRZlR8hS6fq5/%F0%9F%9F%A2-Global-Pattern-Library?node-id=21822%3A5454
export enum BREAKPOINTS {
  XS = 600,
  SM = 768, // * Ipad, tablet
  MD = 1024, // * Laptop
  LG = 1024, // * Desktop > 1024
}

export enum HttpMethod {
  GET = "GET",
  PATCH = "PATCH",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}

export enum HttpStatusCode {
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  UNPROCESSABLE_ENTITY = 422,
  OK = 200,
}
