import { createGlobalStyle } from "styled-components";

import { ASURION_COLORS } from "../constants";
import { GetFilter } from "../lib/utils";

export const GlobalStyle = createGlobalStyle`
  // * START OF STOPLIGHT ELEMENT
  && {
    div#mosaic-provider-react-aria-0-2 > div > div.sl-text-body {
      color: ${(props) => props.theme.textOnPrimary};
      font-family: Apercu;
      font-size: 14px;

      div, p {
        color: ${(props) => props.theme.textOnPrimary};
        font-family: Apercu;
        font-size: 16px;
      }

      p{
        font-size: 14px;
      }
    }
    .sl-prose ul {
      list-style-type: none;
    }
    .sl-popover__tip.sl-absolute.sl-z-0.sl-text-canvas-pure{
      color: ${(props) => props.theme.textOnSecondary};
    }
    .sl-prose ul:not(.contains-task-list) li:before{
      background-color: ${(props) => props.theme.textOnPrimary};
      }
    .sl-text-paragraph,.sl-leading-snug,.sl-font-prose,.sl-font-semibold,.sl-text-heading, p {
      color: ${(props) => props.theme.textOnPrimary};
    }

    .sl-prose tbody tr:nth-child(2n), .sl-bg-canvas-tint {
      background-color: ${({ theme }) => theme.secondaryBackgroundColor};
    }
    
    .sl-bg-code, .sl-badge {
      background-color: ${({ theme }) => theme.secondaryBackgroundColor};
      color: ${(props) => props.theme.invertedTextOnSecondary} !important;
    }

    .sl-whitespace-nowrap {
      color: ${(props) => props.theme.invertedTextOnSecondary};
    }

    div#mosaic-provider-react-aria-0-2 > div > .sl-elements .sl-antialiased .sl-h-full .sl-text-base .sl-font-ui .sl-text-body {
      svg {
        ${(props) => GetFilter(props.theme.textOnPrimary)?.filter};
      }
    }

    .sl-button, .sl-text-body, .token, .punctuation {
      color: ${(props) => props.theme.textOnPrimary};
    }
    .sl-prose thead td, .sl-prose thead th {
      color: ${(props) => props.theme.textOnPrimary};
    }

    .sl-panel__titlebar > div > span.sl-text-body, .SendButtonHolder > .sl-stack > .sl-button {
      color: ${(props) =>
        props.theme.secondaryBackgroundColor === ASURION_COLORS.white
          ? ASURION_COLORS.white
          : props.theme.textOnPrimary} !important;
    }

    .sl-bg-canvas-pure, .sl-menu {
      background-color: ${(props) => props.theme.secondaryBackgroundColor};
    }

    .sl-menu-item__description, .sl-text-sm, .sl-uppercase {
      color: ${(props) => props.theme.invertedTextOnSecondary};
      -webkit-text-fill-color: ${(props) =>
        props.theme.invertedTextOnSecondary} !important;
    }

    .sl-prose .sl-markdown-viewer .sl-my-5, .sl-text-muted {
      color: ${(props) => props.theme.textOnPrimary};
    }

    .sl-prose p {
      color: ${(props) => props.theme.textOnPrimary};
    }

    &[style] {
      font-size: 1rem;
    }
  }

  sl-input {
    font-family: Apercu;
  }

  sl-panel__titlebar {
    font-size: 16px;
    font-family: Apercu;
  }


  span.token.property[style], span.token.punctuation[style], span.token[style] {
    color: #9efaa7 !important;
  }

  span.token.string[style]{
    color: #fee39e !important;
  }

  span.token.boolean[style]{
    color: #fee39e !important;
  }

  span.token.number[style]{
    color: #fee39e !important;
  }
  .sl-flex > .sl-flex-1 > span.token.plain{
    color: #fee39e !important;
  }
  .sl-flex > .sl-flex-1 > span.token.function[style]{
    color: #fee39e !important;
  }
  .sl-flex > .sl-flex-1 > span.token.keyword[style]{
    color: #fee39e !important;
  }
  .sl-flex > .sl-flex-1 > span.token.parameter{
    color: #fee39e !important;
  }
  .sl-flex > .sl-flex-1 > span.token.maybe-class-name{
    color: #fee39e !important;
  }
  .sl-flex > .sl-flex-1 > span.token.console{
    color: #fee39e !important;
  }

  .sl-bg-canvas {
    background-color: ${(props) => props.theme.primaryBackgroundColor};
  }

  .sl-font-sans,
  .sl-font-ui,
  .sl-prose {
    font-family: Apercu;
  }

  .sl-overflow-y-auto.sl-w-full.sl-bg-canvas-100 {
    background-color: ${(props) => props.theme.primaryBackgroundColor};
  }

  .sl-border-r, .sl-bg-canvas-100 {
    background-color: ${(props) =>
      props.theme.secondaryBackgroundColor} !important;
  }

      // * Stoplight Alert banner header
  .sl-bg-canvas-200, .sl-bg-canvas-300 {
    svg {
      color: ${(props) =>
        props.theme.secondaryBackgroundColor === ASURION_COLORS.white
          ? ASURION_COLORS.white
          : props.theme.textOnPrimary} !important;
    }

    background-color: ${(props) =>
      props.theme.secondaryBackgroundColor === ASURION_COLORS.white
        ? "#212a3b"
        : props.theme.secondaryBackgroundColor};
    
    &&& > .sl-panel__titlebar {
      background-color: ${(props) =>
        props.theme.secondaryBackgroundColor === ASURION_COLORS.white
          ? "#212a3b"
          : props.theme.secondaryBackgroundColor} !important;
      span.sl-text-body {
        color: ${(props) =>
          props.theme.secondaryBackgroundColor === ASURION_COLORS.white
            ? ASURION_COLORS.white
            : props.theme.textOnPrimary} !important;
      }
    }
    &&& > div {
      color: ${(props) =>
        props.theme.secondaryBackgroundColor === ASURION_COLORS.white
          ? ASURION_COLORS.white
          : props.theme.textOnPrimary} !important;
    }

    &&& > .sl-prose .sl-markdown-viewer .sl-my-5, .sl-text-muted {
      color: ${(props) =>
        props.theme.secondaryBackgroundColor === ASURION_COLORS.white
          ? ASURION_COLORS.white
          : props.theme.textOnPrimary} !important;
    }
  }

  .TryItPanel.sl-bg-canvas-100.sl-rounded-lg {
    background-color: ${(props) =>
      props.theme.secondaryBackgroundColor === ASURION_COLORS.white
        ? "#2d394e"
        : props.theme.secondaryBackgroundColor} !important;
  }
  
    // * Stoplight Alert banner body
    .sl-panel__content-wrapper.sl-bg-canvas-100 {
      background-color: ${(props) =>
        props.theme.secondaryBackgroundColor === ASURION_COLORS.white
          ? "#2d394e"
          : props.theme.secondaryBackgroundColor} !important;
      label, .ParameterGrid.sl-p-4 > div, .sl-whitespace-nowrap, p,
      .sl-panel__content.sl-p-0, .token, .operator,.token .property, .punctuation {
        color: ${(props) =>
          props.theme.secondaryBackgroundColor === ASURION_COLORS.white
            ? ASURION_COLORS.white
            : props.theme.textOnPrimary} !important;
      }
    }

  .sl-border-r {
    padding: 1em !important;
    max-width: 260px;
    background-color: ${(props) => props.theme.secondaryBackgroundColor};
  }

  .sl-overflow-y-auto.sl-w-full.sl-bg-canvas-100 {
    background-color: ${(props) => props.theme.primaryBackgroundColor};
  }

  /* Actual component ID */
  #mosaic-provider-react-aria-0-2 {
    flex-grow: 3;
  }

  .sl-elements {
    font-size: 1rem;
  }

  .sl-border-r,
  .sl-bg-canvas-100 {
    background-color: ${(props) => props.theme.primaryBackgroundColor};
  }

  .sl-border-r {
    background-color: ${(props) => props.theme.primaryBackgroundColor};
  }

  &&& {
    .sl-flex.sl-overflow-y-auto.sl-flex-col.sl-sticky.sl-inset-y-0.sl-pt-8.sl-bg-canvas-100.sl-border-r {
      background-color: ${(props) =>
        props.theme.secondaryBackgroundColor} !important;
    }
  }

  .sl-bg-primary-tint, .sl-bg-canvas-50 {
    background-color: ${(props) => props.theme.secondaryBackgroundColor};
  }

  .sl-text-primary {
    color: #4a68f9;
  }

  .sl-text-danger {
    color: #db1b1b;
  }

  .sl-text-success {
    color: #00b574;
  }

  .sl-text-warning {
    color: #edb800;
  }

  .sl-bg-success {
    background-color: #00b574;
  }

  .sl-bg-primary {
    background-color: ${(props) => props.theme.secondary};
  }

  .sl-bg-danger {
    background-color: #db1b1b;
  }

  .sl-bg-warning {
    background-color: #edb800;
  }

  .sl-relative, .sl-text-base {
    font-size: 14px;
  }

  .sl-stack > .sl-relative > .sl-button.sl-border-transparent {
    border-color: #a839f3;
    background-color: ${({ theme }) => theme.secondary};
  }

  .sl-stack > .sl-relative > .sl-button {
    background-color: #a839f3;
  }

  .sl-panel__content-wrapper  > sl-p-4 > * {
    font-size: 14px;
  }

  .sl-flex > .sl-flex-1 > span {
    font-size: 14px;
  }

  .sl-code-editor > div {
    font-size: 14px !important;
  }

  .sl-prose li {
    font-size: 14px;
  }

  .sl-text-heading > a > div{
    font-size: 18px !important;
  }

  .sl-stack > .sl-relative > .sl-button .sl-pr-1 {
    color: ${(props) =>
      props.theme.secondaryBackgroundColor === ASURION_COLORS.white
        ? ASURION_COLORS.white
        : props.theme.textOnPrimary} !important;
  }

  .sl-stack > .sl-relative > .sl-button .sl-mr-1 svg {
    color: ${(props) =>
      props.theme.secondaryBackgroundColor === ASURION_COLORS.white
        ? ASURION_COLORS.white
        : props.theme.textOnPrimary} !important;
  }

  .sl-stack > .sl-relative > .sl-button .sl-text-xs {
    color: ${(props) =>
      props.theme.secondaryBackgroundColor === ASURION_COLORS.white
        ? ASURION_COLORS.white
        : props.theme.textOnPrimary} !important;
  }


  .sun-editor-editable table td, .sun-editor-editable table th{
    border: 1px solid ${(props) => props.theme.invertedTextOnSecondary};
  }
  // * END OF STOPLIGHT ELEMENT
  // * END OF SUNEDITOR CSS
  .se-menu-list, .se-btn-tray, .sun-editor .se-resizing-bar .se-navigation, .sun-editor-editable {
    background-color: ${(props) => props.theme.secondaryBackgroundColor};
    color: ${(props) => props.theme.invertedTextOnSecondary};
  }

  .se-menu-list > svg, .se-btn .se-tooltip > svg {
    ${(props) => GetFilter(props.theme.textOnPrimary)?.filter} !important;
  }

  .sun-editor .se-btn:enabled:focus, .sun-editor .se-btn:enabled:hover {
    background-color: ${(props) => props.theme.secondaryBackgroundColor};
    /* border-color: #d1d1d1; */
    outline: 0 none;
  }

  .sun-editor .se-btn:enabled:focus, .sun-editor .se-btn {
    color: ${(props) => props.theme.invertedTextOnSecondary};
    /* border-color: #d1d1d1; */
    outline: 0 none;
  }
  // * END OF SUNEDITOR CSS
  // * START OF SOLUTO CSS
  div[id^='asurion-ui-text-field']{
      color: ${(props) => props.theme.textOnPrimary};
      svg {
        /* ${(props) => GetFilter(props.theme.textOnPrimary)?.filter}; */
        color: ${(props) => props.theme.textOnPrimary};
      }
      div:hover:not(.is-disabled), div:active:not(.is-disabled), div.has-focus:not(.is-disabled) {
        label, input {
          color: ${(props) => props.theme.textOnPrimary} !important;
        }
        
        svg {
          color: ${(props) => props.theme.textOnPrimary};
        }
      }
      div:not(.is-disabled):hover:not(.has-focus) {
        border-color: ${(props) => props.theme.textOnPrimary};

      label, input {
        color: ${(props) => props.theme.textOnPrimary};
      }

      svg {
        /* ${(props) => GetFilter(props.theme.textOnPrimary)?.filter}; */
        color: ${(props) => props.theme.textOnPrimary};
      }
    }
    div:not(.is-disabled):focus, div:not(.is-disabled).has-focus, div:not(.is-disabled):active {
      border-color: ${(props) => props.theme.textOnPrimary};

      label, input {
        color: ${(props) => props.theme.textOnPrimary};
      }

      svg {
        /* ${(props) => GetFilter(props.theme.textOnPrimary)?.filter}; */
        color: ${(props) => props.theme.textOnPrimary};
      }
    }
  }
  svg[aria-describedby^='loader-title'] {
    ${(props) => GetFilter(props.theme.invertedTextOnPrimary)?.filter} ;
    }!important

  // * END OF SOLUTO CSS
`;

export default GlobalStyle;
